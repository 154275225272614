import axios from 'axios';
import { BaseTransport } from '@workflow-solutions/ofs-vuex-crud';

export default class ConnectHttpTransport extends BaseTransport {
	constructor($config, httpClient = axios) {
		super($config, httpClient);
		this.errorHandlers = [];
		this.$config = $config;
		this.httpClient = httpClient;
	}

	find({ resourcePluralName, query, options, ...rest } = {}, middlewares) {
		const path = `${resourcePluralName}`;
		return this.makeRequest(
			{ method: 'GET', path, query, options, ...rest },
			middlewares
		);
	}

	count({ resourcePluralName, query, options, ...rest } = {}, middlewares) {
		const path = `${resourcePluralName}/count`;
		return this.makeRequest(
			{ method: 'GET', path, query, options, ...rest },
			middlewares
		);
	}

	findOne({ resourcePluralName, query, options, ...rest } = {}, middlewares) {
		const path = `${resourcePluralName}/findOne`;
		return this.makeRequest(
			{ method: 'GET', path, query, options, ...rest },
			middlewares
		);
	}

	findById({ resourcePluralName, id, query, options, ...rest } = {}, middlewares) {
		const path = `${resourcePluralName}/${id}`;
		return this.makeRequest(
			{ method: 'GET', path, query, options, ...rest },
			middlewares
		);
	}

	create({ resourcePluralName, data, options, ...rest } = {}, middlewares) {
		const path = `${resourcePluralName}`;
		const payload = data;
		return this.makeRequest(
			{ method: 'POST', path, payload, options, ...rest },
			middlewares
		);
	}

	update({ resourcePluralName, id, data, options, ...rest } = {}, middlewares) {
		const path = `${resourcePluralName}/${id}`;
		return this.makeRequest(
			{ method: 'PUT', path, payload: data, options, ...rest },
			middlewares
		);
	}

	updateAll({ resourcePluralName, where, data, options, ...rest } = {}, middlewares) {
		const path = `${resourcePluralName}`;
		return this.makeRequest(
			{ method: 'PUT', path, query: where, payload: data, options, ...rest },
			middlewares
		);
	}

	delete({ resourcePluralName, id, options, ...rest } = {}, middlewares) {
		const path = `${resourcePluralName}/${id}`;
		return this.makeRequest(
			{ method: 'DELETE', path, options, ...rest },
			middlewares
		);
	}

	async makeRequest(args, middlewares = this.middlewares) {
		await this.execMiddlewares('pre', middlewares, args);
		const { method, path, query = {}, payload = {}, headers = {}, ...rest } = args;

		const url = path.indexOf('http') === 0 ? path : `${this.$config.apiBase}/api/${path}`;

		const request = {
			method,
			data: payload,
			params: query,
			headers,
			url,
			...rest
		};

		try {
			const result = await this.httpClient.request(request);
			await this.execMiddlewares('post', middlewares, result);
			return result;
		} catch (err) {
			await this.execMiddlewares('post', middlewares, err);
			throw err;
		}
	}
}

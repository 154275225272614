export const SET_ACCOUNT = (state, account) => {
	state.currentAccount = account;
};
export const SET_MEMBERSHIPS_COUNT = (state, count) => {
	state.count = count;
};
export const SET_ACCOUNTS = (state, docs) => {
	state.accounts = docs;
};
export const SET_USER = (state, doc) => {
	state.user = doc;
};
export const SET_VARS = (state, doc) => {
	state.vars = doc;
};
export const SET_USER_MEMBERSHIPS = (state, memberships) => {
	state.userMemberships = memberships;
};
export const SET_MEMBERS = (state, accountMemberships) => {
	state.accountMemberships = accountMemberships;
};
export const SET_MEMBER = (state, member) => {
	state.member = member;
};

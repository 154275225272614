<template>
	<b-modal
		:visible="visible"
		title="New Member"
		ok-title="Add"
		:ok-disabled="page.isSubmitting || !isValidMember"
		@ok="onSubmitClick"
		@hidden="$emit('hidden')">

		<form @submit.prevent="onSubmitClick">
			<b-form-group label="Name" label-for="NewMember__name">
				<b-form-input
					id="NewMember__name"
					:disabled="page.isSubmitting"
					v-model="memberName"/>
			</b-form-group>
		</form>

	</b-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	props: {
		visible: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			page: {
				isSubmitting: false
			},
			memberName: null
		};
	},
	computed: {
		isValidMember() {
			return this.memberName;
		}
	},
	methods: {
		...mapActions({
			createMember: 'team/createMember',
			inviteMember: 'team/inviteMember'
		}),
		async onSubmitClick(event) {
			event.preventDefault();
			this.page.isSubmitting = true;
			try {
				await this.createMember({
					id: this.$route.params.id,
					data: { email: this.memberName }
				});
				this.$toaster.success(`Added member: ${this.memberName}`);
				this.$emit('submit');
				this.$emit('hidden');
			} catch (err) {
				try {
					if (err.response.status !== 404) throw err;
					await this.inviteMember({
						id: this.$route.params.id,
						data: { email: this.memberName, redirectUrl: window.location.origin }
					});
					this.$toaster.success(`Invited member: ${this.memberName}`);
					this.$emit('submit');
					this.$emit('hidden');
				} catch (finalError) {
					this.$toaster.error(`Error inviting member: ${finalError}`);
				}
			}
			this.page.isSubmitting = false;
		}
	},
	watch: {
		visible() {
			this.memberName = null;
		}
	}
};
</script>

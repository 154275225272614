<template>
	<DefaultLayout>
		<ContentHeader title="Backlog">
			<b-form-input v-model="searchQuery" placeholder="Search (regex)" />
		</ContentHeader>

		<section class="DashboardBacklog">
			<loader class="App__loading" v-if="page.isLoading" />

			<b-table
				v-if="!page.isLoading"
				:items="filteredBacklog"
				:fields="backlogFields"
				:sort-by.sync="sortBy"
				:sort-desc.sync="sortDesc"
			>
				<template v-slot:cell(retry)="data">
					<span class="text-dark DashboardBacklog__metric">{{ data.item.retry }}</span>
				</template>

				<template v-slot:cell(queued)="data">
					<span class="text-warning DashboardBacklog__metric">{{ data.item.queued }}</span>
				</template>

				<template v-slot:cell(started)="data">
					<span class="text-primary DashboardBacklog__metric">{{ data.item.started }}</span>
				</template>

				<template v-slot:cell(oldestQueued)="data">
					<template v-if="data.item.oldestQueued">
						<time-span
							class="DashboardBacklog__metric--small"
							:class="getTextColourForBacklog(data.item.oldestQueued)"
							:time="data.item.oldestQueued"
						/>
					</template>
					<span v-else>N/A</span>
				</template>

				<template v-slot:cell(oldestStarted)="data">
					<template v-if="data.item.oldestStarted">
						<time-span
							class="DashboardBacklog__metric--small"
							:class="getTextColourForBacklog(data.item.oldestStarted)"
							:time="data.item.oldestStarted"
						/>
					</template>
					<span v-else class="DashboardBacklog__metric--small">N/A</span>
				</template>
			</b-table>
		</section>
	</DefaultLayout>
</template>

<script type="text/javascript">
import { ContentHeader } from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import DefaultLayout from '../../components/DefaultLayout';
import TimeSpan from '../../components/TimeSpan';
import getFormattedDuration from '../../lib/getFormattedDuration';

export default {
	components: {
		DefaultLayout,
		ContentHeader,
		TimeSpan
	},
	data() {
		return {
			sortBy: 'queued',
			sortDesc: true,
			searchQuery: '',
			page: {
				isLoading: false
			},
			backlogFields: [
				{ key: 'type', label: 'Type', sortable: true },
				{ key: 'alias', label: 'Alias', sortable: true },
				{ key: 'retry', label: 'Retry', sortable: true, class: 'text-center' },
				{ key: 'queued', label: 'Queued', sortable: true, class: 'text-center' },
				{ key: 'started', label: 'Started', sortable: true, class: 'text-center' },
				{ key: 'oldestQueued', label: 'Oldest Queued', sortable: true },
				{ key: 'oldestStarted', label: 'Oldest Started', sortable: true }
			],
			getFormattedDuration
		};
	},
	computed: {
		...mapGetters({
			backlog: 'task/backlog'
		}),
		filteredBacklog() {
			if (!this.searchQuery) return this.backlog;
			const regex = new RegExp(this.searchQuery, 'i');

			return this.backlog.filter(({ type, alias }) => regex.test(type) || regex.test(alias));
		}
	},
	methods: {
		...mapActions({
			fetchBacklog: 'task/fetchBacklog'
		}),
		getTimeDifference(dateString) {
			return new Date() - new Date(dateString);
		},
		getTextColourForBacklog(dateString) {
			const timeDifference = this.getTimeDifference(dateString);
			if (timeDifference < 60000) return 'text-success'; // less than 1 minute
			if (timeDifference < 600000) return 'text-warning'; // less than 10 minutes
			return 'text-danger'; // everything else
		}
	},
	watch: {
		$route: {
			immediate: true,
			async handler() {
				clearInterval(this.interval);
				await this.fetchBacklog();

				this.interval = setInterval(this.fetchBacklog, 5000);
			}
		}
	},
	beforeDestroy() {
		clearInterval(this.interval);
	}
};
</script>

<style lang="scss">
.DashboardBacklog {
	padding: 1rem;

	&__metric {
		font-size: 2rem;

		&--small {
			font-size: 1.2rem;
		}
	}
}
</style>

<template>
	<section class="AppTasksSummary">

		<div class="AppTasksSummary__actions">

			<b-button
				variant="primary"
				v-text="'Refresh'"
				@click="fetch"/>

			<div class="AppTasksSummary__filter">
				<label
					class="AppTasksSummary__label"
					v-text="'Period:'"/>
				<b-form-select
					v-model="filters.dateRange"
					:options="filters.dateRangeOptions"
					@input="updateQuery"/>
			</div>

		</div>

		<loader class="App__loading" v-if="page.isLoading"/>

		<b-tabs
			v-if="!page.isLoading"
			v-model="page.tabIndex"
			@input="updateQuery">

			<b-tab>

				<template slot="title">
					<span class="AppTasksSummary__tabTitle">Running</span>
					<b-badge
						variant="primary"
						v-text="tasks.running.length"/>
				</template>

				<datatable
					v-if="!page.isLoading && tasks.running.length >= 1"
					:items="tasks.running"
					:fields="fields.running">

					<template v-slot:cell(name)="data">
						<router-link
							:to="{
								name: 'app.detail.workqueue',
								params: {
									id: $route.params.id,
									workqueue: data.item.workQueueId
								}
							}"
							v-text="data.item.name"/>
					</template>

					<template v-slot:cell(workqueue)="data">
						{{ data.item.workQueueDescription }}
					</template>

					<template v-slot:cell(started)="data">
						<time-span :time="data.item.started"/>
					</template>

				</datatable>

				<p
					v-if="!page.isLoading && tasks.running.length === 0"
					class="AppTasksSummary__emptyListMessage"
					v-text="'There are no results to show.'"/>

			</b-tab>

			<b-tab>

				<template slot="title">
					<span class="AppTasksSummary__tabTitle">Queued</span>
					<b-badge
						variant="primary"
						v-text="tasks.queued.length"/>
				</template>

				<datatable
					v-if="!page.isLoading && tasks.queued.length >= 1"
					:items="tasks.queued"
					:fields="fields.queued">

					<template v-slot:cell(name)="data">
						<router-link
							:to="{
								name: 'app.detail.workqueue',
								params: {
									id: $route.params.id,
									workqueue: data.item.workQueueId
								}
							}"
							v-text="data.item.name"/>
					</template>

					<template v-slot:cell(workqueue)="data">
						{{ data.item.workQueueDescription }}
					</template>

					<template v-slot:cell(queued)="data">
						<time-span :time="data.item.queued"/>
					</template>

				</datatable>

				<p
					v-if="!page.isLoading && tasks.queued.length === 0"
					class="AppTasksSummary__emptyListMessage"
					v-text="'There are no results to show.'"/>

			</b-tab>

			<b-tab>

				<template slot="title">
					<span class="AppTasksSummary__tabTitle">Failed</span>
					<b-badge
						variant="danger"
						v-text="tasks.failed.length"/>
				</template>

				<datatable
					v-if="!page.isLoading && tasks.failed.length > 0"
					:items="tasks.failed"
					:fields="fields.failed">

					<template v-slot:cell(name)="data">
						<router-link
							:to="{
								name: 'app.detail.workqueue',
								params: {
									id: $route.params.id,
									workqueue: data.item.workQueueId
								}
							}"
							v-text="data.item.name"/>
					</template>

					<template v-slot:cell(when)="data">
						<time-span :time="data.item.started"/>
					</template>

					<template v-slot:cell(workqueue)="data">
						{{ data.item.workQueueDescription }}
					</template>

					<template v-slot:cell(error)="data">
						{{data.item.reason}}
					</template>

					<template v-slot:cell(retry)="data">
						<b-button
							variant="warning"
							size="sm"
							v-text="'Restart Task'"
							:disabled="data.item.isSubmitting"
							@click.stop="onTaskRestartClick(data.item)"/>
					</template>

				</datatable>

				<p
					v-if="!page.isLoading && tasks.failed.length === 0"
					class="AppTasksSummary__emptyListMessage"
					v-text="'There are no results to show.'"/>

			</b-tab>
		</b-tabs>

	</section>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import { Datatable } from '@workflow-solutions/ofs-vue-layout';
import dateRangeOptions from '../../../lib/dateRangeOptions';
import getFormattedDuration from '../../../lib/getFormattedDuration';
import TimeSpan from '../../../components/TimeSpan';

export default {
	components: {
		Datatable,
		TimeSpan
	},
	data() {
		return {
			page: {
				tabIndex: 0,
				isLoading: false
			},
			tasks: {
				running: [],
				queued: [],
				failed: []
			},
			fields: {
				running: ['name', 'workqueue', 'started'],
				queued: ['name', 'workqueue', 'queued'],
				failed: ['when', 'name', 'workqueue', 'error', 'retry']
			},
			filters: {
				dateRange: 0,
				dateRangeOptions
			}
		};
	},
	methods: {
		...mapActions({
			addTask: 'task/add',
			getTasks: 'workqueue/getTasks',
			restartTask: 'workqueue/restartTask'
		}),
		getFormattedDuration,
		async fetch() {
			this.page.isLoading = true;
			try {
				await Promise.all([
					this.loadFailedTasks(),
					this.loadQueuedTasks(),
					this.loadRunningTasks()
				]);
			} catch (err) {
				this.$toaster.error(`Error loading tasks: ${err}`);
			}
			this.page.isLoading = false;
		},
		async fetchTasksWithStatus(status) {
			const tasks = await this.getTasks({
				id: this.$route.params.id,
				dates: {
					start: moment().startOf('day').subtract(this.filters.dateRange, 'days'),
					end: moment().endOf('day')
				},
				status
			});
			return tasks.data;
		},
		async loadRunningTasks() {
			const status = 'started';
			this.tasks.running = await this.fetchTasksWithStatus(status);
		},
		async loadQueuedTasks() {
			const status = 'queued';
			this.tasks.queued = await this.fetchTasksWithStatus(status);
		},
		async loadFailedTasks() {
			const status = 'failed';
			this.tasks.failed = await this.fetchTasksWithStatus(status);
		},
		async onTaskRestartClick(task) {
			task.isSubmitting = true;
			try {
				await this.restartTask({ id: task._id });
				this.$toaster.success(`Task: ${task.name} restarted.`);
			} catch (err) {
				this.$toaster.error(`Error restarting task: ${err}`);
			}
			task.isSubmitting = false;
			this.fetch();
		},
		updateQuery() {
			this.$router.replace({
				query: {
					...this.$route.query,
					dateRange: this.filters.dateRange,
					tab: this.page.tabIndex
				}
			}).catch(e => {
				if (e.name !== 'NavigationDuplicated') throw e;
			});
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				if (this.$route.query.tab) this.page.tabIndex = parseInt(this.$route.query.tab, 10);
				if (this.$route.query.dateRange) this.filters.dateRange = this.$route.query.dateRange;
				this.updateQuery();
				this.fetch();
			}
		}
	}
};
</script>

<style lang="scss">
.AppTasksSummary {
	padding: 1rem;

	&__actions {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1rem;
	}

	&__filter {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&__label {
		min-width: fit-content;
		margin: 0;
		margin-right: 0.5rem;
	}

	&__tabTitle {
		margin-right: 0.5rem;
	}

	&__emptyListMessage {
		padding: 2rem 0;
		text-align: center;
	}
}

</style>

<template>
	<default-layout>

		<content-header title="Apps">

			<b-button
				variant="primary"
				v-text="'New App'"
				@click="showNewAppModal"/>

		</content-header>

		<loader v-if="page.isLoading" class="App__loading"/>

		<card-list
			v-if="!page.isLoading"
			class="AppList"
			:items="cardListApps"/>

		<edit-app-modal
			:visible="page.isNewAppModalVisible"
			:create="true"
			@hidden="closeNewAppModal"
			@submitted="onAppCreation"/>

	</default-layout>
</template>

<script>
import { ContentHeader } from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import DefaultLayout from '../../components/DefaultLayout';
import CardList from '../../components/CardList';
import EditAppModal from './components/EditAppModal';


export default {
	components: {
		ContentHeader,
		DefaultLayout,
		CardList,
		EditAppModal
	},
	data() {
		return {
			page: {
				isLoading: false,
				isNewAppModalVisible: false
			}
		};
	},
	computed: {
		...mapGetters({
			appsData: 'app/apps'
		}),
		apps() {
			return this.appsData.data;
		},
		cardListApps() {
			if (!this.apps) return [];
			return this.apps.map(app => ({
				title: app.name,
				text: app.description,
				path: { name: 'app.detail.workqueues', params: { id: app._id } },
				id: app._id
			}));
		}
	},
	methods: {
		...mapActions({
			findApps: 'app/find'
		}),
		async fetch() {
			this.page.isLoading = true;
			try {
				await this.findApps({
					query: {
						pagesize: 9999,
						page: 1
					}
				});
			} catch (err) {
				this.$toaster.error(`Error loading apps: ${err}`);
			}
			this.page.isLoading = false;
		},
		onAppCreation(app) {
			this.$router.push({ name: 'app.detail', params: { id: app._id } });
		},
		showNewAppModal() {
			this.page.isNewAppModalVisible = true;
		},
		closeNewAppModal() {
			this.page.isNewAppModalVisible = false;
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				this.fetch();
			}
		}
	}
};
</script>

<style lang="scss">

.AppList {
	padding: 1rem;
}

</style>

const workerStarterKitNode = `
const { WorkerHost, TaskWorker, TaskResult } = require('@workflow-solutions/workforce-worker-sdk-js');

// define a custom worker
class MyWorker extends TaskWorker {
	async execute(context) {
		// do some work with the context.inputs, and set some context.outputs
		context.outputs.greeting = 'Hello ' + context.inputs.name;

		return TaskResult.succeeded();
	}

	getConfig() {
		return {
			accessKeyId: '',
			secretAccessKey: '',
			queueUrl: ''
		};
	}
}

const host = new WorkerHost([MyWorker]);
host.start()
	.then(() => {
		console.log('Worker Host has started');
	}, err => {
		console.log('An error occurred on Worker Host startup: ', err);
	});
`;

const workerStarterKitDotNet = `class Program
{
	static void Main(string[] args)
	{
		// Configure the host with the specific workers we want to run
		var config = new WorkerHostConfig { ConnectionString = "/" };
		var workerHost = new WorkerHost(config, new[] {
		typeof(MyWorker),
		typeof(MyWorker2)
		});

		// Start the host, which registers and subscribes all the workers
		workerHost.Start();
		Console.WriteLine("Press any key to stop worker host");
		Console.ReadKey();

		// Stop the host, when we want to shut down
		workerHost.Stop();
		Console.WriteLine("All done!");
		Console.ReadKey();
	}
}

[TaskType("sample.myworker1")]
class MyWorker : TaskWorker
{
	public override async Task<TaskResult> Execute(TaskContext context)
	{
		LogInfo("Test 1a");
		await Task.Delay(1000);
		LogInfo("Test 1b");
		return TaskResult.Succeeded;
	}
}
`;

const workqueueStandardSubmission = `{
	"name": "convert-image-to-pdf",
	"description": "an example workqueue to convert an image to PDF",
	"initialState": {
		"imageUrl": "https://files-static.hpsiteflow.com/samples/CardSample_thumb.png"
	},
	"firstTasks": [
		"fetch file"
	],
	"tasks": [
		{
			"name": "fetch file",
			"type": "task.oneflow.fetch",
			"inputs": {
				"url": "$state.imageUrl"
			},
			"outputs": {
				"url": "$state.fetchedUrl"
			}
		},
		{
			"name": "convert to pdf",
			"type": "task.oneflow.pdfconverter",
			"inputs": {
				"url": "$state.fetchedUrl"
			},
			"outputs": {
				"url": "$state.pdfUrl"
			},
			"depends": [
				"fetch file"
			]
		}
	]
}
`;

export {
	workerStarterKitNode,
	workerStarterKitDotNet,
	workqueueStandardSubmission
};

<template>
	<DefaultLayout class="DeploymentDetail">
		<ContentHeader :title="title" :breadcrumbs="breadcrumbs">
			<template v-if="!createMode">
				<b-button
					class="DeploymentDetail__action"
					variant="danger"
					v-text="'Delete Deployment'"
					:disabled="page.isSubmitting"
					@click="onDeleteDeploymentClick"
				/>

				<b-button
					class="DeploymentDetail__action"
					variant="primary"
					v-text="actionText"
					:disabled="!isDeploymentValid || page.isSubmitting"
					@click="onActionButtonClick"
				/>
			</template>

			<template v-else>
				<b-button
					class="DeploymentDetail__action"
					variant="light"
					v-text="'Cancel'"
					:disabled="page.isSubmitting"
					:to="{ name: 'cluster.detail', params: { id: cluster._id } }"
				/>

				<b-button
					class="DeploymentDetail__action"
					variant="primary"
					v-text="'Create'"
					:disabled="!isDeploymentValid || page.isSubmitting"
					@click="onActionButtonClick"
				/>
			</template>
		</ContentHeader>

		<section class="DeploymentDetail__content">
			<loader class="App__loading" v-if="page.isLoading || page.isSubmitting" />

			<template v-if="!page.isLoading && !page.isSubmitting">
				<div class="DeploymentDetail__configuration">
					<h3>Configuration:</h3>
					<div class="DeploymentDetail__configuration__header">
						<b-form-group
							class="DeploymentDetail__configuration__header__toggle"
							name="DeploymentDetail__toggle"
							label="Advanced View"
							label-for="DeploymentDetail__toggle"
							label-size="sm"
							v-if="!createMode"
						>
							<b-form-checkbox v-model="page.advancedView" switch> </b-form-checkbox>
						</b-form-group>
					</div>

					<form v-if="!page.advancedView" @submit.prevent="onSubmitClick">
						<b-form-group label="Cluster" label-for="DeploymentDetail__cluster">
							<b-form-input id="DeploymentDetail__cluster" v-model="cluster.name" :disabled="true" />
						</b-form-group>

						<b-form-group label="Worker" label-for="DeploymentDetail__workerId">
							<b-form-input
								v-if="editedDeployment.workerId"
								id="DeploymentDetail__workerName"
								v-model="selectedWorker.name"
								:disabled="true"
							/>
							<multiselect
								v-else
								class="DeploymentDetail__workerId"
								v-model="selectedWorker"
								:options="workers"
								label="name"
								track-by="_id"
							/>
						</b-form-group>

						<b-form-group label="Docker Tag" label-for="DeploymentDetail__dockerTag">
							<b-form-input id="DeploymentDetail__dockerTag" v-model="editedDeployment.dockerTag" />
						</b-form-group>

						<b-form-group label="Replica Count" label-for="DeploymentDetail__replicaCount">
							<b-form-input id="DeploymentDetail__replicaCount" v-model="editedDeployment.replicaCount" />
						</b-form-group>
						<h3>Resources</h3>
						<b-form-group label="CPU Limit" label-for="DeploymentDetail__limitsCpu">
							<b-form-input
								id="DeploymentDetail__limitsCpu"
								v-model="editedDeployment.resources.limits.cpu"
							/>
						</b-form-group>
						<b-form-group label="Memory Limit" label-for="DeploymentDetail__limitsMemory">
							<b-form-input
								id="DeploymentDetail__limitsMemory"
								v-model="editedDeployment.resources.limits.memory"
							/>
						</b-form-group>
						<b-form-group label="CPU Request" label-for="DeploymentDetail__requestsCpu">
							<b-form-input
								id="DeploymentDetail__requestsCpu"
								v-model="editedDeployment.resources.requests.cpu"
							/>
						</b-form-group>
						<b-form-group label="Memory Request" label-for="DeploymentDetail__requestsMemory">
							<b-form-input
								id="DeploymentDetail__requestsMemory"
								v-model="editedDeployment.resources.requests.memory"
							/>
						</b-form-group>

						<environment-vars class="DeploymentDetail__environment" v-model="editedEnvironmentVars" />
					</form>
					<flux-editor v-if="page.advancedView" v-model="editedFluxConfig" />
				</div>
			</template>
		</section>

		<confirm-modal
			:visible="page.isConfirmDeleteDeploymentModalVisible"
			title="Delete Deployment"
			text="Are you sure you want to delete this deployment?"
			:danger="true"
			@ok="onConfirmDeleteDeploymentClick"
			@hidden="hideConfirmDeleteDeploymentModal"
		/>
	</DefaultLayout>
</template>

<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';
import { ContentHeader } from '@workflow-solutions/ofs-vue-layout';
import validWorkers from './validWorkers';
import DefaultLayout from '../../components/DefaultLayout';
import ConfirmModal from '../../components/ConfirmModal';
import FluxEditor from './components/FluxEditor';
import EnvironmentVars from './components/EnvironmentVars';

export default {
	components: {
		DefaultLayout,
		ContentHeader,
		ConfirmModal,
		FluxEditor,
		EnvironmentVars
	},
	data() {
		return {
			page: {
				isLoading: false,
				isSubmitting: false,
				isConfirmDeleteDeploymentModalVisible: false,
				advancedView: false
			},
			editedFluxConfig: null,
			editedDeployment: null,
			editedEnvironmentVars: null,
			workers: [],
			selectedWorker: null
		};
	},
	computed: {
		...mapGetters({
			cluster: 'cluster/cluster',
			deployment: 'deployment/deployment'
		}),
		deployments() {
			if (!this.deploymentsData) return [];
			return this.deploymentsData.data;
		},
		title() {
			if (this.createMode) return 'New Deployment';
			return this.deployment.alias;
		},
		actionText() {
			if (this.page.advancedView) return 'Update Configuration';
			return 'Update Deployment';
		},
		breadcrumbs() {
			return [
				{ text: 'All Clusters', to: { name: 'cluster.list' } },
				{
					text: this.cluster.name,
					to: { name: 'cluster.detail', params: { id: this.$route.params.clusterId } }
				},
				{ text: 'test', active: true }
			];
		},
		createMode() {
			return this.$route.params.deploymentId === 'new';
		},
		isDeploymentValid() {
			if (!this.editedDeployment) return false;
			if (!this.selectedWorker) return false;
			if (!this.editedDeployment.dockerTag) return false;
			if (!this.editedDeployment.replicaCount) return false;
			if (!this.editedDeployment.resources.limits.cpu) return false;
			if (!this.editedDeployment.resources.limits.memory) return false;
			if (!this.editedDeployment.resources.requests.cpu) return false;
			if (!this.editedDeployment.resources.requests.memory) return false;
			if (this.editedDeployment.environment[''] !== undefined) return false;
			return true;
		},
		environmentVariables() {
			return Object.keys(this.editedDeployment.environment).map(key => this.editedDeployment.environment[key]);
		},
		isAddEnvironmentVarDisabled() {
			return this.editedDeployment.environment[''] !== undefined;
		}
	},
	methods: {
		...mapActions({
			getCluster: 'cluster/get',
			createDeployment: 'deployment/create',
			getDeployment: 'deployment/get',
			deleteDeployment: 'deployment/deleteById',
			updateDeployment: 'deployment/update',
			updateFluxConfig: 'deployment/updateFluxConfig',
			getWorker: 'worker/get',
			findWorkers: 'worker/find'
		}),
		async fetch() {
			this.page.isLoading = true;
			try {
				const { clusterId, deploymentId } = this.$route.params;
				const requests = [this.getCluster({ id: clusterId })];
				if (!this.createMode) {
					requests.push(this.getDeployment({ id: deploymentId, query: { withConfig: true } }));
				}
				await Promise.all(requests);
				if (!this.createMode) {
					this.editedDeployment = this.deployment;
					this.editedFluxConfig = this.deployment.fluxConfig;
					this.editedEnvironmentVars = Object.keys(this.deployment.environment).map(key => ({
						key,
						value: this.deployment.environment[key]
					}));
				} else {
					this.editedDeployment = {
						clusterId: this.cluster._id,
						workerId: '',
						dockerTag: 'latest',
						replicaCount: 1,
						resources: {
							limits: {
								cpu: '1000m',
								memory: '128Mi'
							},
							requests: {
								cpu: '10m',
								memory: '64Mi'
							}
						},
						environment: {}
					};
					this.editedEnvironmentVars = [];
				}
				await this.fetchWorkers();
			} catch (err) {
				this.$toaster.error(`Error loading deployment: ${err}`);
				this.$router.push({ name: 'cluster.detail', params: { id: this.$route.params.clusterId } });
			}
			this.page.isLoading = false;
		},
		onDeleteDeploymentClick() {
			this.page.isConfirmDeleteDeploymentModalVisible = true;
		},
		async onConfirmDeleteDeploymentClick() {
			this.page.isSubmitting = true;
			try {
				await this.deleteDeployment({ id: this.$route.params.deploymentId });
				this.$toaster.success(`Deleted Deployment: ${this.editedDeployment.alias}`);
				this.$router.push({ name: 'cluster.detail', params: { id: this.$route.params.clusterId } });
			} catch (err) {
				this.$toaster.error(`Error deleting deployment: ${err}`);
			}
			this.page.isSubmitting = false;
		},
		hideConfirmDeleteDeploymentModal() {
			this.page.isConfirmDeleteDeploymentModalVisible = false;
		},
		async onActionButtonClick() {
			this.page.isSubmitting = true;
			const environment = {};
			this.editedEnvironmentVars.forEach(variable => {
				if (variable.key === '') return;
				environment[variable.key] = variable.value;
			});
			this.editedDeployment.environment = environment;
			try {
				if (this.createMode) {
					this.editedDeployment.workerId = this.selectedWorker._id;
					const result = await this.createDeployment(this.editedDeployment);
					this.$toaster.success(`Created deployment: ${this.selectedWorker.name}`);
					this.$router.push({
						name: 'cluster.deployment.detail',
						params: { clusterId: this.$route.params.clusterId, deploymentId: result._id }
					});
				} else if (!this.page.advancedView) {
					await this.updateDeployment({ id: this.$route.params.deploymentId, data: this.editedDeployment });
					this.$toaster.success(`Deployment updated: ${this.editedDeployment.alias}`);
				} else {
					await this.updateFluxConfig({ id: this.editedDeployment._id, data: this.editedFluxConfig });
					this.$toaster.success(`Configuration updated: ${this.editedDeployment.alias}`);
				}
				this.fetch();
			} catch (err) {
				this.$toaster.error(`Error updating deployment: ${err}`);
			}
			this.page.isSubmitting = false;
		},
		async fetchWorkers() {
			const results = [];
			const pagesize = 100;
			let page = 1;
			let fetchingWorkers = true;
			while (fetchingWorkers) {
				// eslint-disable-next-line no-await-in-loop
				const result = await this.findWorkers({
					query: { page, pagesize }
				});
				results.push(...result.data);
				page++;
				if (result.data.length !== pagesize) fetchingWorkers = false;
			}
			this.workers = results.filter(worker => validWorkers.includes(worker.name.toLowerCase()));
			if (this.editedDeployment.workerId) {
				this.selectedWorker = this.workers.find(worker => worker._id === this.deployment.workerId);
			}
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				this.fetch();
			}
		}
	}
};
</script>

<style lang="scss">
.DeploymentDetail {
	&__action {
		margin-left: 1rem;
	}

	&__content {
		padding: 1rem;
		display: flex;
		flex-direction: column;
	}

	&__configuration {
		&__header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			&__toggle {
				display: flex;
				flex-direction: row;

				label {
					margin: 0 1rem 0 0;
					padding: 0;
				}
			}
		}
	}

	&__environment {
		margin-top: 2rem;
	}
}
</style>

<template>
	<div class="EnvironmentVars">
		<div class="EnvironmentVars__header">
			<h3 class="EnvironmentVars__title">Environment Variables</h3>
			<b-button
				type="button"
				v-b-tooltip.hover title="Add"
				class="addConfigRow"
				size="md"
				@click="addVariable">
					<b-icon icon="plus-square-fill" variant="primary" aria-hidden="true"/>
			</b-button>
		</div>

		<b-row align-h="start">
			<b-col>
				<b-table
					:fields="configHeaders"
					:items="editedValue"
					head-variant="light"
					show-empty
					size="sm"
					empty-text="No environment variables defined for this worker">
					<template v-slot:cell(key)="data">
						<b-form-input
							v-model="data.item.key"
							size="sm"
							placeholder="Key"
							@change="emitUpdate"/>
					</template>
					<template v-slot:cell(value)="data">
						<SecretInput
							v-model="data.item.value"
							@change="emitUpdate"/>
					</template>
					<template v-slot:cell(actions)="data">
						<b-button
							type="button"
							v-b-tooltip.hover title="Remove"
							size="md"
							class="removeConfigButton"
							@click="removeVariable(data.index)">
								<b-icon icon="dash-square-fill" variant="danger" aria-hidden="true"/>
						</b-button>
					</template>
				</b-table>
			</b-col>
		</b-row>
	</div>
</template>

<script type="text/javascript">
import SecretInput from './SecretInput';

export default {
	components: { SecretInput },
	props: {
		value: { required: true, type: Array }
	},
	data() {
		return {
			configHeaders: [
				{ key: 'key', editable: true },
				{ key: 'value', value: 'actions', editable: true },
				'actions'
			],
			editedValue: []
		};
	},
	methods: {
		addVariable() {
			this.editedValue.push({ key: '', value: '' });
		},
		removeVariable(index) {
			this.editedValue.splice(index, 1);
		},
		emitUpdate() {
			this.$emit('input', this.editedValue);
		}
	},
	watch: {
		value: {
			immediate: true,
			handler() {
				this.editedValue = [...this.value];
			}
		}
	}
};
</script>

<style lang="scss">
.EnvironmentVars {

	&__header {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&__title {
		margin: 0 1rem 0 0;
	}

	.addConfigRow {
		padding: 0px;
	}

	.removeConfigButton {
		padding: 0px;
	}
}
</style>

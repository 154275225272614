import List from './List';
import Detail from './Detail';
import Workqueues from './components/Workqueues';
import Tasks from './components/Tasks';
import TasksSummary from './components/TasksSummary';
import Collaborators from './components/Collaborators';
import Settings from './components/Settings';
import RunWorkqueue from './components/RunWorkqueue';
import Workqueue from './components/Workqueue';

// The structuring here could be improved as to not have workqueue detail be a top level component.

const routes = [
	{
		name: 'app.list',
		path: '/apps',
		component: List,
		meta: {
			title: 'Apps'
		}
	},
	{
		path: '/apps/:id',
		name: 'app.detail',
		component: Detail,
		children: [
			{
				path: '',
				redirect: { name: 'app.detail.workqueues' }
			},
			{
				name: 'app.detail.workqueues',
				path: '/apps/:id/workqueues',
				component: Workqueues
			},
			{
				name: 'app.detail.workqueues.create',
				path: '/apps/:id/workqueues/create',
				component: RunWorkqueue
			},
			{
				name: 'app.detail.workqueue',
				path: '/apps/:id/workqueues/:workqueue',
				component: Workqueue
			},
			{
				name: 'app.detail.tasks',
				path: '/apps/:id/tasks',
				component: Tasks
			},
			{
				name: 'app.detail.tasksSummary',
				path: '/apps/:id/tasks-summary',
				component: TasksSummary
			},
			{
				name: 'app.detail.collaborators',
				path: '/apps/:id/collaborators',
				component: Collaborators
			},
			{
				name: 'app.detail.settings',
				path: '/apps/:id/settings',
				component: Settings
			}
		]
	}
];

export default routes;

<template>
	<AppLayout class="DefaultLayout">
		<top-bar slot="top-bar">
			<b-link slot="logo" :to="{ name: 'app.list' }">
				<img src="/images/header.png"/>
			</b-link>
			<template slot="account">
				<div>
					<b-dropdown variant="dark" size="md" right>
						<template v-slot:button-content>
							<b-icon class="DefaultLayout__icon" icon="person-fill"></b-icon>
						</template>
						<b-dropdown-item-button @click="onLogOutClick" >
							<b-icon icon="box-arrow-left" aria-hidden="true"></b-icon> Sign Out
						</b-dropdown-item-button>
					</b-dropdown>
				</div>
			</template>

		</top-bar>

		<div slot="content" class="DefaultLayout__content">
			<slot/>
		</div>

		<div slot="footer" v-if="hasSideNav">
			<p class="mb-0" v-text="`Version: ${version}`"></p>
		</div>

		<div slot="left-menu" v-if="!hasSideNav" />

	</AppLayout>
</template>

<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';
import { AppLayout, TopBar } from '@workflow-solutions/ofs-vue-layout';
import packageJSON from '../../package.json';

export default {
	components: {
		AppLayout,
		TopBar
	},
	props: {
		hasSideNav: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		...mapGetters('account', ['accounts', 'currentAccount']),
		...mapGetters('auth', ['isHpIdLogin'])
	},
	data() {
		return {
			version: packageJSON.version
		};
	},
	methods: {
		...mapActions('account', ['changeAccount', 'setCurrentAccount']),
		...mapActions('auth', ['logout']),
		hlop(account) {
			this.changeAccount(account);
			this.$nextTick(() => window.location.reload());
		},
		async onLogOutClick() {
			const redirectHpIdLogout = this.isHpIdLogin;
			this.setCurrentAccount(null);
			await this.logout();
			if (redirectHpIdLogout) {
				window.location.href = window.$config.hpIdLogout;
			} else {
				window.location.reload();
			}
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/src/styles/core';

.DefaultLayout {
    &__icon {
        color: #D3D8E0;
    }
	&__content {
		display: flex;
		flex-direction: column;
	}
}

</style>

import Vue from 'vue';

export default function () {
	Vue.$ofsCrud.addResource({
		name: 'deployment',
		pluralName: 'deployments',
		transport: 'workforce',
		actions: {
			updateFluxConfig({ dispatch }, { id, data }) {
				const path = `deployments/${id}/flux`;
				return dispatch('request', { method: 'PUT', path, payload: { fluxConfig: data } }).then(r => r.data);
			}
		}
	});
}

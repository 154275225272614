<template>
	<codemirror
		class="FluxEditor"
		:options="options"
		:value="value"
		@input="onInput"/>
</template>

<script type="text/javascript">
import 'codemirror/mode/yaml/yaml';
import codeEditorOptions from '../../../lib/codeEditorOptions';

export default {
	props: {
		value: { required: true, type: String }
	},
	data() {
		return {
			options: {
				...codeEditorOptions,
				mode: 'text/yaml'
			}
		};
	},
	methods: {
		onInput(data) {
			this.$emit('input', data);
		}
	}
};
</script>

<style lang="scss">

.FluxEditor {
	margin-top: 1rem;

	.CodeMirror {
		height: auto !important;
	}
}

</style>

import List from './List';
import Detail from './Detail';

const routes = [
	{
		name: 'worker.list',
		path: '/workers',
		component: List
	},
	{
		name: 'worker.detail',
		path: '/workers/:id',
		component: Detail
	}
];

export default routes;

import Vue from 'vue';

export default function () {

	Vue.$ofsCrud.addResource({
		name: 'app',
		pluralName: 'apps',
		options: {
			namespaced: true
		},
		transport: 'workforce',
		actions: {
			addAccessToken({ dispatch }, { appId }) {
				const path = `apps/${appId}/token`;
				return dispatch('request', { method: 'POST', path }).then(r => r.data);
			},
			deleteAccessToken({ dispatch }, { appId, tokenId }) {
				const path = `apps/${appId}/token/${tokenId}`;
				return dispatch('request', { method: 'DELETE', path }).then(r => r.data);
			},
			addVariable({ dispatch }, { id, data }) {
				const path = `apps/${id}/variables`;
				return dispatch('request', { method: 'POST', path, payload: data }).then(r => r.data);
			},
			deleteVariable({ dispatch }, { appId, variableId }) {
				const path = `apps/${appId}/variables/${variableId}`;
				return dispatch('request', { method: 'DELETE', path }).then(r => r.data);
			},
			addCollaborator({ dispatch }, { id, data }) {
				const path = `apps/${id}/collaborators`;
				return dispatch('request', { method: 'POST', path, payload: data }).then(r => r.data);
			},
			inviteCollaborator({ dispatch }, { id, data }) {
				const path = `apps/${id}/collaborators/invite`;
				return dispatch('request', { method: 'POST', path, payload: data }).then(r => r.data);
			},
			deleteCollaborator({ dispatch }, { appId, collaboratorId }) {
				const path = `apps/${appId}/collaborators/${collaboratorId}`;
				return dispatch('request', { method: 'DELETE', path }).then(r => r.data);
			},
			getCollaborators({ dispatch }, { id }) {
				const path = `apps/${id}/collaborators`;
				return dispatch('request', { method: 'GET', path }).then(r => r.data.collaborators);
			}
		},
		getters: {}
	});
}

<template>
	<section class="RunWorkqueue">

		<section class="RunWorkqueue__buttons">
			<b-button
				variant="primary"
				v-text="'Workqueue List'"
				@click="goToApp"/>
		</section>

		<b-tabs
			v-if="!page.isLoading"
			v-model="page.tabIndex"
			card>
			<b-tab title="From Plan" no-body>
				<list-table
					class="RunWorkqueue__listTable"
					:items="plans"
					:fields="planFields"
					:config="listConfig"
					:fetchData="fetch"
					:perPage="pagination.perPage"
					:currentPage="pagination.currentPage"
					:totalItems="pagination.total"
					hover
					@row-clicked="openPlanModal"
					@table-change="handleTableChange">
					<template slot="updated" slot-scope="data">
						<time-span :time="data.item.updated"/>
					</template>
				</list-table>
			</b-tab>
			<b-tab title="via API" no-body>
				<codemirror
					v-if="page.tabIndex === 1"
					class="RunWorkqueue__code"
					:options="codeEditorOptions"
					v-model="workqueueStandardSubmission"/>
				<b-button @click="submitStandardAPI" class="mt-3" variant="primary">Submit</b-button>
			</b-tab>
		</b-tabs>

		<loader class="App__loading" v-if="page.isLoading"/>

		<run-plan-modal
			:appId="app._id"
			:plan="currentPlan"
			:visible="isRunPlanModalVisible"
			@hidden="onRunPlanModalClose"
			@submit="onRunPlanModalSubmit"/>
	</section>
</template>

<script>
import { ListTable } from '@workflow-solutions/ofs-vue-layout';
import { mapGetters, mapActions } from 'vuex';
import RunPlanModal from '../../../components/RunPlanModal.vue';
import TimeSpan from '../../../components/TimeSpan.vue';
import listConfig from '../../../lib/listConfig';
import codeEditorOptions from '../../../lib/codeEditorOptions';
import { workqueueStandardSubmission } from '../../../lib/codeSnippets';

export default {
	components: {
		ListTable,
		RunPlanModal,
		TimeSpan
	},
	data() {
		return {
			listConfig,
			codeEditorOptions,
			workqueueStandardSubmission,
			page: {
				isLoading: false,
				tabIndex: 0
			},
			pagination: {
				currentPage: 1,
				perPage: 10,
				total: 0
			},
			planFields: ['name', 'description', 'updated'],
			isRunPlanModalVisible: false,
			currentPlan: {
				initialState: {},
				name: ''
			}
		};
	},
	computed: {
		...mapGetters({
			app: 'app/app',
			plansData: 'plan/plans'
		}),
		plans() {
			return this.plansData.data;
		},
		planOptions() {
			if (this.currentPlan.initialState) {
				return Object.keys(this.currentPlan.initialState);
			}
			return [];
		},
		queryWorkqueue() {
			if (!this.$route.query.workqueue) return null;
			return JSON.parse(this.$route.query.workqueue);
		}
	},
	methods: {
		...mapActions({
			getApp: 'app/get',
			findplans: 'plan/find',
			startWorkqueue: 'workqueue/startWorkqueue'
		}),
		async fetch() {
			this.page.isLoading = true;
			try {
				await Promise.all([
					this.getApp({ id: this.$route.params.id }),
					this.findplans({
						id: this.$route.params.id,
						query: {
							page: parseInt(this.$route.query.page, 10),
							pagesize: parseInt(this.$route.query.perPage, 10)
						}
					})
				]);
				this.pagination.total = this.plansData.total;
			} catch (err) {
				this.$toaster.error(`Error loading data: ${err}`);
			}
			this.page.isLoading = false;
		},
		async submitWorkqueue(newWorkqueue) {
			this.page.isLoading = true;
			try {
				const workqueue = await this.startWorkqueue({ appId: this.$route.params.id, data: newWorkqueue });
				this.$toaster.success(`Workqueue: [${workqueue.name}] started.`);
				this.$router.push({
					name: 'app.detail.workqueue',
					params: { id: workqueue.appId, workqueue: workqueue._id }
				});
			} catch (err) {
				this.$toaster.error(`Couldn't start workqueue [${newWorkqueue.name}]... ${err}`);
			}
			this.page.isLoading = false;
		},
		async submitStandardAPI() {
			this.page.isLoading = true;
			try {
				const workqueue = JSON.parse(this.workqueueStandardSubmission);
				await this.submitWorkqueue(workqueue);
			} catch (err) {
				this.$toaster.error(`Couldn't parse workqueue... ${err}`);
			}
			this.page.isLoading = false;
		},
		openPlanModal(item) {
			this.currentPlan = item;
			this.isRunPlanModalVisible = true;
		},
		onRunPlanModalClose() {
			this.isRunPlanModalVisible = false;
		},
		onRunPlanModalSubmit(workqueue) {
			this.$router.push({
				name: 'app.detail.workqueue',
				params: { id: workqueue.appId, workqueue: workqueue._id }
			});
		},
		goToApp() {
			this.$router.push({
				name: 'app.detail.workqueues',
				params: { id: this.$route.params.id }
			});
		},
		handleTableChange({ currentPage, perPage }) {
			this.pagination.currentPage = currentPage;
			this.pagination.perPage = perPage;
			this.updateQuery();
		},
		updateQuery() {
			this.$router.replace({
				query: {
					...this.$route.query,
					page: this.pagination.currentPage,
					perPage: this.pagination.perPage
				}
			}).catch(e => {
				if (e.name !== 'NavigationDuplicated') throw e;
			});
		}
	},
	watch: {
		queryWorkqueue: {
			immediate: true,
			handler() {
				if (this.queryWorkqueue) {
					this.workqueueStandardSubmission = JSON.stringify(this.queryWorkqueue, null, '\t');
					this.page.tabIndex = 1;
				}
			}
		},
		$route: {
			immediate: true,
			handler() {
				if (this.$route.query.page) this.pagination.currentPage = parseInt(this.$route.query.page, 10);
				if (this.$route.query.perPage) this.pagination.perPage = parseInt(this.$route.query.perPage, 10);
				this.updateQuery();
				this.fetch();
			}
		}
	}
};
</script>

<style lang="scss">
.RunWorkqueue {
	padding: 1rem;

	&__buttons {
		margin-bottom: 1rem;
		display: flex;
		flex-direction: row;
	}

	&__code {
		.CodeMirror {
			height: auto;
		}
	}

	&__listTable {
		margin-top: -15px;
	}
}
</style>

const menuStructure = [
	{
		title: 'Apps',
		matches: [
			{ name: 'app.detail' },
			{ name: 'app.detail.workqueues.create' },
			{ name: 'app.detail.workqueue' }
		],
		items: [
			{
				title: 'Apps',
				items: [
					{ title: 'Apps', name: 'app.list' }
				]
			}
		]
	},
	{
		title: 'Plans',
		matches: [
			{ name: 'plan.detail' }
		],
		items: [
			{
				title: 'Plans',
				items: [
					{ title: 'Plans', name: 'plan.list' }
				]
			}
		]
	},
	{
		title: 'Workers',
		matches: [
			{ name: 'worker.detail' }
		],
		items: [
			{
				title: 'Workers',
				items: [
					{ title: 'Workers', name: 'worker.list' }
				]
			}
		]
	},
	{
		title: 'Scripts',
		matches: [
			{ name: 'script.detail' }
		],
		items: [
			{
				title: 'Scripts',
				items: [
					{ title: 'Scripts', name: 'script.list' }
				]
			}
		]
	},
	{
		title: 'Teams',
		matches: [
			{ name: 'team.detail' }
		],
		items: [
			{
				title: 'Teams',
				items: [
					{ title: 'Teams', name: 'team.list' }
				]
			}
		]
	},
	{
		title: 'Dashboard',
		matches: [
			{ name: 'dashboard' }
		],
		items: [
			{
				title: 'Dashboard',
				items: [
					{ title: 'Backlog', name: 'dashboard' }
				]
			}
		]
	},
	{
		title: 'Clusters',
		matches: [
			{ name: 'cluster.detail' },
			{ name: 'cluster.deployment.detail' }
		],
		items: [
			{
				title: 'Clusters',
				items: [
					{ title: 'Clusters', name: 'cluster.list' }
				]
			}
		]
	}
];

export default menuStructure;

<template>
<svg viewBox="0 0 1 1" preserveAspectRatio="none" class="tracer">
	<rect
		:x="traceQueuedPosition"
		y="0"
		:width="queuedWidth"
		height="1"
		class="trace queued"
	/>
	<rect
		:x="traceStartPosition"
		y="0"
		:width="traceWidth"
		height="1"
		class="trace"
	/>
</svg>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

export default {
	props: {
		startTime: {
			type: String
		},
		endTime: {
			type: String
		},
		queuedTime: {
			type: String
		},
		traceStartTime: {
			type: String
		},
		traceEndTime: {
			type: String
		}
	},
	computed: {
		...mapState(['now']),
		startPoint() {
			return moment(this.startTime || undefined);
		},
		endPoint() {
			return moment(this.endTime || this.now);
		},
		traceQueuedPosition() {
			return this.placeDateOnScale(this.queuedTime || this.now);
		},
		traceStartPosition() {
			return this.placeDateOnScale(this.traceStartTime || this.now);
		},
		traceEndPosition() {
			return this.placeDateOnScale(this.traceEndTime || this.now);
		},
		traceWidth() {
			const width = this.traceEndPosition - this.traceStartPosition;
			return width < 0 ? 0 : width;
		},
		queuedWidth() {
			const width = this.traceStartPosition - this.traceQueuedPosition;
			return width < 0 ? 0 : width;
		}
	},
	methods: {
		placeDateOnScale(date) {
			return (moment(date) - this.startPoint) / (this.endPoint - this.startPoint);
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/src/styles/core';


.tracer {
	width: 100%;
	height: 1rem;
	border: 1px solid $primary;
	border-radius: $border-radius;

}
.trace {
	fill: $primary;
	stroke-width: 0;

	&.queued {
		fill: $of-color-status-pending;
	}
}
</style>

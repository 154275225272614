<template>
	<b-input-group-append size="sm">
		<b-form-input
			size="sm"
			v-model="editedValue"
			:type="inputType"
			@input="updateInput"
			placeholder="Value"/>
			<b-button type="button" size="sm" @click="switchVisibility">
			<b-icon name="eye_icon_table" :icon="icon" aria-hidden="true"/>
		</b-button>
	</b-input-group-append>
</template>

<script>
export default {
	name: 'SecretInput',
	props: {
		value: { required: true, type: String }

	},
	data() {
		return {
			editedValue: '',
			hidden: true
		};
	},
	computed: {
		icon() {
			if (this.hidden) return 'eye';
			return 'eye-slash';
		},
		inputType() {
			if (this.hidden) return 'password';
			return 'text';
		}
	},
	methods: {
		switchVisibility() {
			this.hidden = !this.hidden;
		},
		updateInput() {
			this.$emit('input', this.editedValue);
		}
	},
	watch: {
		value: {
			immediate: true,
			handler() {
				this.editedValue = this.value;
			}
		}
	}
};
</script>

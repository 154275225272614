import Vue from 'vue';
import Router from 'vue-router';
import qs from 'qs';
import routes from './routes';
import menuStructure from './menuStructure';

Vue.use(Router);

const router = new Router({
	routes: [...routes],
	parseQuery(query) {
		return qs.parse(query, { comma: true });
	},
	stringifyQuery(query) {
		const result = qs.stringify(query, {
			encodeValuesOnly: true,
			arrayFormat: 'comma'
		});

		return result ? `?${result}` : '';
	}
});

export default router;
export { routes, menuStructure };

<template>
	<b-modal
		class="ViewSourceModal"
		:visible="visible"
		:title="title"
		size="lg"
		ok-only
		@hidden="$emit('hidden')">

		<codemirror
			class="ViewSourceModal__code"
			ref="codemirrorElement"
			v-if="showCodeEditor"
			:value="source"
			:options="readOnlyCodeEditor"/>
			
		<b-button
			class="ViewSourceModal__copy"
			@click="onCopyClick" 
			v-if="copyBtn"
			size="sm"
			v-text="'Copy'"/>

	</b-modal>
</template>

<script>
import codeEditorOptions from '../../../lib/codeEditorOptions';

export default {
	props: {
		title: {
			type: String,
			default: 'Source'
		},
		visible: {
			type: Boolean,
			required: true
		},
		copyBtn: {
			type: Boolean,
			default: false
		},
		source: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			codeEditorOptions,
			showCodeEditor: false
		};
	},
	computed: {
		readOnlyCodeEditor() {
			return {
				...codeEditorOptions,
				readOnly: true
			};
		}
	},
	methods: {
		async onCopyClick() {
			await navigator.clipboard.writeText(this.source);
		}
	},
	watch: {
		visible: {
			immediate: true,
			async handler() {
				await this.$nextTick();
				if (this.visible) {
					this.showCodeEditor = true;

					// the below logic ensures that the codemirror renders the text without requiring user interaction first
					// https://stackoverflow.com/questions/8349571/codemirror-editor-is-not-loading-content-until-clicked
					setTimeout(() => {
						this.$refs.codemirrorElement.cminstance.refresh();
					}, 1);
				} else {
					this.showCodeEditor = false;
				}
			}
		}
	}
};
</script>

<style lang="scss">

.ViewSourceModal {

	.modal-dialog {
		min-width: 80vw;
	}

	&__code {
		.CodeMirror {
			height: 80vh;
		}
	}

	&__copy {
		margin-top: 1rem;
		clear: both;
	}
}
</style>

<template>
	<b-button @click="onButtonClick">
		<span v-if="!eitherSelected" v-text="'Select dates'" />
		<template v-else>
			<span v-if="from">
				<span v-if="!to">From:</span>
				{{ moment(from).format('YYYY-MM-DD HH:mm') }} {{ timeZone }}
			</span>
			<span v-if="to">
				<span v-if="!from">To:</span>
				<span v-else>-</span>
				{{ moment(to).format('YYYY-MM-DD HH:mm') }} {{ timeZone }}
			</span>
		</template>
	</b-button>
</template>

<script>
import moment from 'moment-timezone';

export default {
	props: {
		from: { type: String },
		to: { type: String }
	},
	data: () => ({
		moment
	}),
	computed: {
		timeZone() {
			const timeZone = moment.tz.guess();
			const time = new Date();
			const timeZoneOffset = time.getTimezoneOffset();
			return moment.tz.zone(timeZone).abbr(timeZoneOffset);
		},
		eitherSelected() {
			return this.to || this.from;
		}
	},
	methods: {
		onButtonClick() {
			this.$emit('click');
		}
	}
};
</script>

<template>
	<b-modal
		:visible="visible"
		title="New Alias"
		ok-title="Add"
		:ok-disabled="page.isSubmitting"
		@ok="onSubmit"
		@hidden="$emit('hidden')">

		<form @submit.prevent="onSubmit">
			<b-form-group
				label="Alias Name"
				label-for="AddAlias__name">
				<b-form-input
					id="AddAlias__name"
					v-model="newAliasName"
					:disabled="page.isSubmitting"/>
			</b-form-group>
		</form>

	</b-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	props: {
		visible: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			page: {
				isSubmitting: false
			},
			newAliasName: null
		};
	},
	methods: {
		...mapActions({
			createAlias: 'worker/addAlias'
		}),
		async onSubmit(event) {
			event.preventDefault();
			this.page.isSubmitting = true;
			try {
				await this.createAlias({
					id: this.$route.params.id,
					aliasName: this.newAliasName
				});
				this.$toaster.success(`Created alias: ${this.newAliasName}`);
				this.$emit('submit');
				this.$emit('hidden');
			} catch (err) {
				this.$toaster.error(`Error creating alias: ${err}`);
			}
			this.page.isSubmitting = false;
		}
	},
	watch: {
		visible() {
			this.newAliasName = null;
		}
	}
};
</script>

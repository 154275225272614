import Vue from 'vue';
import _ from 'lodash';
import pusherClient from '../../lib/pusherClient';

export default function () {

	Vue.$ofsCrud.addResource({
		name: 'task',
		options: {
			namespaced: true
		},
		transport: 'workforce',
		state: {
			backlog: [],
			subscriptions: {}
		},
		mutations: {
			SET_BACKLOG(state, backlog) {
				const typeAlias = ({ type, alias }) => `${type}-${alias}`;
				state.backlog = _.values(_.merge({}, _.keyBy(state.backlog, typeAlias), _.keyBy(backlog, typeAlias)));
			},
			PATCH_BACKLOG(state, taskEvent) {
				const { type, alias, status } = taskEvent.data;
				const typeData = state.backlog.find(t => t.type === type && t.alias === alias);

				if (typeData) {
					switch (status) {
						case 'queued':
							typeData.queued += 1;
							break;
						case 'started':
							typeData.queued -= 1;
							typeData.started += 1;
							break;
						case 'retry':
							typeData.started -= 1;
							typeData.retry += 1;
							break;
						case 'failed':
							typeData.started -= 1;
							break;
						case 'ended':
							typeData.started -= 1;
							break;
						default:
							throw new Error(`unhandled status: ${status}`);
					}
					typeData.retry = Math.max(0, typeData.retry);
					typeData.queued = Math.max(0, typeData.queued);
					typeData.started = Math.max(0, typeData.started);
				} else {
					const newRow = {
						alias,
						type,
						queued: 0,
						started: 0,
						retry: 0
					};
					newRow[status] += 1;
					state.backlog.push(newRow);
				}
			}
		},
		actions: {
			async fetchBacklog({ commit, dispatch }) {
				const path = 'tasks/backlog';
				const response = await dispatch('request', { method: 'GET', path });
				const backlog = response.data;
				commit('SET_BACKLOG', backlog);
				return backlog;
			},
			subscribeToTasks({ commit, state }) {
				const channelName = 'tasks';
				if (state.subscriptions[channelName]) return;
				pusherClient.subscribeToChannel(
					channelName,
					doc => commit('PATCH_BACKLOG', doc.data)
				);
				state.subscriptions[channelName] = true;
			},
			unsubscribeFromTasks({ state }) {
				const channelName = 'tasks';
				pusherClient.unsubscribeFromChannel(channelName);
				delete state.subscriptions[channelName];
			}
		},
		getters: {
			backlog: state => state.backlog
		}
	});
}

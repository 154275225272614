<template>
	<section class="AppWorkqueues">
		<section class="AppWorkqueues__actions">
			<section class="AppWorkqueues__buttons">
				<b-button variant="primary" v-text="'Run New Workqueue'" @click.prevent="goToCreateWorkqueue" />
				<b-button
					v-if="isCancelWorkqueuesButtonVisible"
					variant="danger"
					:disabled="page.isSubmitting"
					v-text="cancelWorkqueuesText"
					@click.prevent="onCancelWorkqueuesClick"
				/>
			</section>

			<b-form class="AppWorkqueues__filters">
				<div class="AppWorkqueues__filter">
					<label class="AppWorkqueues__label" v-text="'Status:'" />
					<b-form-select
						class="AppWorkqueues__label"
						:options="statusOptions"
						v-model="filters.status"
						@input="updateQuery"
					/>
				</div>

				<div class="AppWorkqueues__filter">
					<label class="AppWorkqueues__label" v-text="'Tags:'" />
					<multiselect
						class="AppWorkqueues__multiselect"
						placeholder=""
						v-model="filters.tags"
						:options="filters.tagOptions"
						:multiple="true"
						:taggable="true"
						@tag="onAddTagFilter"
						@input="updateQuery"
					/>
				</div>

				<div class="AppWorkqueues__filter">
					<label class="AppWorkqueues__label" v-text="'Created:'" />
					<date-interval-picker-button
						:from="filters.created.$gte"
						:to="filters.created.$lte"
						@click="onDatePickerButtonClick"
					/>
				</div>
			</b-form>
		</section>

		<date-interval-picker-collapse
			:open="page.isDatePickerOpen"
			:from="filters.created.$gte"
			:to="filters.created.$lte"
			@input="handleCreatedInput"
			@hide="onDatePickerCollapseClose"
		/>

		<loader class="App__loading" v-if="page.isLoading" />

		<template v-if="!page.isLoading">
			<list-table
				class="AppWorkqueues__list"
				:items="workqueues"
				:fields="workqueueFields"
				:config="listConfig"
				:perPage="pagination.perPage"
				:currentPage="pagination.currentPage"
				:totalItems="pagination.totalItems"
				:fetchData="fetch"
				selectable
				select-mode="multi"
				striped
				@row-selected="selectWorkqueues"
				@table-change="handleTableChange"
			>
				<template v-slot:cell(name)="data">
					<span @click.stop>
						<router-link
							:to="{
								name: 'app.detail.workqueue',
								params: { id: data.item.appId, workqueue: data.item._id }
							}"
							v-text="data.item.name"
						/>
					</span>
				</template>

				<template v-slot:cell(created)="data">
					<time-span :time="data.item.created" />
				</template>

				<template v-slot:cell(duration)="data">
					<span v-if="data.item.ended" v-text="getFormattedDuration(data.item.started, data.item.ended)" />
					<span v-else v-text="'In Progress'" />
				</template>

				<template v-slot:cell(status)="data">
					<ofs-badge :text="data.item.status" :status="data.item.status" />
				</template>
			</list-table>
		</template>
	</section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { OfsBadge, ListTable } from '@workflow-solutions/ofs-vue-layout';
import moment from 'moment';
import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import getFormattedDuration from '../../../lib/getFormattedDuration';
import listConfig from '../../../lib/listConfig';
import TimeSpan from '../../../components/TimeSpan.vue';
import DateIntervalPickerButton from '../../../components/form/DateIntervalPickerButton.vue';
import DateIntervalPickerCollapse from '../../../components/form/DateIntervalPickerCollapse.vue';

export default {
	components: {
		OfsBadge,
		ListTable,
		TimeSpan,
		DateIntervalPickerButton,
		DateIntervalPickerCollapse
	},
	data() {
		return {
			listConfig,
			page: {
				isLoading: false,
				isSubmitting: false,
				isIdle: false,
				isDatePickerOpen: false
			},
			pagination: {
				totalItems: 0,
				currentPage: 1,
				perPage: 10
			},
			filters: {
				status: null,
				created: {
					$gte: null,
					$lte: null
				},
				tags: [],
				tagOptions: []
			},
			appId: null,
			workqueueFields: [
				{ key: 'name', label: 'Name' },
				{ key: 'description', label: 'Description' },
				{ key: 'created', label: 'Created' },
				{ key: 'duration', label: 'Duration' },
				{ key: 'status', label: 'Status' }
			],
			statusOptions: [
				{ value: null, text: 'All Items' },
				{ value: 'notstarted', text: 'Not Started' },
				{ value: 'started', text: 'Started' },
				{ value: 'completed', text: 'Completed' },
				{ value: 'failed', text: 'Failed' },
				{ value: 'cancelled', text: 'Cancelled' }
			],
			selectedWorkqueueIds: []
		};
	},
	computed: {
		...mapGetters({
			workqueuesData: 'workqueue/workqueues'
		}),
		workqueues() {
			if (!this.workqueuesData || Array.isArray(this.workqueuesData)) return [];
			return this.workqueuesData.data;
		},
		criteria() {
			const criteria = {
				appId: this.appId,
				page: this.pagination.currentPage,
				pagesize: this.pagination.perPage,
				created: this.filters.created,
				status: this.filters.status,
				tags: this.filters.tags
			};
			return criteria;
		},
		cancelWorkqueuesText() {
			const selectedCount = this.selectedWorkqueueIds.length;
			if (selectedCount < 2) return 'Cancel workqueue';
			return `Cancel ${selectedCount} workqueues`;
		},
		isCancelWorkqueuesButtonVisible() {
			return this.selectedWorkqueueIds.length !== 0;
		},
		areAllWorkqueuesCompleted() {
			let allCompleted = true;
			this.workqueues.forEach(workqueue => {
				if (workqueue.status !== 'completed') allCompleted = false;
			});
			return allCompleted;
		},
		hasDateSelection() {
			return this.filters.created.$gte || this.filters.created.$lte;
		}
	},
	methods: {
		...mapActions({
			subscribeToWorkqueues: 'workqueue/subscribeToWorkqueues',
			unsubscribeFromWorkqueues: 'workqueue/unsubscribeFromWorkqueues',
			findWorkqueues: 'workqueue/find',
			cancelWorkqueue: 'workqueue/cancel'
		}),
		moment,
		getFormattedDuration,
		async fetch() {
			this.page.isLoading = true;
			try {
				let criteria = { ...this.criteria };

				if (Array.isArray(criteria.status)){
					criteria.status = criteria.status[0];
				};
				if (criteria.tags) criteria.tags = criteria.tags.join(',');
				if (criteria.created) {
					// send standard querystring object notation => created[$gte]=...
					const createdProps = reduce(
						this.criteria.created,
						(acc, d, key) => {
							acc[`created[${key}]`] = moment(d).isValid() ? moment(d).toISOString() : null;
							return acc;
						},
						{}
					);
					criteria = omit({ ...criteria, ...createdProps }, ['created']);
				}

				await this.findWorkqueues({
					query: criteria
				});
				if (this.pagination.currentPage !== 1) {
					this.filters.workqueueIds = this.workqueues.map(workqueue => workqueue._id);
				} else {
					delete this.filters.workqueueIds;
				}
				this.filters.perPage = this.pagination.perPage;
				this.pagination.totalItems = this.workqueuesData.total;
				this.subscribeToWorkqueues({
					appId: this.appId,
					filters: this.filters
				});
			} catch (err) {
				this.$toaster.error(`Error loading workqueues: ${err}`);
			}
			this.page.isLoading = false;
		},
		goToCreateWorkqueue() {
			this.$router.push({
				name: 'app.detail.workqueues.create',
				params: { id: this.appId }
			});
		},
		onAddTagFilter(tag) {
			if (!this.filters.tagOptions.includes(tag)) {
				this.filters.tagOptions.push(tag);
			}
			this.filters.tags.push(tag);
			this.updateQuery();
		},
		onRemoveTagFilter(tag) {
			this.filterTags.splice(this.filterTags.indexOf(tag), 1);
		},
		async onCancelWorkqueuesClick() {
			this.page.isSubmitting = true;
			try {
				await Promise.all(this.selectedWorkqueueIds.map(id => this.cancelWorkqueue({ workqueueId: id })));
				this.$toaster.success(`Cancelled ${this.selectedWorkqueueIds.length} workqueues`);
			} catch (err) {
				this.$toaster.error(`Couldn't cancel workqueues: ${err}`);
			}
			this.page.isSubmitting = false;
			this.fetch();
		},
		selectWorkqueues(workqueues) {
			this.selectedWorkqueueIds = workqueues.map(workqueue => workqueue._id);
		},
		handleCreatedInput({ from, to }) {
			this.filters.created = {
				$gte: moment(from).isValid() ? moment(from).toISOString() : null,
				$lte: moment(to).isValid() ? moment(to).toISOString() : null
			};
			this.updateQuery();
		},
		handleTableChange({ currentPage, perPage }) {
			this.pagination.currentPage = currentPage;
			this.pagination.perPage = perPage;
			this.updateQuery();
		},
		updateQuery() {
			const query = omit({ ...this.$route.query, ...this.criteria }, ['appId']);
			this.$router.replace({ query }).catch(e => {
				if (e.name !== 'NavigationDuplicated') throw e;
			});
		},
		onDatePickerButtonClick() {
			this.page.isDatePickerOpen = !this.page.isDatePickerOpen;
		},
		onDatePickerCollapseClose() {
			this.page.isDatePickerOpen = false;
		}
	},
	beforeDestroy() {
		this.unsubscribeFromWorkqueues({ appId: this.appId });
	},
	onIdle() {
		this.$toaster.info('Inactivity detected: disabling live updates');
		this.unsubscribeFromWorkqueues({ appId: this.appId });
		this.page.isIdle = true;
	},
	onActive() {
		if (this.page.isIdle) this.$toaster.info('Activity detected: enabling live updates');
		if (this.areAllWorkqueuesCompleted && this.pagination.currentPage !== 1) return;
		this.subscribeToWorkqueues({
			appId: this.appId,
			filters: this.filters
		});
		this.page.isIdle = false;
	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				if (this.appId !== this.$route.params.id) this.unsubscribeFromWorkqueues({ appId: this.appId });
				this.appId = this.$route.params.id;
				if (this.$route.query.page) this.pagination.currentPage = parseInt(this.$route.query.page, 10);
				if (this.$route.query.perPage) this.pagination.perPage = parseInt(this.$route.query.perPage, 10);
				if (this.$route.query.status) {
					if (Array.isArray(this.$route.query.status)) {
						this.filters.status = this.$route.query.status[0];
					} else {
						this.filters.status = this.$route.query.status;
					}
				}
				if (this.$route.query.tags && this.$route.query.tags.length > 0) {
					this.filters.tags = [...this.$route.query.tags];
					this.filters.tagOptions = [...this.filters.tags];
				}
				if (this.$route.query.created) {
					const created = JSON.parse(JSON.stringify(this.$route.query.created));
					this.filters.created = {};
					['$lte', '$gte'].forEach(queryParam => {
						if (Array.isArray(created[queryParam])) [created[queryParam]] = created[queryParam];
						this.filters.created[queryParam] = moment(created[queryParam]).isValid()
							? moment(created[queryParam]).toISOString()
							: null
					});
				}
				this.updateQuery();
				this.fetch();
			}
		},
		workqueues: {
			deep: true,
			immediate: true,
			handler() {
				if (this.areAllWorkqueuesCompleted && this.pagination.currentPage !== 1) {
					this.unsubscribeFromWorkqueues({ appId: this.appId });
				}
			}
		},
		pagination: {
			deep: true,
			immediate: true,
			handler() {
				if (this.areAllWorkqueuesCompleted && this.pagination.currentPage !== 1) {
					this.unsubscribeFromWorkqueues({ appId: this.appId });
				} else {
					this.subscribeToWorkqueues({
						appId: this.appId,
						filters: this.filters
					});
				}
			}
		}
	}
};
</script>

<style lang="scss">
.AppWorkqueues {
	padding: 1rem;
	overflow: auto;

	&__actions {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 1rem;
	}

	&__buttons {
		> * {
			margin-right: 1rem;
		}
	}

	&__filters {
		display: flex;
		flex-direction: row;
		flex: 1;
		justify-content: flex-end;
	}

	&__filter {
		margin-left: 1rem;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&__label {
		margin-right: 0.5rem;
		margin-bottom: 0;
	}

	&__multiselect {
		min-width: 13rem;
	}

	&__pagination {
		margin-top: 1rem;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	&__perPage {
		height: fit-content;
	}
}
</style>

import ClusterList from './ClusterList';
import ClusterDetail from './ClusterDetail';
import DeploymentDetail from './DeploymentDetail';

const routes = [
	{
		name: 'cluster.list',
		path: '/clusters',
		component: ClusterList
	},
	{
		name: 'cluster.deployment.detail',
		path: '/clusters/:clusterId/deployment/:deploymentId',
		component: DeploymentDetail
	},
	{
		name: 'cluster.detail',
		path: '/clusters/:id',
		component: ClusterDetail
	}
];

export default routes;

import Vue from 'vue';
import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

const accountStore = {};
accountStore.state = {
	account: {
		accountSettings: {}
	},
	currentAccount: null,
	accounts: [],
	memberships: [],
	accountMemberships: null,
	user: {},
	member: null,
	vars: {
		userAccountHash: {}
	}
};

accountStore.actions = {
	...actions
};

accountStore.mutations = {
	...mutations
};

accountStore.getters = {
	...getters
};

export default function () {
	const { authBase } = window.$config;
	// store.registerModule('account', accountStore);
	Vue.$ofsCrud.registerAccountModule({ authBase, moduleOpts: accountStore });
}

import Vue from 'vue';
import OfsMenu from '@workflow-solutions/ofs-vue-layout';
import '@workflow-solutions/ofs-vue-layout/dist/index.css';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VueCodemirror from 'vue-codemirror';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/yaml/yaml';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import Toaster from 'v-toaster';
import 'v-toaster/dist/v-toaster.css';
import VueClipboard from 'vue-clipboard2';
import Multiselect from 'vue-multiselect';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import IdleVue from 'idle-vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import store from './vuex';
import router, { menuStructure } from './router';
import App from './App';
import packageJson from '../package.json';
import Loader from './components/Loader';
import './icons';

// TODO: configure Sentry key to work with S3CF wf-ui
// Sentry Error Logging
const { sentryKey } = window.$config;
if (sentryKey && window.location.hostname !== 'localhost') {
	Sentry.init({
		dsn: sentryKey,
		release: packageJson.version,
		environment: window.$config.environment,
		integrations: [new Integrations.Vue({ Vue })]
	});
}

Vue.config.productionTip = false;
Vue.use(OfsMenu, { router, store, menu: menuStructure });
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueCodemirror);
Vue.use(VueClipboard);
Vue.use(IdleVue, {
	eventEmitter: new Vue(),
	idleTime: 1000 * 60 * 5
});
Vue.use(Toaster, { timeout: 7000 });
Vue.component('loader', Loader);
Vue.component('multiselect', Multiselect);
Vue.component('fa-icon', FontAwesomeIcon);

/* eslint-disable no-new */
new Vue({
	el: '#app',
	router,
	store,
	components: { App },
	template: '<App/>'
});

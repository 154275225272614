<template>
	<time
		class="TimeSpan"
		v-text="formattedTime"
		v-b-tooltip.hover.html
		:title="timeToolTip"
		:datetime="time"/>
</template>

<script>
import moment from 'moment-timezone';

export default {
	props: {
		time: {
			type: String
		},
		format: {
			type: String,
			default: 'fromNow'
		}
	},
	data() {
		return {
			formattedTime: null
		};
	},
	computed: {
		timeZone() {
			const timeZone = moment.tz.guess();
			const time = new Date();
			const timeZoneOffset = time.getTimezoneOffset();
			return moment.tz.zone(timeZone).abbr(timeZoneOffset);
		},
		timeToolTip() {
			let localTime = '';
			if (moment().utcOffset()) {
				localTime = `<br/>${moment(this.time).format('YYYY-MM-DD HH:mm:ss.SSS')} ${this.timeZone}`;
			}
			return (this.time + localTime);
		}
	},
	methods: {
		moment,
		formatFromNow(time) {
			return moment(time).fromNow();
		},
		formatShortTime(time) {
			return moment(time).format('HH:mm:ss');
		}
	},
	watch: {
		time: {
			immediate: true,
			handler() {
				if (!this.time) {
					this.formattedTime = '-';
					return;
				}
				switch (this.format) {
					case 'shortTime':
						this.formattedTime = this.formatShortTime(this.time);
						break;
					case 'fromNow':
					default:
						this.formattedTime = this.formatFromNow(this.time);
						break;
				}
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.TimeSpan {
	&:hover {
		cursor: default; // fixes a curious tooltip bug on chrome
	}
}
</style>

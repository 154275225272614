import Pusher from 'pusher-js';

const pusherConfig = {
	appKey: window.$config.pusher.key,
	options: {
		cluster: 'eu',
		encrypted: true
	}
};

class PusherClient {

	constructor(config = pusherConfig) {
		this.pusher = new Pusher(config.appKey, config.options);
	}

	subscribeToChannel(channel, callback) {
		const subscription = this.pusher.subscribe(channel);
		subscription.bind('status', callback);
		subscription.bind('update', callback);
		subscription.bind('save', callback);
		return subscription;
	}

	unsubscribeFromChannel(channel) {
		this.pusher.unsubscribe(channel);
	}

}

const pusherClient = new PusherClient();

export default pusherClient;

<template>
	<b-modal
		:visible="visible"
		title="New Worker"
		ok-title="Create"
		:ok-disabled="!isValidWorker || page.isSubmitting"
		@ok="onCreateWorkerClick"
		@hidden="$emit('hidden')"
	>
		<form @submit.stop.prevent="onCreateWorkerClick">
			<b-form-group label="Name" label-for="NewWorker__name">
				<b-form-input id="NewWorker__name" v-model="newWorkerName" />
			</b-form-group>

			<b-form-group label="Team" label-for="NewWorker__team">
				<b-form-select id="NewWorker__team" v-model="newWorkerTeam" :options="teams" />
			</b-form-group>

			<b-form-group label="Type" label-for="NewWorker__type">
				<b-form-input id="NewWorker__type" disabled v-model="displayWorkerType" />
			</b-form-group>
		</form>
	</b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
	props: {
		visible: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			page: {
				isSubmitting: false
			},
			newWorkerName: null,
			newWorkerTeam: null
		};
	},
	computed: {
		...mapGetters({
			teamsData: 'team/teams'
		}),
		teams() {
			if (!this.teamsData.data) return [];
			return this.teamsData.data.map(item => ({ text: item.name, value: item._id }));
		},
		isValidWorker() {
			return !!(this.newWorkerTeam && this.workerType);
		},
		displayWorkerType: {
			get() {
				if (Array.isArray(this.teamsData.data) && this.newWorkerTeam) {
					const { namespace } = this.teamsData.data.find(t => t._id === this.newWorkerTeam);
					return `task.${namespace}.${this.workerType}`;
				}
				return '';
			},
			set() {
				// pass (necessary since the b-input tries to set this despite being disabled)
			}
		},
		workerType() {
			return this.newWorkerName.replace(/[^0-9a-zA-Z]/g, '').toLowerCase();
		}
	},
	methods: {
		...mapActions({
			createWorker: 'worker/create',
			findTeams: 'team/find'
		}),
		async onCreateWorkerClick(event) {
			event.preventDefault();
			this.page.isSubmitting = true;
			try {
				const newWorker = await this.createWorker({
					name: this.newWorkerName,
					teamId: this.newWorkerTeam
				});
				this.$toaster.success(`Created worker: ${this.newWorkerName}`);
				this.$emit('submit', newWorker);
				this.$emit('hidden');
			} catch (err) {
				this.$toaster.error(`Error creating worker: ${err}`);
			}
			this.page.isSubmitting = false;
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				this.findTeams({ query: { page: 1, pagesize: 9999 } });
			}
		}
	}
};
</script>

import moment from 'moment';
import humanizeDuration from 'humanize-duration';
import durationConfig from './durationConfig';

/**
 * Returns an english description of the duration between two dates
 * @param {Date} start start of the duration
 * @param {Date} end end of the duration
 * @returns {String} string representing the duration
 */
export default (start, end) => {
	const duration = moment
		.duration(moment(end).diff(moment(start)))
		.asMilliseconds();
	const shortEnglishHumanizer = humanizeDuration.humanizer(durationConfig);
	return shortEnglishHumanizer(duration);
};

import Backlog from './Backlog';

const routes = [
	{
		name: 'dashboard',
		path: '/dashboard',
		component: Backlog
	}
];

export default routes;

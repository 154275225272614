<template>
	<b-modal
		:visible="visible"
		title="New Script"
		ok-title="Create"
		:ok-disabled="!isScriptValid || page.isSubmitting"
		@ok="onSubmitClick"
		@hidden="$emit('hidden')">

		<loader class="App__loading" v-if="page.isLoading"/>

		<form
			v-if="!page.isLoading"
			@submit.prevent="onSubmitClick">

			<b-form-group label="Name" label-for="NewScript__name">
				<b-form-input
					id="NewScript__name"
					v-model="newScript.name"
					:disabled="page.isSubmitting"/>
			</b-form-group>

			<b-form-group label="Team" label-for="NewScript__team">
				<b-form-select
					id="NewScript__team"
					v-model="newScript.team"
					:options="teamOptions"
					:disabled="page.isSubmitting"/>
			</b-form-group>

		</form>

	</b-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	props: {
		visible: {
			type: Boolean,
			required: true
		},
		teamOptions: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			page: {
				isLoading: false,
				isSubmitting: false
			},
			newScript: {
				name: null,
				team: null
			}
		};
	},
	computed: {
		isScriptValid() {
			return (this.newScript.name
				&& this.newScript.team);
		}
	},
	methods: {
		...mapActions({
			createScript: 'script/create'
		}),
		async onSubmitClick() {
			this.page.isSubmitting = true;
			try {
				const newScript = await this.createScript({
					name: this.newScript.name,
					teamId: this.newScript.team,
					type: this.newScript.name
				});
				this.$toaster.success(`Created script: ${this.newScript.name}`);
				this.$emit('submit', newScript);
				this.$emit('hidden');
			} catch (err) {
				this.$toaster.error(`Error creating script: ${err}`);
			}
			this.page.isSubmitting = false;
		}
	},
	watch: {
		visible() {
			this.newScript = {
				name: null,
				team: null
			};
		}
	}
};
</script>

import Vue from 'vue';

export default function () {

	Vue.$ofsCrud.addResource({
		name: 'script',
		pluralName: 'scripts',
		options: {
			namespaced: true
		},
		transport: 'workforce'
	});
}

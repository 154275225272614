import Vue from 'vue';

export default function () {

	Vue.$ofsCrud.addResource({
		name: 'team',
		pluralName: 'teams',
		options: {
			namespaced: true
		},
		transport: 'workforce',
		actions: {
			fetchMembers({ dispatch }, { id }) {
				const path = `teams/${id}/members`;
				return dispatch('request', { method: 'GET', path }).then(r => r.data.members);
			},
			createMember({ dispatch }, { id, data }) {
				const path = `teams/${id}/members`;
				return dispatch('request', { method: 'POST', path, payload: data }).then(r => r.data);
			},
			deleteMember({ dispatch }, { teamId, memberId }) {
				const path = `teams/${teamId}/members/${memberId}`;
				return dispatch('request', { method: 'DELETE', path }).then(r => r.data);
			},
			inviteMember({ dispatch }, { id, data }) {
				const path = `teams/${id}/members/invite`;
				return dispatch('request', { method: 'POST', path, payload: data }).then(r => r.data);
			}
		},
		getters: {}
	});
}

<template>
	<DefaultLayout>
		<ContentHeader :title="script.name" :breadcrumbs="breadcrumbs">
			<b-button
				class="ScriptDetail__action"
				variant="danger"
				v-text="'Delete Script'"
				:disabled="page.isSubmitting"
				@click="openConfirmDeleteModal"
			/>

			<b-button
				class="ScriptDetail__action"
				variant="primary"
				v-text="'Update Script'"
				:disabled="!isValidScript || page.isSubmitting"
				@click="onUpdateScriptClick"
			/>
		</ContentHeader>

		<section class="ScriptDetail__content">
			<loader class="App__loading" v-if="page.isLoading || page.isSubmitting" />

			<template v-if="!page.isLoading && !page.isSubmitting">
				<div class="ScriptDetail__row">
					<div class="ScriptDetail__col">
						<b-form-group label="Name" label-for="ScriptDetail__name">
							<b-form-input id="ScriptDetail__name" v-model="editedScript.name" />
							<b-form-group label="Description" label-for="ScriptDetail__description">
								<b-form-textarea
									fluid
									id="ScriptDetail__description"
									v-model="editedScript.description"
									:rows="5"
									no-resize
								/>
							</b-form-group>
						</b-form-group>
					</div>
					<div class="ScriptDetail__col">
						<b-form-group label="Team" label-for="ScriptDetail__team">
							<b-form-input id="ScriptDetail__team" v-model="editedScript.teamId.name" disabled />
						</b-form-group>
					</div>
				</div>

				<div class="ScriptDetail__row">
					<codemirror class="ScriptDetail__code" v-model="editedScript.code" :options="codeEditorOptions" />
				</div>
			</template>
		</section>

		<confirm-modal
			:visible="page.isConfirmDeleteModalVisible"
			title="Delete Script"
			text="Are you sure you want to delete this script?"
			:danger="true"
			@ok="onConfirmDeleteScriptClick"
			@hidden="hideConfirmDeleteModal"
		/>
	</DefaultLayout>
</template>

<script type="text/javascript">
import { ContentHeader } from '@workflow-solutions/ofs-vue-layout';
import { mapGetters, mapActions } from 'vuex';
import codeEditorOptions from '../../lib/codeEditorOptions';
import DefaultLayout from '../../components/DefaultLayout';
import ConfirmModal from '../../components/ConfirmModal';

export default {
	components: {
		DefaultLayout,
		ContentHeader,
		ConfirmModal
	},
	data() {
		return {
			codeEditorOptions,
			page: {
				isLoading: false,
				isConfirmDeleteModalVisible: false
			},
			editedScript: {
				name: null,
				description: null,
				code: null,
				teamId: { name: null }
			}
		};
	},
	computed: {
		...mapGetters({
			script: 'script/script'
		}),
		breadcrumbs() {
			return [
				{
					text: 'All Scripts',
					to: { name: 'script.list' }
				},
				{
					text: this.script.name,
					active: true
				}
			];
		},
		isValidScript() {
			return this.editedScript.name;
		}
	},
	methods: {
		...mapActions({
			getScript: 'script/get',
			deleteScript: 'script/deleteById',
			updateScript: 'script/update'
		}),
		async fetch() {
			this.page.isLoading = true;
			try {
				await this.getScript({ id: this.$route.params.id });
				this.editedScript = { ...this.script };
			} catch (err) {
				this.$toaster.error(`Error loading script: ${err}`);
				this.$router.push({ name: 'script.list' });
			}
			this.page.isLoading = false;
		},
		async onConfirmDeleteScriptClick() {
			this.page.isSubmitting = true;
			try {
				await this.deleteScript({ id: this.$route.params.id });
				this.$toaster.success(`Deleted script: ${this.script.name}`);
				this.$router.push({ name: 'script.list' });
			} catch (err) {
				this.$toaster.error(`Error deleting script: ${err}`);
			}
			this.page.isSubmitting = false;
		},
		async onUpdateScriptClick() {
			this.page.isSubmitting = true;
			try {
				const payload = { ...this.editedScript };
				delete payload.teamId;
				await this.updateScript({ id: this.$route.params.id, data: payload });
				this.$toaster.success(`Script updated: ${this.editedScript.name}`);
				this.fetch();
			} catch (err) {
				this.$toaster.error(`Error updating script: ${err}`);
			}
			this.page.isSubmitting = false;
		},
		openConfirmDeleteModal() {
			this.page.isConfirmDeleteModalVisible = true;
		},
		hideConfirmDeleteModal() {
			this.page.isConfirmDeleteModalVisible = false;
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				this.fetch();
			}
		}
	}
};
</script>

<style lang="scss">
.ScriptDetail {
	&__action {
		margin-left: 1rem;
	}

	&__content {
		padding: 1rem;
		display: flex;
		flex-direction: column;
	}

	&__row {
		display: flex;
		flex-direction: row;
		width: 100%;
		margin-bottom: 1rem;
	}

	&__col {
		display: flex;
		flex-direction: column;
		width: 50%;

		&:first-of-type {
			margin-right: 0.5rem;
		}
		&:last-of-type {
			margin-left: 0.5rem;
		}
	}

	&__code {
		width: 100%;

		.CodeMirror {
			height: auto;
		}
	}
}
</style>

import Vue from 'vue';

export default function () {

	Vue.$ofsCrud.addResource({
		name: 'worker',
		pluralName: 'workers',
		options: {
			namespaced: true
		},
		transport: 'workforce',
		actions: {
			addAlias({ dispatch }, { id, aliasName }) {
				const path = `workers/${id}/aliases`;
				const data = { aliasName, _id: id };
				return dispatch('request', { method: 'POST', path, payload: data }).then(r => r.data);
			},
			deleteAlias({ dispatch }, { id, aliasName }) {
				const path = `workers/${id}/aliases/${aliasName}`;
				return dispatch('request', { method: 'DELETE', path }).then(r => r.data);
			},
			loadAliases({ dispatch }, { type }) {
				const path = `workers/bytype/${type}`;
				return dispatch('request', { method: 'GET', path });
			},
			deleteAliasPrivKey({ dispatch }, { id, aliasName, privKeyId }) {
				const path = `workers/${id}/aliases/${aliasName}/jwt/${privKeyId}`;
				return dispatch('request', { method: 'DELETE', path }).then(r => r.data);
			},
			generateAliasPrivKey({ dispatch }, { id, aliasName, name }) {
				const path = `workers/${id}/aliases/${aliasName}/jwt`;
				return dispatch('request', { method: 'PUT', path, payload: { name } }).then(r => r.data);
			},
		},
		getters: {}
	});
}

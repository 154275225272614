<template>
	<section class="AppSettings">
		<loader class="App__loading" v-if="page.isLoading || page.isSubmitting" />

		<template v-if="!page.isLoading && !page.isSubmitting">
			<header class="App__panelTitle">
				Access Tokens
				<b-button slot="action" variant="primary" v-text="'Add Token'" @click="onAddTokenClick" />
			</header>

			<ofs-panel class="AppSettings__panel" :isPadded="false">
				<b-table :items="app.tokens" :fields="tokenFields" responsive>
					<template v-slot:cell(secret)="data">
						<div class="AppSettings__panel__token">
							<template v-if="data.item.show">
								{{ data.item.secret }}
							</template>
							<template v-else>
								{{ obfuscate(data.item.secret) }}
							</template>
							<b-icon
								class="AppSettings__viewIcon"
								:icon="data.item.show ? 'eye-slash-fill' : 'eye-slash'"
								@click="viewTokenToggle(data.item)"
							/>
						</div>
					</template>
					<template v-slot:cell(action)="data">
						<div class="AppSettings__actions">
							<b-button
								variant="danger"
								size="sm"
								v-text="'Delete'"
								@click.prevent="onDeleteTokenClick(data.item._id)"
							/>
						</div>
					</template>
				</b-table>
			</ofs-panel>

			<header class="App__panelTitle">
				Variables
				<b-button slot="action" variant="primary" v-text="'Add Variable'" @click="openNewVariableModal" />
			</header>
			<ofs-panel class="AppSettings__panel" :isPadded="false">
				<b-table :items="app.variables" :fields="variableFields" responsive>
					<template v-slot:cell(value)="data">
						{{ obfuscate(data.item.value) }}
					</template>

					<template v-slot:cell(action)="data">
						<div class="AppSettings__actions">
							<b-button
								variant="danger"
								size="sm"
								v-text="'Delete'"
								@click.prevent="onDeleteVariableClick(data.item._id)"
							/>
						</div>
					</template>
				</b-table>
			</ofs-panel>
		</template>

		<new-variable-modal :visible="page.isNewVariableModalVisible" @submit="fetch" @hidden="hideNewVariableModal" />

		<confirm-modal
			:visible="page.isConfirmDeleteTokenModalVisible"
			:danger="true"
			title="Delete Token"
			text="Are you sure you want to delete this token?"
			@ok="onConfirmDeleteTokenClick"
			@hidden="hideConfirmDeleteTokenModal"
		/>
		<confirm-modal
			:visible="page.isConfirmDeleteVariableModalVisible"
			:danger="true"
			title="Delete Variable"
			text="Are you sure you want to delete this variable?"
			@ok="onConfirmDeleteVariableClick"
			@hidden="hideConfirmDeleteVariableModal"
		/>
	</section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { OfsPanel } from '@workflow-solutions/ofs-vue-layout';
import ConfirmModal from '../../../components/ConfirmModal';
import NewVariableModal from './NewVariableModal.vue';

export default {
	components: {
		OfsPanel,
		ConfirmModal,
		NewVariableModal
	},
	data() {
		return {
			page: {
				isLoading: false,
				isSubmitting: false,
				isNewVariableModalVisible: false,
				isConfirmDeleteTokenModalVisible: false,
				isConfirmDeleteVariableModalVisible: false,
				tokenLastClickedId: null,
				variableLastClickedId: null
			},
			tokenFields: ['key', 'secret', { key: 'action', label: ' ' }],
			variableFields: ['name', 'value', { key: 'action', label: ' ' }],
			variableName: null,
			variableValue: null
		};
	},
	computed: {
		...mapGetters({
			app: 'app/app'
		})
	},
	methods: {
		...mapActions({
			getApp: 'app/get',
			addAccessToken: 'app/addAccessToken',
			deleteAccessToken: 'app/deleteAccessToken',
			deleteVariable: 'app/deleteVariable'
		}),
		async fetch() {
			this.page.isLoading = true;
			try {
				await this.getApp({ id: this.app._id });
			} catch (err) {
				this.$toaster.error(`Error loading app: ${err}`);
			}
			this.page.isLoading = false;
		},
		async onAddTokenClick() {
			this.page.submitting = true;
			try {
				await this.addAccessToken({ appId: this.app._id });
				this.$toaster.success('Token added');
			} catch (err) {
				this.$toaster.error(`Error adding token: ${err}`);
			}
			this.page.submitting = false;
			this.fetch();
		},
		onDeleteTokenClick(id) {
			if (this.app.tokens.length < 2) {
				this.$toaster.error('App must have at least one token');
				return;
			}
			this.page.tokenLastClickedId = id;
			this.openConfirmDeleteTokenModal();
		},
		async onConfirmDeleteTokenClick() {
			this.page.submitting = true;
			try {
				await this.deleteAccessToken({ appId: this.app._id, tokenId: this.page.tokenLastClickedId });
				this.$toaster.success('Token removed');
				this.fetch();
			} catch (err) {
				this.$toaster.error(`Error removing token: ${err}`);
			}
			this.page.isSubmitting = false;
		},
		async onDeleteVariableClick(id) {
			this.page.variableLastClickedId = id;
			this.openConfirmDeleteVariableModal();
		},
		async onConfirmDeleteVariableClick() {
			this.page.submitting = true;
			try {
				await this.deleteVariable({
					appId: this.app._id,
					variableId: this.page.variableLastClickedId
				});
				this.$toaster.success('Variable removed');
				this.fetch();
			} catch (err) {
				this.$toaster.error(`Error removing variable: ${err}`);
			}
			this.page.isSubmitting = false;
		},
		openNewVariableModal() {
			this.page.isNewVariableModalVisible = true;
		},
		hideNewVariableModal() {
			this.page.isNewVariableModalVisible = false;
		},
		openConfirmDeleteTokenModal() {
			this.page.isConfirmDeleteTokenModalVisible = true;
		},
		hideConfirmDeleteTokenModal() {
			this.page.isConfirmDeleteTokenModalVisible = false;
		},
		openConfirmDeleteVariableModal() {
			this.page.isConfirmDeleteVariableModalVisible = true;
		},
		hideConfirmDeleteVariableModal() {
			this.page.isConfirmDeleteVariableModalVisible = false;
		},
		obfuscate(value) {
			if (value.length <= 4) return '*'.repeat(value.length);
			const preserve = Math.min(value.length - 4, 4);
			return `${'*'.repeat(value.length - preserve)}${value.slice(-preserve)}`;
		},
		viewTokenToggle(tokenObject) {
			this.$set(tokenObject, 'show', !tokenObject.show);
		}
	}
};
</script>

<style lang="scss">
.AppSettings {
	padding: 1rem;

	&__panel {
		margin-bottom: 1rem !important;

		&__token {
			display: flex;
			justify-content: space-between;
		}
	}

	&__actions {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}

	&__viewIcon {
		cursor: pointer;
	}
}
</style>

export default {
	language: 'shortEn',
	decimal: '.',
	languages: {
		shortEn: {
			y() { return 'years'; },
			mo() { return 'months'; },
			w() { return 'week'; },
			d() { return 'days'; },
			h() { return 'hours'; },
			m() { return 'minutes'; },
			s() { return 'seconds'; },
			ms() { return 'milliseconds'; }
		}
	}
};

import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

const createStore = () => {
	Vue.use(Vuex);
	const store = new Vuex.Store({
		plugins: [createPersistedState({ paths: ['auth'] })],
		state: {
			now: new Date()
		},
		actions: {
			start({ commit }) {
				setInterval(() => {
					commit('updateTime');
				}, 500);
			}
		},
		mutations: {
			updateTime(state) {
				state.now = new Date();
			}
		}
	});
	store.dispatch('start');
	return store;
};

export default createStore;

import Vue from 'vue';
import _get from 'lodash/get';

import * as localStorage from 'store2';

const setupMiddleware = store => {
	Vue.$ofsCrud.addMiddleware('post', async (args, next) => {
		const { status } = args.response || {};

		if ([401, 403].includes(status)) {
			await store.dispatch('auth/logout');
			await store.dispatch('account/changeAccount', null);
		}
		next();
	});

	Vue.$ofsCrud.addMiddleware('pre', (args, next) => {
		const token = _get(store, 'state.auth.token');
		const accountId = _get(store, 'state.account.currentAccount._id');

		if (args.replaceHeaders) next();

		const isAuthRequest = args.transport && args.transport.name === 'auth';
		// const isConnectRequest = args.transport && args.transport.name === 'connect';

		args.headers = { Authorization: `Bearer ${token}` };

		if (accountId && !isAuthRequest) {
			args.headers['x-oneflow-accountid'] = accountId;
			if (!isAuthRequest) {
				args.headers['x-consumer-custom-id'] = 'brand-centre-authId';
			}
		}

		if (!isAuthRequest && process.env.VUE_APP_LOCAL_MODE) {
			const testAuthId = localStorage.get('test-authId');
			const testAuthUsername = localStorage.get('test-authUsername');

			if (testAuthId) {
				args.headers['x-consumer-custom-id'] = testAuthId;
				args.headers['x-consumer-username'] = testAuthUsername;
			}
		}

		next();
	});
};

export default setupMiddleware;

<template>
	<default-layout>

		<content-header title="Workers">
			<b-form-checkbox
				variant="primary"
				class="d-inline-block ml-2"
				switch
				v-model="showDeleted">
				Show Deleted
			</b-form-checkbox>
			<b-button
				variant="primary"
				class="ml-2"
				v-text="'New Worker'"
				@click="openNewWorkerModal"/>

		</content-header>

		<section class="WorkerList">

			<loader class="App__loading" v-if="page.isLoading"/>

			<ListTable
				v-if="!page.isLoading"
				class="WorkerList__listTable"
				:items="workers"
				:fields="workerFields"
				:totalItems="pagination.total"
				:perPage="pagination.perPage"
				:currentPage="pagination.currentPage"
				:fetchData="fetch"
				@table-change="handleTableChange"
				:config="listConfig"
				hover
				striped>

				<template v-slot:cell(name)="data">
					<span class="link" @click.stop>
						<router-link
							:to="{
								name: 'worker.detail',
								params: { id: data.item._id }
							}"
							v-text="data.item.name"/>
					</span>
				</template>

			</ListTable>
		</section>

		<new-worker-modal
			:visible="page.isNewWorkerModalVisible"
			@submit="goToWorker"
			@hidden="hideNewWorkerModal"/>

	</default-layout>
</template>

<script type="text/javascript">
import { ListTable, ContentHeader } from '@workflow-solutions/ofs-vue-layout';
import { mapGetters, mapActions } from 'vuex';
import listConfig from '../../lib/listConfig';
import DefaultLayout from '../../components/DefaultLayout';
import NewWorkerModal from './components/NewWorkerModal';

export default {
	components: {
		DefaultLayout,
		ListTable,
		ContentHeader,
		NewWorkerModal
	},
	data() {
		return {
			listConfig,
			page: {
				isLoading: false,
				isNewWorkerModalVisible: false
			},
			pagination: {
				currentPage: 1,
				perPage: 10,
				total: 0
			},
			showDeleted: false,
			workerFields: [
				'name',
				{ key: 'teamId.name', label: 'Team' },
				{ key: 'type', label: 'Task' },
				'status'
			]
		};
	},
	computed: {
		...mapGetters({
			workersData: 'worker/workers'
		}),
		workers() {
			if (!this.workersData.data) return [];
			return this.workersData.data;
		}
	},
	methods: {
		...mapActions({
			findWorkers: 'worker/find'
		}),
		async fetch() {
			this.page.isLoading = true;
			try {
				await this.findWorkers({
					query: {
						page: parseInt(this.$route.query.page, 10),
						pagesize: parseInt(this.$route.query.perPage, 10),
						showDeleted: this.$route.query.showDeleted,
						populateTeamId: true
					}
				});
				this.pagination.total = this.workersData.total;
			} catch (err) {
				this.$toaster.error(`Error loading workers: ${err}`);
			}
			this.page.isLoading = false;
		},

		handleTableChange({ currentPage, perPage }) {
			this.pagination.currentPage = currentPage;
			this.pagination.perPage = perPage;
			this.updateQuery();
		},
		openNewWorkerModal() {
			this.page.isNewWorkerModalVisible = true;
		},
		hideNewWorkerModal() {
			this.page.isNewWorkerModalVisible = false;
			this.newWorkerName = '';
		},
		goToWorker(worker) {
			this.$router.push({ name: 'worker.detail', params: { id: worker._id } });
		},
		updateQuery() {
			this.$router.replace({
				name: 'worker.list',
				query: {
					...this.$route.query,
					page: this.pagination.currentPage,
					perPage: this.pagination.perPage,
					showDeleted: this.showDeleted
				}
			}).catch(e => {
				if (e.name !== 'NavigationDuplicated') throw e;
			});
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				if (this.$route.query.page) this.pagination.currentPage = parseInt(this.$route.query.page, 10);
				if (this.$route.query.perPage) this.pagination.perPage = parseInt(this.$route.query.perPage, 10);
				this.updateQuery();
				this.fetch();
			}
		},
		showDeleted: 'updateQuery'
	}
};
</script>

<style lang="scss">

.WorkerList {
	padding: 1rem;

	&__listTable {
		margin-top: -28px;
	}
}
</style>

<template>
	<DefaultLayout>

		<ContentHeader title="Clusters">
			<b-button
				variant="primary"
				v-text="'Refresh Clusters'"
				@click="fetch"/>
		</ContentHeader>

		<section class="ClusterList">

			<loader class="App__loading" v-if="page.isLoading"/>

			<list-table
				v-if="!page.isLoading"
				class="ClusterList__listTable"
				:items="clusters"
				:fields="clusterFields"
				:config="listConfig"
				:totalItems="pagination.total"
				:perPage="pagination.perPage"
				:currentPage="pagination.currentPage"
				:fetchData="fetch"
				@table-change="handleTableChange">

				<template v-slot:cell(name)="data">
					<span class="link" @click.stop>
						<router-link
							:to="{
								name: 'cluster.detail',
								params: { id: data.item._id }
							}"
							v-text="data.item.name"/>
					</span>
				</template>

				<template v-slot:cell(created)="data">
					<time-span :time="data.item.created"/>
				</template>

			</list-table>
		</section>

	</DefaultLayout>
</template>

<script type="text/javascript">
import { ListTable, ContentHeader } from '@workflow-solutions/ofs-vue-layout';
import { mapGetters, mapActions } from 'vuex';
import listConfig from '../../lib/listConfig';
import DefaultLayout from '../../components/DefaultLayout';
import TimeSpan from '../../components/TimeSpan';

export default {
	components: {
		DefaultLayout,
		ListTable,
		ContentHeader,
		TimeSpan
	},
	data() {
		return {
			listConfig,
			page: {
				isLoading: false,
				isLoadingTeams: false,
				isNewScriptModalVisible: false
			},
			pagination: {
				currentPage: 1,
				perPage: 10,
				total: 0
			},
			clusterFields: [
				'name',
				'created'
			]
		};
	},
	computed: {
		...mapGetters({
			clustersData: 'cluster/clusters'
		}),
		clusters() {
			if (!this.clustersData.data) return [];
			return this.clustersData.data;
		}
	},
	methods: {
		...mapActions({
			findClusters: 'cluster/find'
		}),
		async fetch() {
			this.page.isLoading = true;
			try {
				await this.findClusters({
					query: {
						page: parseInt(this.$route.query.page, 10),
						pagesize: parseInt(this.$route.query.perPage, 10)
					}
				});
				this.pagination.total = this.clustersData.total;
			} catch (err) {
				this.$toaster.error(`Error loading clusters: ${err}`);
			}
			this.page.isLoading = false;
		},
		goToCluster(cluster) {
			this.$router.push({ name: 'cluster.detail', params: { id: cluster._id } });
		},
		handleTableChange({ currentPage, perPage }) {
			this.pagination.currentPage = currentPage;
			this.pagination.perPage = perPage;
			this.updateQuery();
		},
		updateQuery() {
			this.$router.replace({
				name: 'cluster.list',
				query: {
					...this.$route.query,
					page: this.pagination.currentPage,
					perPage: this.pagination.perPage
				}
			}).catch(e => {
				if (e.name !== 'NavigationDuplicated') throw e;
			});
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				if (this.$route.query.page) this.pagination.currentPage = parseInt(this.$route.query.page, 10);
				if (this.$route.query.perPage) this.pagination.perPage = parseInt(this.$route.query.perPage, 10);
				this.updateQuery();
				this.fetch();
			}
		}
	}
};
</script>

<style lang="scss">

.ClusterList {
	padding: 1rem;

	&__listTable {
		margin-top: -28px;
	}
}
</style>

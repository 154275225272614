
import Login from './Login';

const routes = [
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/logout',
		name: 'logout',
		component: Login,
		props: { isLogout: true }
	}
];

export default routes;

<template>
	<b-modal
		:visible="visible"
		:ok-variant="okVariant"
		:header-bg-variant="headerBgVariant"
		:header-text-variant="headerTextVariant"
		@ok="$emit('ok')"
		@hidden="$emit('hidden')">
		<template v-slot:modal-title>
			<h5 :class="`text-${headerTextVariant}`" v-text="title"/>
		</template>
		<span v-text="text"/>
	</b-modal>
</template>

<script>
export default {
	props: {
		visible: {
			type: Boolean,
			required: true
		},
		title: {
			type: String,
			required: true
		},
		text: {
			type: String,
			required: true
		},
		danger: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		headerTextVariant() {
			if (this.danger) return 'light';
			return 'dark';
		},
		headerCloseVariant() {
			if (this.danger) return 'light';
			return '';
		},
		headerBgVariant() {
			if (this.danger) return 'danger';
			return '';
		},
		okVariant() {
			if (this.danger) return 'danger';
			return 'info';
		}
	}
};
</script>

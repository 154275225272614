import List from './List';
import Detail from './Detail';

const routes = [
	{
		name: 'plan.list',
		path: '/plans',
		component: List
	},
	{
		name: 'plan.detail',
		path: '/plans/:id',
		component: Detail
	}
];

export default routes;

<template>
	<section class="AppTasks">
		<section class="AppTasks__filters">
			<div class="AppTasks__filter">
				<label class="AppTasks__label" v-text="'Task Type:'" />
				<b-form-select v-model="filters.type" :options="filters.typeOptions" @input="updateQuery" />
			</div>

			<div class="AppTasks__filter">
				<label class="AppTasks__label" v-text="'Status:'" />
				<b-form-select v-model="filters.status" :options="filters.statusOptions" @input="updateQuery" />
			</div>

			<div class="AppTasks__filter">
				<label class="AppTasks__label" v-text="'Created:'" />
				<date-interval-picker-button
					:from="filters.created.$gte"
					:to="filters.created.$lte"
					@click="onDatePickerButtonClick"
				/>
			</div>
		</section>

		<date-interval-picker-collapse
			:open="page.isDatePickerOpen"
			:from="filters.created.$gte"
			:to="filters.created.$lte"
			@input="handleCreatedInput"
			@hide="onDatePickerCollapseClose"
		/>

		<loader class="App__loading" v-if="page.isLoading" />

		<list-table
			v-if="!page.isLoading"
			:items="tasks"
			:fields="taskFields"
			:config="listConfig"
			:totalItems="pagination.total"
			:perPage="pagination.perPage"
			:currentPage="pagination.currentPage"
			:fetchData="fetchTasks"
			@table-change="handleTableChange"
			hover
		>
			<template v-slot:cell(name)="data">
				<router-link
					:to="{
						name: 'app.detail.workqueue',
						params: {
							id: data.item.appId,
							workqueue: data.item.workQueueId
						}
					}"
					v-text="data.item.name"
				/>
			</template>

			<template v-slot:cell(created)="data">
				<time-span :time="data.item.created" />
			</template>

			<template v-slot:cell(duration)="data">
				{{ getFormattedDuration(data.item.started, data.item.ended) }}
			</template>

			<template v-slot:cell(status)="data">
				<ofs-badge :text="data.item.status" :status="data.item.status" />
			</template>
		</list-table>
	</section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ListTable, OfsBadge } from '@workflow-solutions/ofs-vue-layout';
import moment from 'moment';
import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import TimeSpan from '../../../components/TimeSpan.vue';
import listConfig from '../../../lib/listConfig';
import getFormattedDuration from '../../../lib/getFormattedDuration';
import DateIntervalPickerButton from '../../../components/form/DateIntervalPickerButton.vue';
import DateIntervalPickerCollapse from '../../../components/form/DateIntervalPickerCollapse.vue';

export default {
	components: {
		ListTable,
		OfsBadge,
		TimeSpan,
		DateIntervalPickerButton,
		DateIntervalPickerCollapse
	},
	data() {
		return {
			listConfig,
			page: {
				isLoading: false,
				isDatePickerOpen: false
			},
			pagination: {
				currentPage: 1,
				total: 0,
				perPage: 10
			},
			taskFields: ['name', 'type', 'created', 'duration', 'status'],
			filters: {
				type: undefined,
				typeOptions: [{ text: 'All Types', value: undefined }],
				status: null,
				statusOptions: [
					{ value: null, text: 'All' },
					{ value: 'notstarted', text: 'Not Started' },
					{ value: 'started', text: 'Started' },
					{ value: 'queued', text: 'Queued' },
					{ value: 'ended', text: 'Ended' },
					{ value: 'failed', text: 'Failed' },
					{ value: 'retry', text: 'Retry' }
				],
				created: {
					$gte: null,
					$lte: null
				}
			}
		};
	},
	computed: {
		...mapGetters({
			tasksData: 'task/tasks',
			workersData: 'worker/workers'
		}),
		tasks() {
			return this.tasksData.data;
		},
		workers() {
			return this.workersData.data;
		},
		criteria() {
			const criteria = {
				page: this.pagination.currentPage,
				pagesize: this.pagination.perPage,
				created: this.filters.created,
				type: this.filters.type,
				status: this.filters.status,
				appId: this.$route.params.id
			};
			return criteria;
		},
		hasDateSelection() {
			return this.filters.created.$gte || this.filters.created.$lte;
		}
	},
	methods: {
		...mapActions({
			findTasks: 'task/find',
			findWorkers: 'worker/find'
		}),
		moment,
		getFormattedDuration,
		async fetchTasks() {
			this.page.isLoading = true;
			try {
				let criteria = { ...this.criteria };

				if (Array.isArray(criteria.status)) {
					criteria.status = criteria.status[0];
				}

				if (Array.isArray(criteria.type)) {
					criteria.status = criteria.type[0];
				}

				if (criteria.created) {
					// send standard querystring object notation => created[$gte]=...
					const createdProps = reduce(
						this.criteria.created,
						(acc, d, key) => {
							acc[`created[${key}]`] = moment(d).isValid() ? moment(d).toISOString() : null;
							return acc;
						},
						{}
					);
					criteria = omit({ ...criteria, ...createdProps }, ['created']);
				}

				await this.findTasks({
					query: criteria
				});
				this.pagination.total = this.tasksData.total;
			} catch (err) {
				this.$toaster.error(`Error loading tasks: ${err}`);
			}
			this.page.isLoading = false;
		},
		async fetchWorkers() {
			try {
				await this.findWorkers({
					query: { pagesize: 9999 }
				});
				const taskTypes = this.workers.map(w => w.type);
				['task.workforce.noop', 'task.workforce.delay', 'task.workforce.map', 'task.workforce.plan']
					.forEach(t => taskTypes.push(t));
				const sortedTaskTypes = taskTypes.sort();
				this.filters.typeOptions.push(...sortedTaskTypes);
			} catch (err) {
				this.$toaster.error(`Error loading workers: ${err}`);
			}
		},
		handleTableChange({ currentPage, perPage }) {
			this.pagination.currentPage = currentPage;
			this.pagination.perPage = perPage;
			this.updateQuery();
		},
		handleCreatedInput({ from, to }) {
			this.filters.created = {
				$gte: moment(from).isValid() ? moment(from).toISOString() : null,
				$lte: moment(to).isValid() ? moment(to).toISOString() : null
			};
			this.updateQuery();
		},
		updateQuery() {
			const query = omit({ ...this.$route.query, ...this.criteria }, ['appId']);
			this.$router.replace({ query }).catch(e => {
				if (e.name !== 'NavigationDuplicated') throw e;
			});
		},
		onDatePickerButtonClick() {
			this.page.isDatePickerOpen = !this.page.isDatePickerOpen;
		},
		onDatePickerCollapseClose() {
			this.page.isDatePickerOpen = false;
		}
	},
	created() {
		this.fetchWorkers();
	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				if (this.$route.query.page) this.pagination.currentPage = parseInt(this.$route.query.page, 10);
				if (this.$route.query.perPage) this.pagination.perPage = parseInt(this.$route.query.perPage, 10);
				if (this.$route.query.status) {
					if (Array.isArray(this.$route.query.status)) {
						this.filters.status = this.$route.query.status[0];
					} else {
						this.filters.status = this.$route.query.status;
					}
				}
				if (this.$route.query.type) {
					if (Array.isArray(this.$route.query.type)) {
						this.filters.type = this.$route.query.type[0];
					} else {
						this.filters.type = this.$route.query.type;
					}
				}
				if (this.$route.query.created) {
					const created = JSON.parse(JSON.stringify(this.$route.query.created));
					this.filters.created = {};
					['$lte', '$gte'].forEach(queryParam => {
						if (Array.isArray(created[queryParam])) [created[queryParam]] = created[queryParam];
						this.filters.created[queryParam] = moment(created[queryParam]).isValid()
							? moment(created[queryParam]).toISOString()
							: null
					});
				}
				this.updateQuery();
				this.fetchTasks();
			}
		}
	}
};
</script>

<style lang="scss">
.AppTasks {
	padding: 1rem;

	&__filters {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}

	&__filter {
		margin-left: 1rem;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&__label {
		min-width: fit-content;
		margin: 0;
		margin-right: 0.5rem;
	}
}
</style>

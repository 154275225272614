import each from 'lodash/each';
import find from 'lodash/find';

const $config = window.$config || {};

// Account
export const getAccount = async ({ dispatch }) => {
	const path = `${window.$config.apiBase}/api/account`;
	const results = await dispatch('api/request', { method: 'GET', path }, { root: true });
	return results;
};

export const setCurrentAccount = ({ commit }, account) => {
	commit('SET_ACCOUNT', account);
};

export const getVars = async ({ dispatch, commit }) => {
	const path = `${window.$config.apiBase}/api/vars`;
	const { data: vars } = await dispatch('api/request', {
		method: 'GET',
		path,
		transport: 'connect'
	}, { root: true });

	vars.userAccountLookup = {};
	each(vars.userAccountHash, account => {
		vars.userAccountLookup[account._id] = account.name;
	});
	commit('SET_VARS', vars);

	return vars;
};

export const getUserAccountRoles = async ({ dispatch, commit }, id) => {
	const path = `${window.$config.apiBase}/api/user/${id}`;
	const results = await dispatch('api/request', { method: 'GET', path }, { root: true });
	commit('SET_VARS', results.data);
	commit('SET_ACCOUNTS', results.data.userAccountHash);
	return results;
};


export const inviteUser = async ({ dispatch }, email) => {
	await dispatch('inviteToCentralisedAccount', email);
	await dispatch('inviteToConnect', email);
};

export const inviteToCentralisedAccount = ({ dispatch, state }, email) => {
	const accountId = state.currentAccount._id;
	const redirectUrl = `${window.location.protocol}//${window.location.host}`;
	const invitation = { email, redirectUrl };
	const payload = { accountId, invitation };
	return dispatch('request', { method: 'POST', path: 'invitation/toAccount', payload });
};

export const inviteToConnect = ({ dispatch, state }, email) => {
	const { name: accountName } = state.currentAccount;
	const payload = {
		name: email,
		email,
		role: 'owner',
		domain: `account:${accountName}:owner`,
		from: 'invitations@hpsiteflow.com',
		redirectUrl: window.location.origin,
		accountName
	};

	return dispatch('api/request', {
		method: 'POST',
		path: 'user/invite',
		transport: 'connect',
		payload
	}, { root: true });
};

export const removeMembership = ({ dispatch, state }, userId) => {
	dispatch('request', {
		method: 'DELETE',
		path: `accounts/${state.currentAccount._id}/memberships/${userId}`
	});
};


export const updateMembership = ({ dispatch, state }, { userId, payload }) => {
	dispatch('request', {
		method: 'PUT',
		path: `accounts/${state.currentAccount._id}/memberships/${userId}`,
		payload
	});
};

export const changeAccount = async ({ dispatch }, account) => {
	dispatch('setCurrentAccount', account);
};

export const saveAccountSettings = ({ dispatch, state }, accountSettings) => {
	const { _id: accountId } = state.currentAccount || {};

	return dispatch('request', {
		method: 'PUT',
		path: 'accountSettings/save',
		payload: {
			accountId,
			...accountSettings
		},
		transport: 'connect'
	});
};

export const getMembers = async ({ state, commit, dispatch }, params) => {
	const selectedAccountId = state.currentAccount._id;
	const { data } = await dispatch('request', {
		method: 'GET',
		path: `accounts/${selectedAccountId}/memberships`,
		query: params
	});
	commit('SET_MEMBERS', data.docs);
	commit('SET_MEMBERSHIPS_COUNT', data.total);
	return data;
};

export const getMember = async ({ state, commit, dispatch }, userId) => {
	const { data } = await dispatch('request', {
		method: 'GET',
		path: `accounts/${state.currentAccount._id}/memberships/${userId}`
	});

	commit('SET_MEMBER', data);
};

const findOneflowAccount = accounts => {
	const enviromentSuffix = $config.environment === 'stage' ? `-${$config.environment}` : '';
	const accountName = `oneflow${enviromentSuffix}`;

	return find(accounts, { name: accountName });
};

export const fetchBaseAccountData = async ({ dispatch, getters, rootGetters }) => {
	const { currentAccount } = getters;
	const isAuthenticated = rootGetters['auth/isAuthenticated'];

	if (isAuthenticated) {
		const user = await dispatch('auth/getUser', null, { root: true });

		await dispatch('getUserMemberships', user._id);

		if (!currentAccount) {
			const account = findOneflowAccount(getters.accounts) || getters.accounts[0];
			await dispatch('setCurrentAccount', account);
		}
	}

	return null;
};

<template>
	<default-layout class="AppDetail" :hasSideNav="false">
		<content-header :title="app.name" :breadcrumbs="breadcrumbs">
			<b-button variant="secondary" v-text="'Edit App'" @click="showEditModal" />
		</content-header>

		<loader class="App__loading" v-if="isLoading" />

		<section v-if="!isLoading" class="AppDetail__content">
			<sidebar :items="sidebarItems" />

			<router-view class="AppDetail__routerView" />
		</section>

		<edit-app-modal :visible="isEditModalVisible" :app="app" @hidden="hideEditModal" @submitted="fetch" />
	</default-layout>
</template>

<script>
import { ContentHeader } from '@workflow-solutions/ofs-vue-layout';
import { mapGetters, mapActions } from 'vuex';
import DefaultLayout from '../../components/DefaultLayout';
import Sidebar from '../../components/Sidebar';
import EditAppModal from './components/EditAppModal';

export default {
	components: {
		DefaultLayout,
		ContentHeader,
		Sidebar,
		EditAppModal
	},
	data() {
		return {
			isLoading: true,
			isEditModalVisible: false,
			sidebarItems: [
				{
					title: 'Workqueues',
					description: 'Executed Work Plans',
					path: {
						name: 'app.detail.workqueues',
						params: { id: this.$route.params.id }
					}
				},
				{
					title: 'Tasks',
					description: 'All Tasks',
					path: {
						name: 'app.detail.tasks',
						params: { id: this.$route.params.id }
					}
				},
				{
					title: 'Collaborators',
					description: 'App Collaborators',
					path: {
						name: 'app.detail.collaborators',
						params: { id: this.$route.params.id }
					}
				},
				{
					title: 'Tasks Summary',
					description: 'Recent Workqueues',
					path: {
						name: 'app.detail.tasksSummary',
						params: { id: this.$route.params.id }
					}
				},
				{
					title: 'Settings',
					description: 'Manage App Settings',
					path: {
						name: 'app.detail.settings',
						params: { id: this.$route.params.id }
					}
				}
			]
		};
	},
	computed: {
		...mapGetters({
			app: 'app/app'
		}),
		breadcrumbs() {
			const breadcrumbs = [{ text: 'Apps', to: { name: 'app.list' } }, { text: this.app.name, active: true }];
			if (this.$route.name === 'app.detail.workqueues.create') {
				breadcrumbs[1] = {
					text: this.app.name,
					to: { name: 'app.detail.workqueues' }
				};
				breadcrumbs.push({ text: 'Run Workqueue', active: true });
			}
			if (this.$route.name === 'app.detail.workqueue') {
				breadcrumbs[1] = {
					text: this.app.name,
					to: { name: 'app.detail.workqueues' }
				};
				breadcrumbs.push({ text: this.$route.params.workqueue, active: true });
			}
			return breadcrumbs;
		}
	},
	methods: {
		...mapActions({
			getApp: 'app/get'
		}),
		async fetch() {
			this.isLoading = true;
			try {
				await this.getApp({ id: this.$route.params.id });
			} catch (err) {
				this.$toaster.error(`Error loading app details: ${err}`);
				this.$router.push({ name: 'app.list' });
			}
			this.isLoading = false;
		},
		showEditModal() {
			this.isEditModalVisible = true;
		},
		hideEditModal() {
			this.isEditModalVisible = false;
		}
	},
	watch: {
		'$route.params.id': {
			immediate: true,
			handler: 'fetch'
		}
	}
};
</script>

<style lang="scss">
.AppDetail {
	display: flex;
	flex-direction: column;

	&__breadcrumbs {
		margin: 0.5rem 1rem;
	}

	&__content {
		display: flex;
		flex-direction: column;
		border-top: 1px solid rgba(0, 0, 0, 0.05);

		@media all and (min-width: 600px) {
			flex-direction: row;
		}
	}

	&__routerView {
		width: 100%;
		max-width: 100%;
		overflow: hidden;
	}
}
</style>

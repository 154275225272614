<template>
	<b-modal
		:visible="visible"
		title="New Variable"
		ok-title="Add"
		:ok-disabled="!isSubmitDisabled"
		@ok="onSumbit"
		@hidden="$emit('hidden')">

		<form @submit.stop.prevent="onSumbit">
			<b-form-group
				label="Name"
				label-for="NewCollaborator__name">
				<b-form-input
					id="NewCollaborator__name"
					:disabled="page.isSubmitting"
					v-model.trim="newVariable.name" />
			</b-form-group>
			<b-form-group
				label="Value"
				label-for="NewCollaborator__value">
				<b-form-input
					id="NewCollaborator__value"
					:disabled="page.isSubmitting"
					v-model.trim="newVariable.value" />
			</b-form-group>
		</form>

	</b-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	props: {
		visible: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			page: {
				isSubmitting: false
			},
			newVariable: {
				name: null,
				value: null
			}
		};
	},
	computed: {
		isValidVariable() {
			return this.newVariable.name && this.newVariable.value;
		},
		isSubmitDisabled() {
			return this.isValidVariable && !this.page.isSubmitting;
		}
	},
	methods: {
		...mapActions({
			addVariable: 'app/addVariable'
		}),
		async onSumbit(event) {
			event.preventDefault();
			this.page.isSubmitting = true;
			try {
				await this.addVariable({
					id: this.$route.params.id,
					data: this.newVariable
				});
				this.$toaster.success(`Added variable: ${this.variableName}`);
				this.$emit('submit');
				this.$emit('hidden');
			} catch (err) {
				this.$toaster.error(`Error adding variable: ${err}`);
			}
			this.page.isSubmitting = false;
		}
	},
	watch: {
		visible() {
			this.newVariable.name = null;
			this.newVariable.value = null;
		}
	}
};
</script>

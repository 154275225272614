<template>
	<nav class="Sidebar">
		<ul class="Sidebar__menu">

			<li
				class="Sidebar__item"
				v-for="item in items"
				:key="item.title">

				<router-link :to="item.path" class="Sidebar__link" active-class="Sidebar__link--active">
					<div class="Sidebar__text">
						<h3 class="Sidebar__item__heading" v-text="item.title"/>
						<p class="Sidebar__item__subHeading" v-text="item.description"/>
					</div>
				</router-link>

			</li>

		</ul>
	</nav>
</template>

<script>
export default {
	props: {
		items: {
			type: Array
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/src/styles/core';


.Sidebar {
	background: #edeff3;
	min-height: calc(100vh - 175px);
	min-width: 200px;
	display: none;
	overflow: auto;

	@media all and (min-width: 600px) {
		display: flex;
	}


	&__menu {
		list-style: none;
		margin: 0;
		padding: 0;
		flex: 1;
		width: 100%;
		max-width: 100%;
	}

	&__item {
		margin: 0;
		padding: 0;
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);

		&__heading {
			margin: 0;
			font-size: 1rem
		}

		&__subHeading {
			margin: 0.2rem 0 0;
			font-size: 0.7rem;
			font-style: italic;
			opacity: 0.8;
		}
	}

	&__link {
		position: relative;
		padding: 1rem 2rem 1rem 1rem;
		color: inherit;
		display: flex;
		flex-direction: row;
		align-items: center;

		&:hover {
			color: inherit;
			text-decoration: none;
			background: rgba(255, 255, 255, 0.2);
		}

		&:before,
		&:after {
			content: ' ';
			display: block;
			position: absolute;
			right: 1rem;
			top: 50%;
			margin-top: -5px;
			width: 10px;
			height: 10px;
			border: 1px solid;
			border-width: 3px 3px 0 0;
			transform: rotate(45deg);
			opacity: 0.3;
		}

		&--active {
			background: rgba(255, 255, 255, 0.4);

			&:before,
			&:after {
				opacity: 1;
			}

			&:hover {
				background: rgba(255, 255, 255, 0.4);
			}
		}
	}

	&__text {
		display: flex;
		flex-direction: column;
		flex: 1;
		overflow: hidden;
	}

}
</style>

import Vue from 'vue';

export default function () {

	Vue.$ofsCrud.addResource({
		name: 'plan',
		pluralName: 'plans',
		options: {
			namespaced: true
		},
		transport: 'workforce',
		actions: {
			run({ dispatch }, planId, appId, initialState) {
				const path = `plans/${planId}/run?appId=${appId}`;
				const { data: result } = dispatch('request', { method: 'POST', path, payload: initialState });
				return result;
			}
		}
	});
}

<template>
	<section class="AppWorkqueue">
		<loader class="App__loader" v-if="page.isLoading" />

		<template v-if="!page.isLoading">
			<div class="AppWorkqueue__header">
				<h2 class="AppWorkqueue__header__title" v-text="`Workqueue: ${this.workqueue.name}`" />

				<div>
					<b-button
						v-if="isCancelButtonVisible"
						class="AppWorkqueue__action"
						variant="danger"
						v-text="'Cancel'"
						@click="cancelWorkqueue"
					/>

					<router-link :to="runWorkqueuePath">
						<b-button class="AppWorkqueue__action" variant="primary" :disabled="page.isLoading">
							<fa-icon icon="play" /> Modify and rerun
						</b-button>
					</router-link>

					<b-button
						class="AppWorkqueue__action"
						variant="primary"
						:disabled="page.isLoading"
						@click="openViewSourceModal"
					>
						<fa-icon icon="code" /> View Source
					</b-button>
					<b-button
						class="AppWorkqueue__action"
						variant="primary"
						v-text="'Refresh Logs'"
						:disabled="page.isLoading"
						@click="refreshLogs"
					/>
				</div>
			</div>

			<section class="AppWorkqueue__content">
				<div class="AppWorkqueue__row">
					<b-col>
						<ofs-panel>
							<b-form-group horizontal :description="workqueue.description" label="Name">
								<router-link
									v-if="workqueue.planId"
									:to="{ name: 'plan.detail', params: { id: workqueue.planId } }"
									v-text="workqueue.name"
								>
								</router-link>
								<strong v-else v-text="workqueue.name"></strong>
							</b-form-group>
							<b-form-group v-if="workqueue.tags && workqueue.tags.length" horizontal label="Tags">
								<b-badge
									v-for="tag in workqueue.tags"
									:key="tag"
									class="AppWorkqueue__smallBadge"
									:to="{
										name: 'app.detail.workqueues',
										params: { id: workqueue.appId },
										query: { tags: [tag] }
									}"
									v-text="tag"
									variant="primary"
								>
								</b-badge>
							</b-form-group>
							<b-form-group horizontal label="Started">
								<span class="Workqueue__label">
									<time-span :time="workqueue.created" />
								</span>
							</b-form-group>
							<b-form-group horizontal label="Ended">
								<span class="Workqueue__label">
									<time-span :time="workqueue.ended" />
								</span>
							</b-form-group>
							<b-form-group horizontal label="Status">
								<ofs-badge :text="workqueue.status" :status="workqueue.status" />
							</b-form-group>
						</ofs-panel>
					</b-col>

					<b-col>
						<ofs-panel class="AppWorkqueue__workflow">
							<workflow :tasks="workqueue.tasks" />
						</ofs-panel>
					</b-col>
				</div>

				<div class="AppWorkqueue__row">
					<b-col>
						<header class="App__panelTitle">Initial State</header>
						<ofs-panel>
							<tree-view class="AppWorkqueue__tree" :data="workqueue.initialState" />
						</ofs-panel>
					</b-col>
					<b-col>
						<header class="App__panelTitle">Output State</header>
						<ofs-panel>
							<tree-view class="AppWorkqueue__tree" :data="workqueue.state" />
						</ofs-panel>
					</b-col>
				</div>

				<div class="AppWorkqueue__row AppWorkqueue__logs">
					<b-col>
						<header class="App__panelTitle">Task Details</header>
						<ofs-panel class="AppWorkqueue__taskDetails">
							<b-table :fields="taskFields" :items="orderTasks(workqueue.tasks)" details>
								<template v-slot:cell(name)="data">
									<strong class="AppWorkqueue__table__name">{{ data.item.name }}</strong>
									<span class="AppWorkqueue__table__type">
										{{ data.item.type }}
										<span v-if="shouldDisplayAlias(data.item)">({{ data.item.alias }})</span>
									</span>
									<span class="AppWorkqueue__table__version" v-if="data.item.version">
										{{ data.item.version }} @ {{ data.item.hostName }}
									</span>
								</template>

								<template v-slot:cell(started)="data">
									<time-span :time="data.item.started" />
									<span
										v-if="data.item.started && data.item.wait > 1000"
										class="AppWorkqueue__table__wait"
										:title="data.item.wait"
									>
										(waited {{ data.item.wait | humanDuration }})
									</span>
								</template>

								<template v-slot:cell(ended)="data">
									<time-span :time="data.item.ended" />
									<span
										v-if="data.item.status === 'retry'"
										class="AppWorkqueue__table__retry"
										:title="data.item.wait"
									>
										(retrying <time-span :time="data.item.retryTime" />)
									</span>
								</template>

								<template v-slot:cell(duration)="data">
									{{ data.item | humanDuration }}
								</template>

								<template v-slot:cell(actions)="row">
									<b-dropdown
										class="AppWorkqueue__table__action"
										variant="primary"
										text="Re Run"
										size="sm"
										split
										@click.prevent="restartTask(row.item._id)"
									>
										<b-dropdown-item @click="openRestartWithAliasModal(row.item)">
											Re-run (new Alias)
										</b-dropdown-item>
									</b-dropdown>
									<b-button
										class="AppWorkqueue__table__action"
										variant="secondary"
										size="sm"
										@click.stop="toggleLogs(row)"
									>
										{{ row.detailsShowing ? 'Hide' : 'Show' }} Logs
									</b-button>
									<b-button
										class="AppWorkqueue__table__action"
										variant="secondary"
										size="sm"
										v-text="'View Source'"
										@click.prevent="openViewTaskSourceModal(row.item)"
									/>
								</template>

								<template v-slot:cell(progress)="data">
									<b-progress
										v-if="shouldDisplayProgress(data.item)"
										:max="1"
										:value="data.item.progress"
									/>
								</template>

								<template v-slot:cell(status)="row">
									<ofs-badge :text="row.item.status" :status="row.item.status" />
								</template>

								<template v-slot:cell(trace)="row">
									<Tracer
										:start-time="workqueue.started"
										:end-time="workqueue.ended"
										:trace-start-time="row.item.started"
										:trace-end-time="row.item.ended"
										:queued-time="row.item.queued"
									/>
								</template>

								<!-- https://stackoverflow.com/questions/71465132/how-to-fix-this-unexpected-useless-attribute-on-template-error-in-bootstrap -->
								<!-- eslint-disable-next-line vue/no-useless-template-attributes -->
								<template v-slot:row-details="data" class="AppWorkqueue__table__details">
									<ul class="AppWorkqueue__table__logs" :id="'log_' + data.item._id">
										<li
											v-for="log in orderLogs(data.item.logs.slice())"
											v-bind:key="log._id"
											class="AppWorkqueue__table__log"
										>
											<time-span
												class="AppWorkqueue__table__timestamp"
												:time="log.timestamp"
												format="shortTime"
											/>
											<div
												class="AppWorkqueue__table__message"
												:class="`AppWorkqueue__table__message--${log.level}`"
												v-html="linkify(log.message)"
											/>
											<span
												v-if="log.level === 'error'"
												class="AppWorkqueue__table__stack"
												:class="`AppWorkqueue__table__message--${log.level}`"
												@click="openViewStackModal(log)"
											>
												<fa-icon icon="exclamation-triangle" />
											</span>
										</li>
										<li v-if="page.logsFetched && !data.item.logs.length" class="AppWorkqueue__table__log">
											No logs available
										</li>
										<li v-if="page.logsLoading && !page.logsFetched" class="AppWorkqueue__table__log">
											Loading...
										</li>
											
									</ul>

									<div v-if="hasLotsOfLogs(data.item)" class="AppWorkqueue__table__logButtons">
										<b-button
											size="sm"
											variant="secondary"
											class="float-right"
											v-text="'Collapse Logs'"
											@click.prevent="data.toggleDetails"
										/>
										<b-button
											size="sm"
											class="float-right"
											variant="primary"
											v-text="'Re-run'"
											@click.prevent="restartTask(data.item._id)"
										/>
									</div>
								</template>
							</b-table>
						</ofs-panel>
					</b-col>
				</div>
			</section>
		</template>

		<view-source-modal
			v-if="source"
			:visible="page.isViewSourceModalVisible"
			title="Workqueue Source"
			:source="source"
			@hidden="hideViewSourceModal"
		/>

		<view-source-modal
			v-if="taskSource"
			:visible="page.isViewTaskSourceModalVisible"
			title="Task Source"
			:source="taskSource"
			@hidden="hideViewTaskSourceModal"
		/>

		<b-modal
			:visible="page.isViewStackModalVisible"
			title="Stack trace"
			:ok-only="true"
			ok-title="Close"
			size="lg"
			@hidden="hideViewStackModal"
		>
			<pre><samp>{{ page.logEntryLastClicked.message }}
{{ page.logEntryLastClicked.stacktrace }}</samp></pre>
		</b-modal>

		<b-modal
			:visible="page.isChooseAliasModalVisible"
			title="Re-run Task with a different Alias"
			ok-title="Run"
			:ok-disabled="!isAliasRunnable"
			@ok="restartTaskWithAlias"
			@hidden="hideRestartWithAliasModal"
		>
			<loader class="App__loader" v-if="page.isAliasLoading" />

			<b-form-group label="Name" label-for="AppWorkqueue__rerunName">
				<b-form-input disabled id="AppWorkqueue__rerunName" v-model="selectedTask.name" />
			</b-form-group>
			<b-form-group label="Type" label-for="AppWorkqueue__rerunType">
				<b-form-input disabled id="AppWorkqueue__rerunType" v-model="selectedTask.type" />
			</b-form-group>
			<b-form-group label="Aliases" label-for="AppWorkqueue__rerunAlias" v-if="!page.isAliasLoading">
				<b-form-select id="AppWorkqueue__rerunAlias" v-model="selectedAlias" :options="aliasOptions" />
			</b-form-group>
		</b-modal>
	</section>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { OfsPanel, OfsBadge } from '@workflow-solutions/ofs-vue-layout';
import humanizeDuration from 'humanize-duration';
import Workflow from '../../../components/Workflow.vue';
import TreeView from '../../../components/TreeView.vue';
import TimeSpan from '../../../components/TimeSpan.vue';
import Tracer from '../../../components/Tracer.vue';
import ViewSourceModal from './ViewSourceModal.vue';
import durationConfig from '../../../lib/durationConfig';
import codeEditorOptions from '../../../lib/codeEditorOptions';

export default {
	components: {
		OfsPanel,
		OfsBadge,
		Workflow,
		TreeView,
		TimeSpan,
		Tracer,
		ViewSourceModal
	},
	data() {
		return {
			codeEditorOptions,
			page: {
				isLoading: false,
				isAliasLoading: false,
				isViewSourceModalVisible: false,
				isViewTaskSourceModalVisible: false,
				isViewStackModalVisible: false,
				isChooseAliasModalVisible: false,
				logEntryLastClicked: {},
				taskLastClicked: {},
				logsFetched: false,
				logsLoading: false
			},
			taskFields: [
				'name',
				{ key: 'started', class: 'AppWorkqueue__table--minColumnWidth' },
				{ key: 'ended', class: 'AppWorkqueue__table--minColumnWidth' },
				{ key: 'duration', class: 'AppWorkqueue__table--minColumnWidth' },
				'actions',
				{ key: 'progress', class: 'AppWorkqueue__table--minColumnWidth' },
				'status',
				{ key: 'trace', class: 'AppWorkqueue__table--minColumnWidth' }
			],
			source: null,
			taskSource: null,
			aliasOptions: [],
			selectedAlias: null,
			selectedTask: {}
		};
	},
	computed: {
		...mapGetters({
			workqueue: 'workqueue/workqueue',
			app: 'app/app'
		}),
		isCancelButtonVisible() {
			return this.workqueue.status === 'notstarted' || this.workqueue.status === 'started';
		},
		isAliasRunnable() {
			return !this.page.isAliasLoading && this.selectedAlias;
		},
		cleanWorkqueue() {
			const findName = taskIdOrName => _.get(
				_.find(this.workqueue.tasks, t => t._id === taskIdOrName || t.name === taskIdOrName), 'name'
			);
			return {
				..._.pick(this.workqueue, ['name', 'description', 'tags', 'initialState', 'outputsDef']),
				firstTasks: _.map(this.workqueue.firstTasks, findName),
				errorTasks: _.map(this.workqueue.errorTasks, findName),
				tasks: _.map(this.workqueue.tasks, task => ({
					..._.pick(task, [
						'name',
						'description',
						'type',
						'alias',
						'condition',
						'continueOnError',
						'inputs',
						'outputs'
					]),
					depends: _.map(task.depends, findName)
				}))
			};
		},
		runWorkqueuePath() {
			return {
				path: `/apps/${this.workqueue.appId}/workqueues/create`,
				query: {
					workqueue: JSON.stringify(this.cleanWorkqueue)
				}
			};
		}
	},
	beforeDestroy() {
		this.unsubcribeFromUpdates();
	},
	methods: {
		...mapActions({
			getApp: 'app/get',
			subscribeToWorkqueue: 'workqueue/subscribeToWorkqueue',
			unsubscribeFromWorkqueue: 'workqueue/unsubscribeFromWorkqueue',
			subscribeToWorkqueueTasks: 'workqueue/subscribeToWorkqueueTasks',
			unsubscribeFromWorkqueueTasks: 'workqueue/unsubscribeFromWorkqueueTasks',
			subscribeToWorkqueueTaskLogs: 'workqueue/subscribeToWorkqueueTaskLogs',
			unsubscribeFromWorkqueueTaskLogs: 'workqueue/unsubscribeFromWorkqueueTaskLogs',
			getWorkqueue: 'workqueue/findById',
			putCancelWorkqueue: 'workqueue/cancel',
			loadWorkqueueLogs: 'workqueue/getLogs',
			postTaskRestart: 'workqueue/restartTask',
			postTaskRestartWithAlias: 'workqueue/restartTaskWithAlias',
			getAliases: 'worker/loadAliases'
		}),
		unsubcribeFromUpdates() {
			this.unsubscribeFromWorkqueue({ appId: this.workqueue.appId, workqueueId: this.workqueue._id });
			this.unsubscribeFromWorkqueueTasks({ appId: this.workqueue.appId, workqueueId: this.workqueue._id });
			this.unsubscribeFromWorkqueueTaskLogs({ appId: this.workqueue.appId, workqueueId: this.workqueue._id });
		},
		openViewSourceModal() {
			this.page.isViewSourceModalVisible = true;
		},
		hideViewSourceModal() {
			this.page.isViewSourceModalVisible = false;
		},
		openViewTaskSourceModal(task) {
			this.taskSource = JSON.stringify(task, null, 2);
			this.page.isViewTaskSourceModalVisible = true;
		},
		hideViewTaskSourceModal() {
			this.page.isViewTaskSourceModalVisible = false;
		},
		openViewStackModal(log) {
			this.page.logEntryLastClicked = log;
			this.page.isViewStackModalVisible = true;
		},
		hideViewStackModal() {
			this.page.isViewStackModalVisible = false;
		},
		shouldDisplayAlias(task) {
			return task.alias && task.alias !== 'default';
		},
		shouldDisplayProgress(task) {
			return _.isNumber(task.progress);
		},
		scrollToLog(logId) {
			const logs = this.$el.querySelector(`#log_${logId}`);
			if (logs) {
				// checks logs are present
				const offsetHeight = this.getElementOffset(logs).top + logs.offsetHeight;
				// grab the main frame of the app that needs to be scrolled
				const frame = document.querySelector('.OneflowAppLayout-MainContent');
				frame.scrollTop = offsetHeight;
			}
		},
		getElementOffset(el) {
			let top = 0;
			let left = 0;
			let element = el;
			// Loop through the DOM tree
			// and add it's parent's offset to get page offset
			do {
				top += element.offsetTop || 0;
				left += element.offsetLeft || 0;
				element = element.offsetParent;
			} while (element);
			return {
				top,
				left
			};
		},
		hasLotsOfLogs(task) {
			const lots = 10;
			return task.logs.length > lots;
		},
		async toggleLogs(row) {
			await row.toggleDetails();
			if (!this.page.logsFetched) {
				await this.getWorkqueueLogs();
			}
			// await next iteration of lifecycle hook (waits until logs are rendered)
			await this.$nextTick();
			this.scrollToLog(row.item._id);
		},
		async refreshLogs() {
			await this.getWorkqueueLogs(true);
			await this.$nextTick();
		},
		taskHasLogs(task) {
			if (!Array.isArray(task.logs)) return false;
			return task.logs.length !== 0;
		},
		async fetchWorkqueue() {
			this.page.isLoading = true;
			try {
				await this.getApp({ id: this.$route.params.id });
			} catch (err) {
				this.$toaster.error(`Couldn't load application details... ${err}`);
				await this.$router.push({ name: 'app.list' });
				return;
			}
			try {
				await this.getWorkqueue({ id: this.$route.params.workqueue });

				this.source = JSON.stringify(this.workqueue, null, 2);

				this.workqueue.tasks.forEach(task => {
					this.$set(task, '_showDetails', false);
					task.logs = [];
				});

				// Subscribe to realtime updates

				// - Workqueue updates
				this.subscribeToWorkqueue({
					appId: this.workqueue.appId,
					workqueueId: this.workqueue._id
				});

				// - Workqueue tasks
				this.subscribeToWorkqueueTasks({
					appId: this.workqueue.appId,
					workqueueId: this.workqueue._id
				});

			} catch (err) {
				this.$toaster.error(`Error loading workqueue: ${err}`);
				this.$router.push({ name: 'app.detail.workqueues', params: { id: this.$route.params.id } });
			}
			this.page.isLoading = false;
		},
		async cancelWorkqueue() {
			try {
				await this.putCancelWorkqueue({ workqueueId: this.workqueue._id });
				this.$toaster.success('Cancelled workqueue');
			} catch (err) {
				this.$toaster.error(`Couldn't cancel workqueue: ${err}`);
			}
		},
		async getWorkqueueLogs(replaceLogs) {
			try {
				this.page.logsLoading = true;
				const query = { };
				if (this.app.variables && this.app.variables.length) {
					const athenaSetting = this.app.variables.find(variable => variable.name === 'athena');
					if (athenaSetting) query.athena = athenaSetting.value || 'false';
				}
				
				const logs = await this.loadWorkqueueLogs({ workqueueId: this.workqueue._id, query });
				
				// capture fallback if taskid is not present
				let groupedLogs = _.groupBy(logs, l => l.taskid);
				if(groupedLogs[undefined]) groupedLogs = _.groupBy(logs, l => l.taskId);

				this.workqueue.tasks.forEach(task => {
					if (replaceLogs) task.logs = [];
					task.logs = [...(task.logs || []), ...(groupedLogs[task._id] || [])];
				});
				
				this.$forceUpdate();

				// - Workqueue task logs
				this.subscribeToWorkqueueTaskLogs({
					appId: this.workqueue.appId,
					workqueueId: this.workqueue._id
				});
				this.page.logsFetched = true;
				this.page.logsLoading = false;
				this.$toaster.success('Workqueue Logs Loaded');
			} catch (err) {
				this.$toaster.error(`Couldn't load workqueue logs: ${err}`);
			}
		},
		async restartTask(id) {
			const toRestart = this.workqueue.tasks.find(task => task._id === id);
			try {
				await this.postTaskRestart({ id });
				this.$toaster.success(`Task: [${toRestart.name}] restarted.`);
			} catch (err) {
				this.$toaster.error(`Couldn't restart task: [${toRestart.name}]... ${err}`);
			}
		},
		async restartTaskWithAlias() {
			if (this.selectedAlias) {
				try {
					await this.postTaskRestartWithAlias({ id: this.selectedTask._id, alias: this.selectedAlias });
					this.$toaster.success(
						`Task: [${this.selectedTask.name}] restarted with Alias: [${this.selectedAlias}].`
					);
				} catch (err) {
					this.$toaster.error(
						`Couldn't restart task: [${this.selectedTask.name}] with Alias: \
						[${this.selectedAlias}]... ${err}`
					);
				}
				this.selectedAlias = null;
				this.selectedTask = {};
			} else {
				alert('Please select an Alias!'); // eslint-disable-line no-alert
			}
		},
		openRestartWithAliasModal(task) {
			this.selectedTask = task;
			this.fetchAliases(task.type);
		},
		hideRestartWithAliasModal() {
			this.page.isChooseAliasModalVisible = false;
		},
		async fetchAliases(type) {
			this.page.isAliasLoading = true;
			try {
				this.page.isChooseAliasModalVisible = true;
				const aliasData = await this.getAliases({ type });
				this.aliasOptions = aliasData.data.aliases.map(alias => alias.name);
			} catch (err) {
				this.$toaster.error(`Couldn't get aliases for type: [${type}]... ${err}`);
				this.page.isChooseAliasModalVisible = false;
			}
			this.page.isAliasLoading = false;
		},
		orderLogs(logs) {
			return _.orderBy(logs, item => new Date(item.timestamp));
		},
		orderTasks(tasks) {
			if (!Array.isArray(tasks)) return [];
			const orderedTasks = tasks.filter(t => !t.depends || t.depends.length === 0);
			const otherTasks = tasks.filter(t => t.depends && t.depends.length > 0);
			const keyed = _.keyBy(orderedTasks, t => t._id);

			let iteration = 0;
			while (otherTasks.length) {
				const top = otherTasks.shift();
				if (_.every(top.depends, d => keyed[d])) {
					orderedTasks.push(top);
					keyed[top._id] = top;
				} else {
					otherTasks.push(top);
				}
				iteration++;
				if (iteration > tasks.length * 4) {
					return tasks; // we couldn't sort xby dependency (within reasonable iterations)
				}
			}
			return orderedTasks;
		},
		linkify(inputText) {
			// URLs starting with http://, https://, or ftp://
			const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
			let replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

			// URLs starting with www. (without // before it, or it'd re-link the ones done above)
			const replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
			replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

			// Change email addresses to mailto:: links
			const replacePattern3 = /([\w.]+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim;
			replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

			return replacedText;
		}
	},
	filters: {
		humanDuration: input => {
			const humanizer = humanizeDuration.humanizer(durationConfig);
			if (_.isNumber(input)) {
				return humanizer(input);
			}
			if (input.started && input.ended) {
				const duration = moment.duration(moment(input.ended).diff(moment(input.started))).asMilliseconds();
				return humanizer(duration);
			}
			if (input.started) {
				return 'in progress';
			}
			return '-';
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				if (this.$route.params.workqueue !== this.workqueue._id) this.unsubcribeFromUpdates();
				this.fetchWorkqueue();
			}
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/src/styles/core';

.AppWorkqueue {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;

	&__header {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 1rem;

		&__title {
			font-size: 1.75rem;
			font-weight: 500;
		}
	}

	&__content {
		width: 100%;
		max-width: 100%;
	}

	&__smallBadge {
		margin-right: 1rem;
	}

	&__action {
		margin-left: 0.5rem;
	}

	&__row {
		display: flex;
		flex-direction: row;
		margin-bottom: 1rem;
		padding: 0;

		> .col {
			width: 50%;
			padding: 0;

			&:first-of-type {
				padding-right: 0.5rem;
			}
			&:last-of-type {
				padding-left: 0.5rem;
			}
		}
	}

	&__workflow {
		> * {
			width: 100%;
			display: flex;
			justify-content: center;
		}
	}

	&__logs {
		width: 100%;

		.OfsPanel-content {
			width: 100%;
		}
	}

	&__tree {
		overflow-x: scroll;
	}

	&__table {
		width: 100%;

		&--minColumnWidth {
			min-width: 120px;
		}

		&__name {
			font-weight: normal;
			display: block;
		}

		&__type {
			text-transform: uppercase;
			font-size: 0.7rem;
			opacity: 0.6;
			display: block;
		}

		&__version {
			font-size: 0.7rem;
			opacity: 0.8;
		}

		&__wait {
			font-size: 0.7rem;
			opacity: 0.8;
			display: block;
		}

		&__retry {
			font-size: 0.7rem;
			opacity: 0.8;
			display: block;
		}

		&__action {
			margin-right: 0.5rem;
		}

		&__logs {
			list-style: none;
			margin: 0 12px;
			padding: 1rem;
			background: #222222;
			color: #e8e8e8;
			border-radius: 0.3rem;
			overflow: auto;
		}

		&__log {
			margin: 0.5rem 0 0;
			padding: 0;
			display: flex;
			word-break: break-word;

			&:first-child {
				margin: 0;
			}
		}

		&__timestamp {
			font-weight: bold;
			display: inline;
			margin-right: 1rem;
			flex-shrink: 0;
		}

		&__message {
			margin: 0;
			padding: 0;
			display: inline;
			flex: 1;

			&--error {
				color: $danger;
			}
		}

		&__stack {
			cursor: pointer;
		}

		&__details {
			background: $danger;
		}

		&__logButtons {
			margin-top: 8px;
		}
	}

	&__taskDetails {
		width: 100%;
	}
}
</style>

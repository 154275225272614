<template>
	<b-modal
		:visible="visible"
		:title="titleText"
		:ok-title="okText"
		:ok-disabled="!isTeamValid"
		@ok="onSubmitClick"
		@hidden="$emit('hidden')">

		<form @submit.prevent="onSubmitClick">
			<b-form-group label="Name" for="EditTeam__name">
				<b-form-input
					id="EditTeam__name"
					v-model="teamName"/>
			</b-form-group>
		</form>

	</b-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	props: {
		visible: {
			type: Boolean,
			required: true
		},
		createMode: {
			type: Boolean,
			default: false
		},
		team: {
			type: Object
		}
	},
	data() {
		return {
			page: {
				isSubmitting: false
			},
			teamName: null
		};
	},
	computed: {
		isTeamValid() {
			return this.teamName;
		},
		titleText() {
			if (this.createMode) return 'New Team';
			return 'Edit Team';
		},
		okText() {
			if (this.createMode) return 'Create';
			return 'Update';
		}
	},
	methods: {
		...mapActions({
			createTeam: 'team/create',
			updateTeam: 'team/update'
		}),
		async onSubmitClick(event) {
			event.preventDefault();
			this.page.isSubmitting = true;
			try {
				let result;
				if (this.createMode) {
					result = await this.createTeam({ name: this.teamName });
					this.$toaster.success(`Created team: ${this.teamName}`);
				} else {
					result = await this.updateTeam({
						id: this.$route.params.id,
						data: {
							...this.team,
							name: this.teamName
						}
					});
					this.$toaster.success(`Updated team: ${this.teamName}`);
				}
				this.$emit('submit', result);
				this.$emit('hidden');
			} catch (err) {
				if (this.createMode) {
					this.$toaster.error(`Error creating team: ${err}`);
				} else {
					this.$toaster.error(`Error updating team: ${err}`);
				}
			}
			this.page.isSubmitting = false;
		}
	},
	watch: {
		team: {
			immediate: true,
			handler() {
				if (this.team) {
					this.teamName = this.team.name;
				}
			}
		},
		visible: {
			handler() {
				if (this.team) {
					this.teamName = this.team.name;
				} else {
					this.teamName = '';
				}
			}
		}
	}
};
</script>

import List from './List';
import Detail from './Detail';

const routes = [
	{
		name: 'team.list',
		path: '/teams',
		component: List
	},
	{
		name: 'team.detail',
		path: '/teams/:id',
		component: Detail
	}
];

export default routes;

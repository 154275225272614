import _get from 'lodash/get';

export const account = state => state.account;
export const accountSettings = state => _get(state, 'vars.oneflowAccountSettings');
export const accounts = state => state.accounts;
export const user = state => state.user;
export const vars = state => state.vars;
export const currentAccount = state => state.currentAccount;
export const accountMemberships = state => state.accountMemberships;
export const member = state => state.member;

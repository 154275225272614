import _ from 'lodash';
import Vue from 'vue';
import pusherClient from '../../lib/pusherClient';

export default function () {

	Vue.$ofsCrud.addResource({
		name: 'workqueue',
		pluralName: 'workqueues',
		state: {
			subscriptions: {}
		},
		options: {
			namespaced: true
		},
		transport: 'workforce',
		mutations: {
			ADD_WORKQUEUE(state, data, perPage) {
				state.workqueues.data.unshift(data);
				if (!perPage || perPage === state.workqueues.data.length) {
					state.workqueues.data.splice(-1, 1);
				}
			},
			PATCH_WORKQUEUE(state, data) {
				state.workqueue = _.extend({}, state.workqueue, data);
			},
			PATCH_WORKQUEUES(state, data) {
				const workqueueIndex = state.workqueues.data.findIndex(workqueue => workqueue._id === data._id);
				if (workqueueIndex !== -1) {
					const updatedWorkqueue = _.extend(state.workqueues.data[workqueueIndex], data);
					Vue.set(state.workqueues.data, workqueueIndex, updatedWorkqueue);
				}
			},
			PATCH_WORKQUEUE_TASK(state, data) {
				const task = _.find(state.workqueue.tasks, {
					_id: data._id
				});

				if (task) {
					_.extend(task, data);
				}
			},
			PATCH_WORKQUEUE_TASK_LOGS(state, data) {
				const task = _.find(state.workqueue.tasks, {
					_id: data._id
				});

				if (task) {
					data.logs.forEach(log => {
						const matchedLog = _.find(task.logs, {
							_id: log._id
						});

						if (matchedLog) {
							// If log entry exists, update it
							_.merge(matchedLog, log);
						} else {
							// Else push it onto the stack
							task.logs.push(log);
						}
					});

					// Order the log entries
					task.logs = _.orderBy(task.logs, item => new Date(item.timestamp)).reverse();
				}
			}
		},
		actions: {
			subscribeToWorkqueue({ commit, state }, { appId, workqueueId }) {
				const channelName = `apps.${appId}.workqueues.${workqueueId}`;
				if (state.subscriptions[channelName]) return;
				pusherClient.subscribeToChannel(
					channelName,
					doc => commit('PATCH_WORKQUEUE', doc.data)
				);
				state.subscriptions[channelName] = true;
			},
			unsubscribeFromWorkqueue({ state }, { appId, workqueueId }) {
				const channelName = `apps.${appId}.workqueues.${workqueueId}`;
				delete state.subscriptions[channelName];
				pusherClient.unsubscribeFromChannel(channelName);
			},
			subscribeToWorkqueues({ commit, state }, { appId, filters = {} }) {
				const channelName = `apps.${appId}.workqueues`;
				if (state.subscriptions[channelName]) return;
				pusherClient.subscribeToChannel(
					`apps.${appId}.workqueues`,
					doc => {
						if (filters) {
							if (filters.status) {
								const workqueueStatus = doc.data.status;
								if (workqueueStatus !== filters.status) return;
							}
							if (filters.tags && filters.tags.length > 0) {
								const { tags: workqueueTags } = doc.data;
								let isMatch = false;
								if (workqueueTags && workqueueTags.length > 0) {
									filters.tags.forEach(tag => {
										if (workqueueTags.includes(tag)) isMatch = true;
									});
								}
								if (!isMatch) return;
							}
							if (filters.workqueueIds) {
								if (!filters.workqueueIds.includes(doc.data._id)) return;
							}
							const workqueueStart = new Date(doc.data.created).getTime();
							if (filters.created.$lte) {
								const createdBefore = new Date(filters.created.$lte).getTime();
								if (createdBefore >= workqueueStart) return;
							}
							if (filters.created.$gte) {
								const createdAfter = new Date(filters.created.$gte).getTime();
								if (createdAfter <= workqueueStart) return;
							}
						}
						switch (doc.action) {
							case 'save':
								commit('ADD_WORKQUEUE', doc.data, filters.perPage);
								break;
							default:
								commit('PATCH_WORKQUEUES', doc.data);
						}
					}
				);
				state.subscriptions[channelName] = true;
			},
			unsubscribeFromWorkqueues({ state }, { appId }) {
				const channelName = `apps.${appId}.workqueues`;
				pusherClient.unsubscribeFromChannel(channelName);
				delete state.subscriptions[channelName];
			},
			subscribeToWorkqueueTasks({ commit, state }, { appId, workqueueId }) {
				const channelName = `apps.${appId}.workqueues.${workqueueId}.tasks`;
				if (state.subscriptions[channelName]) return;
				pusherClient.subscribeToChannel(
					channelName,
					doc => commit('PATCH_WORKQUEUE_TASK', doc.data)
				);
				state.subscriptions[channelName] = true;
			},
			unsubscribeFromWorkqueueTasks({ state }, { appId, workqueueId }) {
				const channelName = `apps.${appId}.workqueues.${workqueueId}.tasks`;
				pusherClient.unsubscribeFromChannel(channelName);
				delete state.subscriptions[channelName];
			},
			subscribeToWorkqueueTaskLogs({ commit, state }, { appId, workqueueId }) {
				const channelName = `apps.${appId}.workqueues.${workqueueId}.tasks.logs`;
				if (state.subscriptions[channelName]) return;
				pusherClient.subscribeToChannel(
					channelName,
					doc => commit('PATCH_WORKQUEUE_TASK_LOGS', doc.data)
				);
				state.subscriptions[channelName] = true;
			},
			unsubscribeFromWorkqueueTaskLogs({ state }, { appId, workqueueId }) {
				const channelName = `apps.${appId}.workqueues.${workqueueId}.tasks.logs`;
				pusherClient.unsubscribeFromChannel(channelName);
				delete state.subscriptions[channelName];
			},
			cancel({ dispatch }, { workqueueId }) {
				const data = { status: 'cancelled' };
				const path = `workqueues/${workqueueId}/end`;
				return dispatch('request', { method: 'POST', path, payload: data }).then(r => r.data);
			},
			restartTask({ dispatch }, { id }) {
				const data = {
					taskId: id
				};
				const path = `tasks/${id}/restart`;
				return dispatch('request', { method: 'POST', path, payload: data }).then(r => r.data);
			},
			restartTaskWithAlias({ dispatch }, { id, alias }) {
				const data = {
					taskId: id,
					aliasName: alias
				};
				const path = `tasks/${id}/restart/alias/${alias}`;
				return dispatch('request', { method: 'POST', path, payload: data }).then(r => r.data);
			},

			getLogs({ dispatch }, { workqueueId, query }) {
				const path = `workqueues/${workqueueId}/logs`;
				return dispatch('request', { method: 'GET', path, query }).then(r => r.data);
			},

			async startWorkqueue({ dispatch }, { appId, data }) {
				const path = `workqueues?appId=${appId}`;
				const { data: result } = await dispatch('request', { method: 'POST', path, payload: data });
				return result;
			},
			getTasks({ dispatch }, { id, dates, status }) {
				const startDate = dates.start;
				const endDate = dates.end;
				const path = `workqueues/recent?appId=${id}&endDate=${endDate}&startDate=${startDate}&status=${status}`;
				return dispatch('request', { method: 'GET', path });
			}
		},
		getters: {
			page: state => state.page,
			total: state => state.total,
			perPage: state => state.perPage
		}
	});
}

<template>
	<b-container fluid class="LoginPage d-flex flex-column">
		<b-row align-h="center">
			<b-col sm="6" lg="4">
				<b-button
					v-if="showHpId"
					class="LoginPage-button"
					variant="primary"
					size="large"
					@click="loginWithHPID">
						Sign In to Workforce with HPID
				</b-button>
				<p v-if="showHpId">or</p>
				<LoginForm
					class="LoginPage-form"
					:onLoginRequest="handleLegacyLoginRequest"
					:onResetPasswordRequest="handleLegacyResetPasswordRequest">
					<div slot="logo">
						<div class="LoginPage-top">
							sign in to Workforce
						</div>
					</div>
				</LoginForm>
			</b-col>
		</b-row>
	</b-container>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import { LoginForm } from '@workflow-solutions/ofs-vue-layout';

export default {
	name: 'LoginPage',
	components: {
		LoginForm
	},
	computed: {
		...mapGetters('auth', ['isAuthenticated']),
		redirectTo() {
			const { redirectTo } = this.$route.query || {};
			return redirectTo;
		},
		showHpId() {
			return window.$config.hpIdEnabled;
		}
	},
	methods: {
		...mapActions('auth', ['login', 'resetPassword']),
		async handleLegacyLoginRequest(credentials) {
			const response = await this.login(credentials);
			const redirectTo = this.redirectTo || '/';
			this.$router.push(redirectTo);
			return response;
		},
		async handleLegacyResetPasswordRequest({ email }) {
			await this.resetPassword(email);
			this.$toaster.info('Password reset email has been sent');
		},
		loginWithHPID() {
			window.location.href = window.$config.hpIdAuthorize
		}
	},
	created() {
		if (this.isAuthenticated) this.$router.push('/');
	}
};

</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/src/styles/core';

	.LoginPage {
		height: 100vh;
		width: 100vw;

		> .row.justify-content-center {
			height: 100%;
			> .col-sm-6.col-lg-4 {
				align-self: center;
			}
		}
		&-top-brand-centre {
			background-color: $primary;
			padding: 10px 20px;
			color: #fff;
			font-size: 18px;
			font-weight: 700;
		}
		&-bottom {
			background-color: #5a5a5a;
			font-size: 14px;
			font-weight: 700;
			color: #fff;
			padding: 20px 20px;
			line-height: 25px;
			margin: 0 -15px;

			a {
				color: #fff;
			}

			a:hover {
				text-decoration: none;
			}
		}

		&-logo {
			height: 100px;
		}

		&-form {
			.btn-info {
				background: $primary;
			}
		}

		&-button {
			width: 100%;
			margin-bottom: 50px;
		}
	}
</style>

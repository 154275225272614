<template>
	<DefaultLayout>

		<ContentHeader
			:title="title"
			:breadcrumbs="breadcrumbs">

			<b-button
				class="WorkerDetail__action"
				variant="secondary"
				v-text="'Starter Kits'"
				@click="openStarterKitsModal"/>
			<template v-if="!isDeleted && !page.isLoading">
				<b-button
					class="WorkerDetail__action"
					variant="danger"
					v-text="'Delete Worker'"
					:disabled="page.isSubmitting"
					@click="openConfirmDeleteModal"/>
				<b-button
					class="WorkerDetail__action"
					variant="primary"
					v-text="'Update Worker'"
					:disabled="page.isSubmitting"
					@click="onUpdateWorkerClick"/>
			</template>
		</ContentHeader>

		<section class="WorkerDetail__content">

			<loader class="App__loading" v-if="page.isLoading || page.isSubmitting"/>

			<template v-if="!page.isLoading && !page.isSubmitting">
				<div class="WorkerDetail__row">
					<div class="WorkerDetail__col">
						<b-form-group label="Name" label-for="WorkerDetail__name">
							<b-form-input
								id="WorkerDetail__name"
								v-model="editedWorker.name"
								:disabled="isDeleted"/>
						</b-form-group>
						<b-form-group label="Description" label-for="WorkerDetail__description">
							<b-form-textarea
								id="WorkerDetail__description"
								v-model="editedWorker.description"
								:disabled="isDeleted"
								:rows="5"
								no-resize
								fluid>
							</b-form-textarea>
						</b-form-group>
					</div>
					<div class="WorkerDetail__col">
						<b-form-group label="Team" label-for="WorkerDetail__team">
							<b-form-input
								id="WorkerDetail__team"
								v-model="worker.teamId.name"
								disabled/>
						</b-form-group>
						<b-form-group label="Task" label-for="WorkerDetail__task">
							<b-form-input
								id="WorkerDetail__task"
								v-model="worker.type"
								disabled/>
						</b-form-group>
					</div>
				</div>

				<div v-if="!isDeleted" class="WorkerDetail__row">
					<b-button
						variant="warning"
						v-text="toggleAliasButtonText"
						:disabled="page.isLoadingAlias"
						@click="toggleShowAliases"/>
				</div>

				<div class="WorkerDetail__row">
					<b-collapse
						:visible="page.areAliasesVisible"
						class="WorkerDetail__aliasCollapse"
						id="WorkerDetail__aliasCollapse">
						<b-table
							:items="worker.aliases"
							:fields="aliasFields"
							striped
							bordered>

							<template v-slot:cell(keys)="data">
								<table>
									<tr>
										<td><b>Access Key:</b></td>
										<td>
											<span
												class="Worker__alias__key"
												v-text="data.item.accessKeyId"/>
											<b-button
												class="Worker__alias__clipboard"
												v-clipboard:copy="data.item.accessKeyId"
												size="sm"
												v-text="'Copy'"/>
										</td>
									</tr>
									<tr>
										<td><b>Secret Key:</b></td>
										<td>
											<span
												class="Worker__alias__key"
												v-text="data.item.secretAccessKey"/>
											<b-button
												v-clipboard:copy="data.item.secretAccessKey"
												size="sm"
												class="Worker__alias__clipboard"
												v-text="'Copy'"/>
										</td>
									</tr>
									<tr>
										<td><b>Queue URL:</b></td>
										<td>
											<span
												class="Worker__alias__key"
												v-text="data.item.queueUrl"/>
											<b-button
												v-clipboard:copy="data.item.queueUrl"
												size="sm"
												class="Worker__alias__clipboard"
												v-text="'Copy'"/>
										</td>
									</tr>
								</table>
								<hr />
								<h3> <b>Private Keys </b></h3>
								<table>
									<tr v-if="data.item.privateKeys && data.item.privateKeys.length">
										<td><b>Name</b></td>
										<td><b>created</b></td>
										<td><b>expires in</b></td>
										<td><b>Actions</b></td>
									</tr>
									<tr v-for="privKey in data.item.privateKeys" :key="privKey._id">
											<td>{{ privKey.name }}</td>
											<td><time-span :time="privKey.createdAt" /></td>
											<td>{{ privKey.expiresAt | daysLeft}}</td>
											<td>
												<b-button
													size="sm"
													variant="info"
													:disabled="page.isSubmitting"
													@click="onPrivKeyMoreInfoClick(data.item, privKey)"
													v-text="'More Info'"
													class="Worker__alias__privkey"/>
												<b-button
													size="sm"
													variant="danger"
													:disabled="page.isSubmitting"
													@click="onDeletePrivKeyClick(data.item, privKey)"
													v-text="'Delete'"
													class="Worker__alias__privkey"/>
											</td>
									</tr>
									<tr>
										<td><b-button
												size="sm"
												variant="info"
												:disabled="page.isSubmitting"
												@click="onGeneratePrivKeyClick(data.item)"
												v-text="'Generate New Priv Key'"/></td>
									</tr>
								</table>
							</template>

							<template v-slot:cell(action)="data">
								<b-button
									v-if="(data.item.name!=='default')"
									variant="danger"
									v-text="'Delete'"
									@click="onDeleteAliasClick(data.item)"/>
							</template>

						</b-table>
						<b-button
							class="WorkerDetail__addAlias"
							variant="primary"
							v-text="'Add Alias'"
							@click="openAddAliasModal"/>
					</b-collapse>
				</div>

				<div class="WorkerDetail__row">

					<div class="WorkerDetail__col">
						<h4>Inputs</h4>
						<codemirror
							class="WorkerDetail__code"
							v-model="editedWorker.inputs"
							:options="codeEditorOptions"/>
					</div>

					<div class="WorkerDetail__col">
						<h4>Outputs</h4>
						<codemirror
							class="WorkerDetail__code"
							v-model="editedWorker.outputs"
							:options="codeEditorOptions"/>
					</div>

				</div>
			</template>

		</section>

		<starter-kits-modal
			:visible="page.isStarterKitsModalVisible"
			@hidden="hideStarterKitsModal"/>

		<add-alias-modal
			:visible="page.isAddAliasModalVisible"
			@hidden="hideAddAliasModal"
			@submit="fetch"/>

		<confirm-modal
			:visible="page.isConfirmDeleteModalVisible"
			title="Delete Worker"
			text="Are you sure you want to delete this worker?"
			:danger="true"
			@ok="onConfirmDeleteWorkerClick"
			@hidden="hideConfirmDeleteModal"/>

		<confirm-modal
			:visible="page.isConfirmDeleteAliasModalVisible"
			title="Delete Alias"
			text="Are you sure you want to delete this alias?"
			:danger="true"
			@ok="onConfirmDeleteAliasClick"
			@hidden="hideConfirmDeleteAliasModal"/>

		<confirm-modal
			:visible="page.isConfirmDeleteAliasPrivKeyModal"
			:title="`Delete Alias ${page.aliasLastClicked ? page.aliasLastClicked.name : ''}'s Private Key`"
			:text="`Are you sure you want to delete this private key:
							${page.aliasPrivKeyLastClicked ? page.aliasPrivKeyLastClicked.name : ''}?`"
			:danger="true"
			@ok="onConfirmDeleteAliasPrivKeyClick"
			@hidden="hideConfirmDeleteAliasPrivKeyModal"/>

		<view-source-modal
			v-if="page.aliasPrivKeyLastClicked && page.aliasPrivKeyLastClicked.privateKey"
			:visible="page.isAliasPrivKeyInfoModal"
			:title="`New PrivateKey for ${page.aliasLastClicked ? page.aliasLastClicked.name : ''} alias. Please copy private key and keep safe. It will not be saved in the database`"
			:source="JSON.stringify(page.aliasPrivKeyLastClicked, null, 2)"
			copyBtn="true"
			@ok="hideAliasPrivKeyInfoModal"
			@hidden="hideAliasPrivKeyInfoModal"/>

		<view-source-modal
			v-if="page.aliasPrivKeyLastClicked && !page.aliasPrivKeyLastClicked.privateKey"
			:visible="page.isAliasPrivKeyInfoModal"
			:title="`More Details for PrivateKey: ${page.aliasPrivKeyLastClicked.name}`"
			:source="JSON.stringify(page.aliasPrivKeyLastClicked, null, 2)"
			copyBtn="true"
			@ok="hideAliasPrivKeyInfoModal"
			@hidden="hideAliasPrivKeyInfoModal"/>

		<add-alias-priv-key-modal
			:visible="page.isAddAliasPrivKeyModalVisible"
			@hidden="hideAddAliasPrivKeyModal"
			@ok="onAddAliasPrivKeySubmit"/>

	</DefaultLayout>
</template>

<script type="text/javascript">
import _ from 'lodash';
import { ContentHeader } from '@workflow-solutions/ofs-vue-layout';
import { mapGetters, mapActions } from 'vuex';
import codeEditorOptions from '../../lib/codeEditorOptions';
import DefaultLayout from '../../components/DefaultLayout';
import ConfirmModal from '../../components/ConfirmModal';
import ViewSourceModal from '../App/components/ViewSourceModal';
import StarterKitsModal from './components/StarterKitsModal';
import AddAliasModal from './components/AddAliasModal';
import AddAliasPrivKeyModal from './components/AddAliasPrivKeyModal';
import TimeSpan from '../../components/TimeSpan';

export default {
	components: {
		DefaultLayout,
		ContentHeader,
		ConfirmModal,
		StarterKitsModal,
		AddAliasModal,
		AddAliasPrivKeyModal,
		ViewSourceModal,
		TimeSpan
	},
	data() {
		return {
			codeEditorOptions,
			page: {
				isLoading: false,
				isLoadingAlias: false,
				isSubmitting: false,
				areAliasesVisible: false,
				isStarterKitsModalVisible: false,
				isConfirmDeleteModalVisible: false,
				isAddAliasModalVisible: false,
				isAddAliasPrivKeyModalVisible: false,
				isConfirmDeleteAliasModalVisible: false,
				isConfirmDeleteAliasPrivKeyModal: false,
				isAliasPrivKeyInfoModal: false,
				aliasLastClicked: null,
				aliasPrivKeyLastClicked: null,
				newAliasName: null
			},
			editedWorker: null,
			newAliasName: '',
			aliasFields: [
				'name',
				'keys',
				{
					key: 'action',
					label: ' '
				}
			]
		};
	},
	computed: {
		...mapGetters({
			worker: 'worker/worker'
		}),
		breadcrumbs() {
			return [
				{
					text: 'All Workers',
					to: { name: 'worker.list' }
				},
				{
					text: this.worker.name,
					active: true
				}
			];
		},
		toggleAliasButtonText() {
			if (this.page.areAliasesVisible) return 'Hide Aliases';
			return 'Show Aliases';
		},
		title() {
			if (this.isDeleted) return `${this.worker.name} [deleted]`;
			return this.worker.name;
		},
		isDeleted() {
			if (this.worker) return this.worker.status === 'deleted';
			return false;
		}
	},
	filters: {
		daysLeft(value) {
			if (value) {
				const d = new Date(value);
				const diffTime = Math.abs(d - Date.now());
				const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
				return `${diffDays} days`
			}
			return '';
		}
	},
	methods: {
		...mapActions({
			getWorker: 'worker/get',
			deleteWorker: 'worker/deleteById',
			updateWorker: 'worker/update',
			deleteAlias: 'worker/deleteAlias',
			deleteAliasPrivKey: 'worker/deleteAliasPrivKey',
			generateAliasPrivKey: 'worker/generateAliasPrivKey'
		}),
		async fetch() {
			this.page.isLoading = true;
			try {
				await this.getWorker({ id: this.$route.params.id });
				this.editedWorker = {
					..._.cloneDeep(this.worker),
					inputs: JSON.stringify(this.worker.inputs, null, 4),
					outputs: JSON.stringify(this.worker.outputs, null, 4)
				};
			} catch (err) {
				this.$toaster.error(`Error loading worker: ${err}`);
				this.$router.push({ name: 'worker.list' });
			}
			this.page.isLoading = false;
		},
		async onConfirmDeleteWorkerClick() {
			this.page.isSubmitting = true;
			try {
				await this.deleteWorker({ id: this.$route.params.id });
				this.$toaster.success(`Deleted worker: ${this.worker.name}`);
				this.$router.push({ name: 'worker.list' });
			} catch (err) {
				this.$toaster.error(`Error deleting worker: ${err}`);
			}
			this.page.isSubmitting = false;
		},
		async onUpdateWorkerClick() {
			this.page.isSubmitting = true;
			try {
				const updatedWorker = { ...this.editedWorker };
				if (updatedWorker.inputs) {
					updatedWorker.inputs = JSON.parse(updatedWorker.inputs);
				} else {
					updatedWorker.inputs = {};
				}
				if (updatedWorker.outputs) {
					updatedWorker.outputs = JSON.parse(updatedWorker.outputs);
				} else {
					updatedWorker.outputs = {};
				}

				if (_.isObject(updatedWorker.teamId) && updatedWorker.teamId._id) {
					updatedWorker.teamId = updatedWorker.teamId._id;
				}

				await this.updateWorker({ id: this.$route.params.id, data: updatedWorker });
				this.$toaster.success(`Updated worker: ${updatedWorker.name}`);
				this.fetch();
			} catch (err) {
				this.$toaster.error(`Error updating worker: ${err}`);
			}
			this.page.isSubmitting = false;
		},
		onDeleteAliasClick(alias) {
			this.page.aliasLastClicked = alias;
			this.openConfirmDeleteAliasModal();
		},
		onDeletePrivKeyClick(alias, privateKey) {
			this.page.aliasLastClicked = alias;
			this.page.aliasPrivKeyLastClicked = privateKey;
			this.openConfirmDeleteAliasPrivKeyModal();
		},
		onPrivKeyMoreInfoClick(alias, privateKey) {
			this.page.aliasLastClicked = alias;
			this.page.aliasPrivKeyLastClicked = privateKey;
			this.openAliasPrivKeyInfoModal();
		},
		async onConfirmDeleteAliasClick() {
			this.page.isSubmitting = true;
			try {
				const aliasName = this.page.aliasLastClicked.name;
				await this.deleteAlias({
					id: this.$route.params.id,
					aliasName
				});
				this.$toaster.success(`Removed alias: ${aliasName}`);
				this.fetch();
			} catch (err) {
				this.$toaster.error(`Error removing alias: ${err}`);
			}
			this.page.isSubmitting = false;
		},
		async onAddAliasPrivKeySubmit(newPrivKeyName) {
			this.page.isSubmitting = true;
			try {
				const aliasName = this.page.aliasLastClicked.name;
				this.page.aliasPrivKeyLastClicked = await this.generateAliasPrivKey({
					id: this.$route.params.id,
					aliasName, name: newPrivKeyName
				});
				this.openAliasPrivKeyInfoModal();
				this.$toaster.success(`Added Private Key to: ${aliasName}`);
				this.fetch();
			} catch (err) {
				this.$toaster.error(`Error adding private key to alias: ${err}`);
			}
			this.page.isSubmitting = false;
		},
		async onGeneratePrivKeyClick(alias) {
			this.page.aliasLastClicked = alias;
			this.page.isAddAliasPrivKeyModalVisible = true;
		},
		async onConfirmDeleteAliasPrivKeyClick() {
			this.page.isSubmitting = true;
			try {
				const privKeyId = this.page.aliasPrivKeyLastClicked.jwtId;
				const privKeyName = this.page.aliasPrivKeyLastClicked.name;
				const aliasName = this.page.aliasLastClicked.name;
				await this.deleteAliasPrivKey({
					id: this.$route.params.id,
					aliasName,
					privKeyId
				});
				this.$toaster.success(`Removed alias private key: ${privKeyName}`);
				this.fetch();
			} catch (err) {
				this.$toaster.error(`Error removing alias private key: ${err}`);
			}
			this.page.isSubmitting = false;
		},
		toggleShowAliases() {
			this.page.areAliasesVisible = !this.page.areAliasesVisible;
		},
		openStarterKitsModal() {
			this.page.isStarterKitsModalVisible = true;
		},
		hideStarterKitsModal() {
			this.page.isStarterKitsModalVisible = false;
		},
		openConfirmDeleteModal() {
			this.page.isConfirmDeleteModalVisible = true;
		},
		hideConfirmDeleteModal() {
			this.page.isConfirmDeleteModalVisible = false;
		},
		openAddAliasModal() {
			this.page.isAddAliasModalVisible = true;
		},
		hideAddAliasModal() {
			this.page.isAddAliasModalVisible = false;
		},
		hideAddAliasPrivKeyModal() {
			this.page.isAddAliasPrivKeyModalVisible = false;
		},
		openConfirmDeleteAliasModal() {
			this.page.isConfirmDeleteAliasModalVisible = true;
		},
		hideConfirmDeleteAliasModal() {
			this.page.isConfirmDeleteAliasModalVisible = false;
		},
		openConfirmDeleteAliasPrivKeyModal() {
			this.page.isConfirmDeleteAliasPrivKeyModal = true;
		},
		hideConfirmDeleteAliasPrivKeyModal() {
			this.page.isConfirmDeleteAliasPrivKeyModal = false;
		},
		openAliasPrivKeyInfoModal() {
			this.page.isAliasPrivKeyInfoModal = true;
		},
		hideAliasPrivKeyInfoModal() {
			this.page.isAliasPrivKeyInfoModal = false;
		},
		fromEpochToDate(value) {
			if (value) {
				const d = new Date(0);
				d.setUTCMilliseconds(value)
				return d.toISOString()
			}
			return '';
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				this.fetch();
			}
		}
	}
};
</script>

<style lang="scss">

.WorkerDetail {

	&__action {
		margin-left: 1rem;
	}

	&__content {
		padding: 1rem;
		display: flex;
		flex-direction: column;
	}

	&__row {
		display: flex;
		flex-direction: row;
		width: 100%;
		margin-bottom: 1rem;
	}

	&__col {
		display: flex;
		flex-direction: column;
		width: 50%;

		&:first-of-type { margin-right: 0.5rem; }
		&:last-of-type { margin-left: 0.5rem; }
	}

	&__aliasCollapse {
		width: 100%;
	}

	&__addAlias {
		margin-top: 1rem;
	}
}
.Worker {

	&__alias{

		&__clipboard{
			float: right;
			margin-left: 1rem;
			clear: both;
		}

		&__privkey{
			margin-right: 1rem;
		}
	}

	&__code {
		.CodeMirror {
			height: auto;
		}
	}

}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"AppTasksSummary"},[_c('div',{staticClass:"AppTasksSummary__actions"},[_c('b-button',{attrs:{"variant":"primary"},domProps:{"textContent":_vm._s('Refresh')},on:{"click":_vm.fetch}}),_c('div',{staticClass:"AppTasksSummary__filter"},[_c('label',{staticClass:"AppTasksSummary__label",domProps:{"textContent":_vm._s('Period:')}}),_c('b-form-select',{attrs:{"options":_vm.filters.dateRangeOptions},on:{"input":_vm.updateQuery},model:{value:(_vm.filters.dateRange),callback:function ($$v) {_vm.$set(_vm.filters, "dateRange", $$v)},expression:"filters.dateRange"}})],1)],1),(_vm.page.isLoading)?_c('loader',{staticClass:"App__loading"}):_vm._e(),(!_vm.page.isLoading)?_c('b-tabs',{on:{"input":_vm.updateQuery},model:{value:(_vm.page.tabIndex),callback:function ($$v) {_vm.$set(_vm.page, "tabIndex", $$v)},expression:"page.tabIndex"}},[_c('b-tab',[_c('template',{slot:"title"},[_c('span',{staticClass:"AppTasksSummary__tabTitle"},[_vm._v("Running")]),_c('b-badge',{attrs:{"variant":"primary"},domProps:{"textContent":_vm._s(_vm.tasks.running.length)}})],1),(!_vm.page.isLoading && _vm.tasks.running.length >= 1)?_c('datatable',{attrs:{"items":_vm.tasks.running,"fields":_vm.fields.running},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('router-link',{attrs:{"to":{
							name: 'app.detail.workqueue',
							params: {
								id: _vm.$route.params.id,
								workqueue: data.item.workQueueId
							}
						}},domProps:{"textContent":_vm._s(data.item.name)}})]}},{key:"cell(workqueue)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.workQueueDescription)+" ")]}},{key:"cell(started)",fn:function(data){return [_c('time-span',{attrs:{"time":data.item.started}})]}}],null,false,865127668)}):_vm._e(),(!_vm.page.isLoading && _vm.tasks.running.length === 0)?_c('p',{staticClass:"AppTasksSummary__emptyListMessage",domProps:{"textContent":_vm._s('There are no results to show.')}}):_vm._e()],2),_c('b-tab',[_c('template',{slot:"title"},[_c('span',{staticClass:"AppTasksSummary__tabTitle"},[_vm._v("Queued")]),_c('b-badge',{attrs:{"variant":"primary"},domProps:{"textContent":_vm._s(_vm.tasks.queued.length)}})],1),(!_vm.page.isLoading && _vm.tasks.queued.length >= 1)?_c('datatable',{attrs:{"items":_vm.tasks.queued,"fields":_vm.fields.queued},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('router-link',{attrs:{"to":{
							name: 'app.detail.workqueue',
							params: {
								id: _vm.$route.params.id,
								workqueue: data.item.workQueueId
							}
						}},domProps:{"textContent":_vm._s(data.item.name)}})]}},{key:"cell(workqueue)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.workQueueDescription)+" ")]}},{key:"cell(queued)",fn:function(data){return [_c('time-span',{attrs:{"time":data.item.queued}})]}}],null,false,3827411924)}):_vm._e(),(!_vm.page.isLoading && _vm.tasks.queued.length === 0)?_c('p',{staticClass:"AppTasksSummary__emptyListMessage",domProps:{"textContent":_vm._s('There are no results to show.')}}):_vm._e()],2),_c('b-tab',[_c('template',{slot:"title"},[_c('span',{staticClass:"AppTasksSummary__tabTitle"},[_vm._v("Failed")]),_c('b-badge',{attrs:{"variant":"danger"},domProps:{"textContent":_vm._s(_vm.tasks.failed.length)}})],1),(!_vm.page.isLoading && _vm.tasks.failed.length > 0)?_c('datatable',{attrs:{"items":_vm.tasks.failed,"fields":_vm.fields.failed},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('router-link',{attrs:{"to":{
							name: 'app.detail.workqueue',
							params: {
								id: _vm.$route.params.id,
								workqueue: data.item.workQueueId
							}
						}},domProps:{"textContent":_vm._s(data.item.name)}})]}},{key:"cell(when)",fn:function(data){return [_c('time-span',{attrs:{"time":data.item.started}})]}},{key:"cell(workqueue)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.workQueueDescription)+" ")]}},{key:"cell(error)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.reason)+" ")]}},{key:"cell(retry)",fn:function(data){return [_c('b-button',{attrs:{"variant":"warning","size":"sm","disabled":data.item.isSubmitting},domProps:{"textContent":_vm._s('Restart Task')},on:{"click":function($event){$event.stopPropagation();return _vm.onTaskRestartClick(data.item)}}})]}}],null,false,1606526922)}):_vm._e(),(!_vm.page.isLoading && _vm.tasks.failed.length === 0)?_c('p',{staticClass:"AppTasksSummary__emptyListMessage",domProps:{"textContent":_vm._s('There are no results to show.')}}):_vm._e()],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<b-modal
		:visible="visible"
		:title="plan.name"
		ok-title="Run"
		body-class="RunPlanModal"
		@ok-disabled="page.isSubmitting || page.isLoading"
		@ok="onSubmit"
		@hidden="$emit('hidden')">

		<loader class="App__loading" v-if="page.isLoading || page.isSubmitting"/>

		<div v-if="!page.isLoading && !page.isSubmitting">

			<section v-if="planOptions">

				<div v-for="(key) in planOptions" :key="key">
					<b-form-group
						:label="key"
						:label-for="`RunPlanModal__${key}`">
						<b-form-input
							:id="`RunPlanModal__${key}`"
							v-model="plan.initialState[key]"/>
					</b-form-group>
				</div>

			</section>

			<b-form-group
				v-if="!appId"
				label="Select an Application:"
				for="RunPlanModal__appSelector">
				<b-form-select
					id="RunPlanModal__appSelector"
					v-model="selectedAppId"
					:options="appOptions"/>
			</b-form-group>

		</div>


	</b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
	props: {
		appId: {
			type: String
		},
		plan: {
			type: Object,
			required: true
		},
		visible: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			page: {
				isLoading: false,
				isSubmitting: false
			},
			selectedAppId: null
		};
	},
	computed: {
		...mapGetters({
			appsData: 'app/apps'
		}),
		apps() {
			return this.appsData.data;
		},
		appOptions() {
			if (!this.apps) return [];
			return this.apps.map(a => ({ value: a._id, text: a.name }));
		},
		planOptions() {
			if (!this.plan.initialState) return [];
			return Object.keys(this.plan.initialState);
		}
	},
	methods: {
		...mapActions({
			startWorkqueue: 'workqueue/startWorkqueue',
			findApps: 'app/find'
		}),
		async fetch() {
			this.page.isLoading = true;
			try {
				await this.findApps({
					query: {
						page: 1,
						pagesize: 999
					}
				});
			} catch (err) {
				this.$toaster.error(`Couldn't load application list... ${err}`);
			}
			this.page.isLoading = false;
		},
		async onSubmit(event) {
			event.preventDefault();
			this.page.isSubmitting = true;
			const appId = this.appId || this.selectedAppId;
			if (!appId) {
				this.$toaster.error('Select an application');
				return;
			}
			try {
				const workqueue = await this.startWorkqueue({
					appId,
					data: {
						initialState: this.plan.initialState,
						plan: this.plan.name
					}
				});
				this.$toaster.success(`Plan: [${this.plan.name}] started...`);
				this.$emit('submit', workqueue);
				this.$emit('hidden');
			} catch (err) {
				this.$toaster.error(`Couldn't start plan: ${this.plan.name}... ${err}`);
			}
			this.page.isSubmitting = false;
		}
	},
	watch: {
		visible: {
			immediate: true,
			handler: 'fetch'
		}
	}
};
</script>

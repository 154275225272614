<template>
	<b-modal
		:visible="visible"
		title="New Alias Private Key"
		ok-title="Generate"
		@ok="$emit('ok', newAliasPrivKeyName)"
		@hidden="$emit('hidden')">

		<form @submit.prevent="onSubmit">
			<b-form-group
				label="Private Key Name"
				label-for="AddAliasPrivKey__name">
				<b-form-input
					id="AddAliasPrivKey__name"
					v-model="newAliasPrivKeyName"/>
			</b-form-group>
		</form>

	</b-modal>
</template>

<script>
export default {
	props: {
		visible: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			newAliasPrivKeyName: null
		};
	},
	watch: {
		visible() {
			this.newAliasPrivKeyName = null;
		}
	}
};
</script>

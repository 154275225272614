<template>
	<b-modal
		:visible="visible"
		:title="title"
		:ok-title="submitTitle"
		:ok-disabled="isSubmitDisabled"
		@ok="onSubmitClick"
		@hidden="$emit('hidden')">

		<loader class="App__loading" v-if="isSubmitting"/>

		<template v-else>

			<b-form-group
				label="Name"
				label-for="EditAppModal__name">
				<b-form-input
					id="EditAppModal__name"
					v-model.trim="name"/>
			</b-form-group>

			<b-form-group
				description="Enter a description for your app."
				label="Description"
				label-for="EditAppModal__description">
				<b-form-input
					id="EditAppModal__description"
					v-model.trim="description"/>
			</b-form-group>

		</template>

	</b-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	props: {
		visible: {
			type: Boolean,
			required: true
		},
		app: {
			type: Object
		},
		create: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isSubmitting: false,
			name: null,
			description: null
		};
	},
	computed: {
		title() {
			if (this.create) return 'New App';
			return 'Edit App';
		},
		submitTitle() {
			if (this.create) return 'Create';
			return 'Update';
		},
		isSubmitDisabled() {
			const isValid = this.name && this.description;
			return !isValid || this.isSubmitting;
		}
	},
	methods: {
		...mapActions({
			createApp: 'app/create',
			updateApp: 'app/update'
		}),
		async onSubmitClick(event) {
			event.preventDefault();
			this.isSubmitting = true;
			try {
				let updatedApp;
				if (this.create) {
					updatedApp = await this.createApp({
						name: this.name,
						description: this.description
					});
					this.$toaster.success(`Created App: ${this.name}`);
				} else {
					updatedApp = await this.updateApp({
						id: this.$route.params.id,
						data: {
							...this.app,
							name: this.name,
							description: this.description
						}
					});
					this.$toaster.success(`Updated App: ${this.name}`);
				}
				this.$emit('submitted', updatedApp);
				this.$emit('hidden');
			} catch (err) {
				this.$toaster.error(`Error creating app: ${err}`);
			}
			this.isSubmitting = false;
		}
	},
	watch: {
		app: {
			immediate: true,
			handler() {
				if (this.create) {
					this.name = null;
					this.description = null;
				} else {
					this.name = this.app.name;
					this.description = this.app.description;
				}

			}
		}
	}
};
</script>

<template>
	<b-modal
		class="StarterKits"
		:visible="visible"
		size="lg"
		title="Starter Kits"
		ok-only
		ok-title="Close"
		@hidden="$emit('hidden')">
		<b-tabs v-model="page.tabIndex">

			<b-tab title="NodeJS" no-body>
				<codemirror
					class="StarterKits__code"
					v-if="page.showEditors && page.tabIndex === 0"
					v-model="workerStarterKitNode"
					:options="readOnlyEditorOptions"/>
			</b-tab>

			<b-tab title="C# .NET" no-body>
				<codemirror
					class="StarterKits__code"
					v-if="page.showEditors && page.tabIndex === 1"
					v-model="workerStarterKitDotNet"
					:options="readOnlyEditorOptions"/>
			</b-tab>

		</b-tabs>
	</b-modal>
</template>

<script>
import codeEditorOptions from '../../../lib/codeEditorOptions';
import { workerStarterKitNode, workerStarterKitDotNet } from '../../../lib/codeSnippets';

export default {
	props: {
		visible: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			workerStarterKitNode,
			workerStarterKitDotNet,
			page: {
				tabIndex: 0,
				showEditors: false
			}
		};
	},
	computed: {
		readOnlyEditorOptions() {
			return {
				...codeEditorOptions,
				readOnly: true
			};
		}
	},
	watch: {
		async visible() {
			if (this.visible) {
				await this.$nextTick();
				this.page.showEditors = true;
			} else {
				this.page.showEditors = false;
			}
		}
	}
};
</script>

<style lang="scss">

.StarterKits {

	.modal-dialog {
		min-width: 80vw;
	}

	&__code {
		.CodeMirror {
			height: auto;
		}
	}
}

</style>

<template>
	<DefaultLayout>

		<ContentHeader
			:title="team.name"
			:breadcrumbs="breadcrumbs">

			<b-button
				class="TeamDetail__action"
				variant="warning"
				v-text="'Edit Team'"
				@click="openEditTeamModal"/>

			<b-button
				class="TeamDetail__action"
				variant="primary"
				v-text="'Add Team Member'"
				@click="openInviteMemberModal"/>

		</ContentHeader>

		<section class="TeamDetail__content">

			<loader class="App__loading" v-if="page.isLoading || page.isSubmitting"/>

			<b-table
				v-if="!page.isLoading && !page.isSubmitting"
				:items="members"
				:fields="memberFields">

				<template v-slot:cell(action)="data">
					<b-button
						variant="danger"
						size="sm"
						v-text="'Delete'"
						@click.prevent="onDeleteMemberClick(data.item)"/>
				</template>

			</b-table>

		</section>

		<edit-team-modal
			:visible="page.isEditTeamModalVisible"
			:team="editedTeam"
			@submit="fetch"
			@hidden="hideEditTeamModal"/>

		<new-member-modal
			:visible="page.isInviteMemberModalVisible"
			@submit="fetch"
			@hidden="hideInviteMemberModal"/>

		<confirm-modal
			:visible="page.isConfirmDeleteModalVisible"
			:danger="true"
			title="Delete Member"
			text="Are you sure you want to delete this member?"
			@ok="onConfirmDeleteMember"
			@hidden="hideConfirmDeleteModal"/>

	</DefaultLayout>
</template>

<script type="text/javascript">
import { ContentHeader } from '@workflow-solutions/ofs-vue-layout';
import { mapGetters, mapActions } from 'vuex';
import DefaultLayout from '../../components/DefaultLayout';
import ConfirmModal from '../../components/ConfirmModal';
import EditTeamModal from './components/EditTeamModal';
import NewMemberModal from './components/NewMemberModal';

export default {
	components: {
		DefaultLayout,
		ContentHeader,
		EditTeamModal,
		NewMemberModal,
		ConfirmModal
	},
	data() {
		return {
			page: {
				isLoading: false,
				isSubmitting: false,
				isEditTeamModalVisible: false,
				isInviteMemberModalVisible: false,
				isConfirmDeleteModalVisible: false,
				memberLastClicked: null
			},
			editedTeam: {
				name: null
			},
			newMemberName: null,
			members: [],
			memberFields: [
				{ key: 'userId.email', label: 'Email' },
				'role',
				{
					key: 'action',
					label: ''
				}
			]
		};
	},
	computed: {
		...mapGetters({
			team: 'team/team'
		}),
		breadcrumbs() {
			return [
				{
					text: 'Teams',
					to: { name: 'team.list' }
				},
				{
					text: this.team.name,
					active: true
				}
			];
		}
	},
	methods: {
		...mapActions({
			fetchMembers: 'team/fetchMembers',
			createMember: 'team/createMember',
			deleteMember: 'team/deleteMember',
			getTeam: 'team/get'
		}),
		async fetch() {
			this.page.isLoading = true;
			try {
				const results = await Promise.all([
					this.getTeam({ id: this.$route.params.id }),
					this.fetchMembers({ id: this.$route.params.id })
				]);
				this.editedTeam = { ...this.team };
				[, this.members] = results;
			} catch (err) {
				this.$toaster.error(`Error loading team: ${err}`);
				this.$router.push({ name: 'team.list' });
			}
			this.page.isLoading = false;
		},
		onDeleteMemberClick(member) {
			this.page.memberLastClicked = member;
			this.openConfirmDeleteModal();
		},
		async onConfirmDeleteMember() {
			this.page.isSubmitting = true;
			try {
				await this.deleteMember({
					teamId: this.$route.params.id,
					memberId: this.page.memberLastClicked._id
				});
				this.$toaster.success(`Removed member: ${this.page.memberLastClicked.userId.email}`);
				this.fetch();
			} catch (err) {
				this.$toaster.error(`Error removing member: ${err}`);
			}
			this.page.isSubmitting = false;
		},
		openEditTeamModal() {
			this.page.isEditTeamModalVisible = true;
		},
		hideEditTeamModal() {
			this.page.isEditTeamModalVisible = false;
		},
		openInviteMemberModal() {
			this.page.isInviteMemberModalVisible = true;
		},
		hideInviteMemberModal() {
			this.page.isInviteMemberModalVisible = false;
		},
		openConfirmDeleteModal() {
			this.page.isConfirmDeleteModalVisible = true;
		},
		hideConfirmDeleteModal() {
			this.page.isConfirmDeleteModalVisible = false;
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				this.fetch();
			}
		}
	}
};
</script>

<style lang="scss">

.TeamDetail {

	&__action {
		margin-left: 1rem;
	}

	&__content {
		padding: 1rem;
	}
}

</style>

<template>
	<list-table
		class="DeploymentList__list"
		:items="deployments"
		:fields="fields"
		:config="listConfig">

		<template v-slot:cell(alias)="data">
			<span class="link" @click.stop>
				<router-link
					:to="{
						name: 'cluster.deployment.detail',
						params: {
							clusterId: clusterId,
							deploymentId: data.item._id  }
					}"
					v-text="data.item.alias"/>
			</span>
		</template>

		<template v-slot:cell(worker)="data">
			<span v-text="getWorkerName(data.item)"/>
		</template>
	</list-table>
</template>

<script type="text/javascript">
import { ListTable } from '@workflow-solutions/ofs-vue-layout';
import listConfig from '../../../lib/listConfig';

export default {
	components: {
		ListTable
	},
	props: {
		deployments: { type: Array, required: true },
		workers: { type: Array, required: true },
		clusterId: { type: String, required: true }
	},
	data() {
		return {
			listConfig,
			fields: [
				'alias',
				'worker',
				'dockerTag'
			]
		};
	},
	methods: {
		getWorkerName(deployment) {
			const match = this.workers.find(worker => worker._id === deployment.workerId);
			if (!match) return null;
			return match.name;
		}
	}
};
</script>

<style lang="scss">

.DeploymentList {
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	&__list {
		width: 100%;
	}
}

</style>

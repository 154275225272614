<template>
	<section class="AppCollaborators">

		<section class="AppCollaborators__actions">

			<b-button
				variant="primary"
				v-text="'Add Collaborator'"
				@click="showInviteModal"/>

		</section>

		<loader class="App__loading" v-if="page.isLoading"/>

		<b-table
			v-if="!page.isLoading"
			:items="collaborators"
			:fields="collaboratorFields">

			<template v-slot:cell(action)="data">
				<b-button
					variant="danger"
					size="sm"
					v-text="'Delete'"
					@click.prevent="onDeleteClick(data.item._id)"/>
			</template>

		</b-table>

		<new-collaborator-modal
			:visible="page.isInviteUserModalVisible"
			@submit="fetchCollaborators"
			@hidden="hideInviteModal"/>

		<confirm-modal
			:visible="page.isDeleteModalVisible"
			title="Delete Collaborator"
			:text="`Are you sure you want to remove ${collaboratorLastClickedEmail} as a collaborator?`"
			:danger="true"
			@ok="deleteCollaborator"
			@hidden="hideDeleteModal"/>

	</section>
</template>

<script>
import { mapActions } from 'vuex';
import ConfirmModal from '../../../components/ConfirmModal';
import NewCollaboratorModal from './NewCollaboratorModal';

export default {
	components: {
		ConfirmModal,
		NewCollaboratorModal
	},
	data() {
		return {
			page: {
				isLoading: false,
				isInviteUserModalVisible: false,
				isDeleteModalVisible: false
			},
			collaboratorFields: [
				{ key: 'userId.email', label: 'Email' },
				'role',
				{ key: 'action', label: 'Actions' }
			],
			collaboratorLastClickedId: null,
			collaborators: []
		};
	},
	computed: {
		collaboratorLastClickedEmail() {
			const collaboratorLastClicked = this.collaborators.find(
				collaborator => collaborator._id === this.collaboratorLastClickedId
			);
			if (!collaboratorLastClicked) return null;
			return collaboratorLastClicked.userId.email;
		}
	},
	methods: {
		...mapActions({
			appRemoveCollaborator: 'app/deleteCollaborator',
			getCollaborators: 'app/getCollaborators'
		}),
		async fetchCollaborators() {
			this.page.isLoading = true;
			try {
				this.collaborators = await this.getCollaborators({ id: this.$route.params.id });
			} catch (err) {
				this.$toaster.error(`Error loading collaborators: ${err}`);
			}
			this.page.isLoading = false;
		},
		showInviteModal() {
			this.page.isInviteUserModalVisible = true;
		},
		hideInviteModal() {
			this.page.isInviteUserModalVisible = false;
		},
		onDeleteClick(collaboratorId) {
			this.collaboratorLastClickedId = collaboratorId;
			this.showDeleteModal();
		},
		showDeleteModal() {
			this.page.isDeleteModalVisible = true;
		},
		hideDeleteModal() {
			this.page.isDeleteModalVisible = false;
		},
		async deleteCollaborator() {
			this.page.isLoading = true;
			try {
				await this.appRemoveCollaborator({
					appId: this.$route.params.id,
					collaboratorId: this.collaboratorLastClickedId
				});
				this.$toaster.success(`Removed collaborator: ${this.collaboratorLastClickedEmail}`);
				this.page.isLoading = false;
				this.fetchCollaborators();
			} catch (err) {
				this.$toaster.error(`Error removing collaborator: ${err}`);
				this.page.isLoading = false;
			}
		}
	},
	mounted() {
		this.fetchCollaborators();
	}
};
</script>

<style lang="scss">

.AppCollaborators {
	padding: 1rem;

	&__actions {
		margin-bottom: 1rem;
	}
}
</style>

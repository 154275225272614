<template>
	<ul class="CardList">
		<router-link
			v-for="item in items"
			:key="item.id"
			:to="item.path"
			class="CardList__item">
			<li>
				<h3 class="CardList__title" v-text="item.title"/>
				<span class="CardList__text" v-text="item.text"/>
			</li>
		</router-link>
	</ul>
</template>

<script>
/**
 * List Component to display an array of data as cards
 * Array items must follow the schema:
 * 	{
 * 		title: {string}	// Card title text
 * 		text: {string}	// Card text content
 * 		path: {Object}	// Router path object for the card to navigate through to
 * 		id: {string}	// Unique id for this card item
 * 	}
 */

export default {
	props: {
		items: {
			type: Array,
			required: true
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/src/styles/core';

.CardList {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
	flex-wrap: wrap;

	&__item {
		background: $light;
		min-width: calc(25% - 1rem);
		max-width: calc(25% - 1rem);
		margin: 0.5rem;
		padding: 1rem;
		box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		flex-grow: 1;
		color: $dark;
		border-radius: 0.3rem;
		cursor: pointer;
		transition: all .15s linear;

		&:hover {
			color: $dark;
			text-decoration: none;
			box-shadow: -1px 5px 19px 0px rgba(0,0,0,0.1);
		}
	}

	&__title {
		font-size: 1rem;
	}

	&__text {
		color: lighten($dark, 15%);
		margin-bottom: 0;
	}
}
</style>

<template>
	<DefaultLayout>

		<ContentHeader title="Plans"/>

		<section class="PlanList">

			<loader class="App__loading" v-if="page.isLoading"/>

			<list-table
				v-if="!page.isLoading"
				class="PlanList__listTable"
				:items="plans"
				:fields="planFields"
				:config="listConfig"
				:totalItems="pagination.total"
				:perPage="pagination.perPage"
				:currentPage="pagination.currentPage"
				:fetchData="fetchPlans"
				@row-clicked="goToPlan"
				@table-change="handleTableChange"
				hover
				striped>

				<template v-slot:cell(name)="data">
					<span class="link" @click.stop>
						<router-link
							:to="{
								name: 'plan.detail',
								params: { id: data.item._id }
							}"
							v-text="data.item.name"/>
					</span>
				</template>

			</list-table>
		</section>


	</DefaultLayout>
</template>

<script type="text/javascript">
import { ListTable, ContentHeader } from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import listConfig from '../../lib/listConfig';
import DefaultLayout from '../../components/DefaultLayout';


export default {
	components: {
		DefaultLayout,
		ContentHeader,
		ListTable
	},
	data() {
		return {
			listConfig,
			page: {
				isLoading: false
			},
			pagination: {
				currentPage: 1,
				total: 0,
				perPage: 10
			},
			planFields: [
				'name',
				'description'
			]
		};
	},
	computed: {
		...mapGetters({
			planData: 'plan/plans'
		}),
		plans() {
			return this.planData.data;
		}
	},
	methods: {
		...mapActions({
			findplans: 'plan/find'
		}),
		async fetchPlans() {
			this.page.isLoading = true;
			try {
				await this.findplans({
					query: {
						page: parseInt(this.$route.query.page, 10),
						pagesize: parseInt(this.$route.query.perPage, 10)
					}
				});
				this.pagination.total = this.planData.total;
			} catch (err) {
				this.$toaster.error(`Error loading plans: ${err}`);
			}
			this.page.isLoading = false;
		},
		goToPlan(item) {
			this.$router.push({ name: 'plan.detail', params: { id: item._id } });
		},
		handleTableChange({ currentPage, perPage }) {
			this.pagination.currentPage = currentPage;
			this.pagination.perPage = perPage;
			this.updateQuery();
		},
		updateQuery() {
			this.$router.replace({
				name: 'plan.list',
				query: {
					...this.$route.query,
					page: this.pagination.currentPage,
					perPage: this.pagination.perPage
				}
			}).catch(e => {
				if (e.name !== 'NavigationDuplicated') throw e;
			});
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				if (this.$route.query.page) this.pagination.currentPage = parseInt(this.$route.query.page, 10);
				if (this.$route.query.perPage) this.pagination.perPage = parseInt(this.$route.query.perPage, 10);
				this.updateQuery();
				this.fetchPlans();
			}
		}
	}
};
</script>

<style lang="scss">

.PlanList {
	padding: 1rem;

	&__listTable {
		margin-top: -28px;
	}
}
</style>

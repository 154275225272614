import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faCode,
	faExclamationTriangle,
	faPlay
} from '@fortawesome/free-solid-svg-icons';

library.add(faCode);
library.add(faExclamationTriangle);
library.add(faPlay);

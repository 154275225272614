<template>
	<DefaultLayout class="ClusterDetail">
		<ContentHeader
			:title="cluster.name"
			:breadcrumbs="breadcrumbs">

			<b-button
				class="ClusterDetail__action"
				variant="danger"
				v-text="'Delete Cluster'"
				:disabled="page.isSubmitting"
				@click="onDeleteClusterClick"/>

			<b-button
				class="ClusterDetail__action"
				variant="primary"
				v-text="'Update Cluster'"
				:disabled="!isValidCluster || page.isSubmitting"
				@click="onUpdateClusterClick"/>
		</ContentHeader>

		<section class="ClusterDetail__content">
			<loader class="App__loading" v-if="page.isLoading || page.isSubmitting"/>

			<template v-if="!page.isLoading && !page.isSubmitting">

				<b-form-group label="Name" label-for="ClusterDetail__name">
					<b-form-input
						id="ClusterDetail__name"
						v-model="editedCluster.name"/>
				</b-form-group>

				<b-form-group label="Team" label-for="ClusterDetail__team">
					<b-form-select
						id="ClusterDetail__team"
						v-model="editedCluster.teamId"
						:options="teams"
						text-field="name"
						value-field="_id"/>
				</b-form-group>

				<b-form-group label="Tags" label-for="ClusterDetail__tags">
					<b-form-tags
						id="ClusterDetail__tags"
						v-model="editedCluster.tags"
						tag-variant="primary"
						tag-pills
						size="lg"/>
				</b-form-group>

				<div class="ClusterDetail__configuration">

					<h3>Configuration:</h3>
					<div class="ClusterDetail__configuration__header">
						<b-button
							variant="primary"
							size="sm"
							v-text="'New Deployment'"
							:to="{ name: 'cluster.deployment.detail', params: { clusterId: cluster._id, deploymentId: 'new' } }"/>
					</div>

					<deployment-list
						v-if="!page.advancedView"
						:deployments="deployments"
						:workers="workers"
						:clusterId="this.$route.params.id"/>

				</div>
			</template>
		</section>

		<confirm-modal
			:visible="page.isConfirmDeleteClusterModalVisible"
			title="Delete Cluster"
			text="Are you sure you want to delete this cluster?"
			:danger="true"
			@ok="onConfirmDeleteClusterClick"
			@hidden="hideConfirmDeleteClusterModal"/>
	</DefaultLayout>
</template>

<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';
import { ContentHeader } from '@workflow-solutions/ofs-vue-layout';
import validWorkers from './validWorkers';
import DefaultLayout from '../../components/DefaultLayout';
import ConfirmModal from '../../components/ConfirmModal';
import DeploymentList from './components/DeploymentList';

export default {
	components: {
		DefaultLayout,
		ContentHeader,
		ConfirmModal,
		DeploymentList
	},
	data() {
		return {
			page: {
				isLoading: false,
				isSubmitting: false,
				isConfirmDeleteClusterModalVisible: false
			},
			editedCluster: {
				name: null
			},
			workers: []
		};
	},
	computed: {
		...mapGetters({
			cluster: 'cluster/cluster',
			deploymentsData: 'deployment/deployments',
			teamsData: 'team/teams'
		}),
		deployments() {
			if (!this.deploymentsData) return [];
			return this.deploymentsData.data;
		},
		teams() {
			if (!this.teamsData) return [];
			return this.teamsData.data;
		},
		breadcrumbs() {
			return [
				{ text: 'All Clusters', to: { name: 'cluster.list' } },
				{ text: this.cluster.name, active: true }
			];
		},
		isValidCluster() {
			return (this.editedCluster.name);
		}
	},
	methods: {
		...mapActions({
			getCluster: 'cluster/get',
			deleteCluster: 'cluster/deleteById',
			updateCluster: 'cluster/update',
			getDeployments: 'deployment/find',
			findWorkers: 'worker/find',
			findTeams: 'team/find'
		}),

		async fetch() {
			this.page.isLoading = true;
			try {
				const clusterId = this.$route.params.id;
				await Promise.all([
					this.getCluster({ id: clusterId }),
					this.getDeployments({ query: { page: 1, pagesize: 999, clusterId } }),
					this.findTeams({ query: { page: 1, pagesize: 9999 } }),
					this.fetchWorkers()
				]);
				this.editedCluster = { ...this.cluster };
			} catch (err) {
				this.$toaster.error(`Error loading cluster: ${err}`);
				this.$router.push({ name: 'cluster.list' });
			}
			this.page.isLoading = false;
		},
		async fetchWorkers() {
			const result = await this.findWorkers({
				query: {
					page: 1,
					pagesize: 999
				}
			});
			this.workers = result.data.filter(worker => validWorkers.includes(worker.name.toLowerCase()));
		},
		onDeleteClusterClick() {
			this.page.isConfirmDeleteClusterModalVisible = true;
		},
		async onConfirmDeleteClusterClick() {
			this.page.isSubmitting = true;
			try {
				await this.deleteCluster({ id: this.$route.params.id });
				this.$toaster.success(`Deleted cluster: ${this.cluster.name}`);
				this.$router.push({ name: 'cluster.list' });
			} catch (err) {
				this.$toaster.error(`Error deleting cluster: ${err}`);
			}
			this.page.isSubmitting = false;
		},
		hideConfirmDeleteClusterModal() {
			this.page.isConfirmDeleteClusterModalVisible = false;
		},
		async onUpdateClusterClick() {
			this.page.isSubmitting = true;
			try {
				await this.updateCluster({ id: this.$route.params.id, data: this.editedCluster });
				this.$toaster.success(`Cluster updated: ${this.editedCluster.name}`);
				this.fetch();
			} catch (err) {
				this.$toaster.error(`Error updating cluster: ${err}`);
			}
			this.page.isSubmitting = false;
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				this.fetch();
			}
		}
	}
};
</script>

<style lang="scss">

.ClusterDetail {

	&__action {
		margin-left: 1rem;
	}

	&__content {
		padding: 1rem;
		display: flex;
		flex-direction: column;
	}

	&__configuration {
		margin: 2rem 0;

		&__header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			&__toggle {
				display: flex;
				flex-direction: row;

				label {
					margin: 0 1rem 0 0;
					padding: 0;
				}
			}

		}

		&__action {
			margin-right: 1rem;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}
}
</style>

<template>
	<DefaultLayout>

		<ContentHeader title="Teams">

			<b-button
				variant="primary"
				v-text="'New Team'"
				@click="openNewTeamModal"/>

		</ContentHeader>

		<loader class="App__loading" v-if="page.isLoading"/>

		<card-list
			v-if="!page.isLoading"
			class="TeamList"
			:items="teamItems"/>

		<edit-team-modal
			:visible="page.isNewTeamModalVisible"
			:createMode="true"
			@submit="goToTeam"
			@hidden="hideNewTeamModal"/>

	</DefaultLayout>
</template>

<script type="text/javascript">
import { ContentHeader } from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import DefaultLayout from '../../components/DefaultLayout';
import CardList from '../../components/CardList';
import EditTeamModal from './components/EditTeamModal';

export default {
	components: {
		DefaultLayout,
		ContentHeader,
		CardList,
		EditTeamModal
	},
	data() {
		return {
			page: {
				isLoading: false,
				isNewTeamModalVisible: false
			}
		};
	},
	computed: {
		...mapGetters({
			teamsData: 'team/teams'
		}),
		teams() {
			return this.teamsData.data;
		},
		teamItems() {
			if (!this.teams) return [];
			return this.teams.map(team => {
				let text;
				if (team.members.length === 1) {
					text = `${team.members.length} Member`;
				} else {
					text = `${team.members.length} Members`;
				}
				return {
					title: team.name,
					text,
					path: { name: 'team.detail', params: { id: team._id } },
					id: team._id
				};
			});
		}
	},
	methods: {
		...mapActions({
			fetchTeams: 'team/find'
		}),
		async fetch() {
			this.page.isLoading = true;
			try {
				await this.fetchTeams({ query: { page: 1, pagesize: 9999 } });
			} catch (err) {
				this.$toaster.error(`Error loading teams: ${err}`);
			}
			this.page.isLoading = false;
		},
		openNewTeamModal() {
			this.page.isNewTeamModalVisible = true;
		},
		hideNewTeamModal() {
			this.page.isNewTeamModalVisible = false;
		},
		goToTeam(team) {
			this.$router.push({ name: 'team.detail', params: { id: team._id } });
		}
	},
	created() {
		this.fetch();
	}
};
</script>

<style lang="scss">

.TeamList {
	padding: 1rem;
}

</style>

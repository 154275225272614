import Vue from 'vue';
import VueResource from 'vue-resource';
import OfsCrud from '@workflow-solutions/ofs-vuex-crud';
import * as localStorage from 'store2';

import httpOfBaseApiTransportInstance from './httpOfBaseApiTransportInstance';
import WorkforceTransport from '../lib/WorkforceTransport';

import registerAccountModule from './account';
import registerAppModule from './app';
import registerWorkqueueModule from './workqueue';
import registerTaskModule from './tasks';
import registerplanModule from './plan';
import registerWorkerModule from './worker';
import registerScriptModule from './script';
import registerTeamModule from './team';
import registerClusterModule from './cluster';
import registerDeploymentModule from './deployment';

const setupModules = store => {
	Vue.use(VueResource);
	Vue.use(OfsCrud, { store });
	const { authBase } = window.$config;
	Vue.$ofsCrud.registerTransport({ name: 'ofBaseApi', fn: () => httpOfBaseApiTransportInstance });

	if (process.env.VUE_APP_LOCAL_MODE) {
		window.$config.apiBase = localStorage.get('test-apiBase') ? localStorage.get('test-apiBase') : window.$config.apiBase;
	}

	Vue.$ofsCrud.registerTransport({ name: 'workforce', fn: () => new WorkforceTransport(window.$config) });
	Vue.$ofsCrud.registerAuthModule({ authBase });

	registerAccountModule(store);
	registerAppModule(store);
	registerWorkqueueModule(store);
	registerTaskModule(store);
	registerplanModule(store);
	registerWorkerModule(store);
	registerScriptModule(store);
	registerTeamModule(store);
	registerClusterModule(store);
	registerDeploymentModule(store);
};

export default setupModules;

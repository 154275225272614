<template>
	<b-modal
		:visible="visible"
		title="New Collaborator"
		ok-title="Add"
		:ok-disabled="!isSubmitDisabled"
		@ok="onSumbit"
		@hidden="$emit('hidden')">

		<form @submit.stop.prevent="onSumbit">
			<b-form-group
				label="Email"
				label-for="NewCollaborator__email">
				<b-form-input
					id="NewCollaborator__email"
					:disabled="page.isSubmitting"
					v-model.trim="newCollaboratorEmail" />
			</b-form-group>
		</form>

	</b-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	props: {
		visible: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			page: {
				isSubmitting: false
			},
			newCollaboratorEmail: null
		};
	},
	computed: {
		isValidCollaborator() {
			return this.newCollaboratorEmail;
		},
		isSubmitDisabled() {
			return this.isValidCollaborator && !this.page.isSubmitting;
		}
	},
	methods: {
		...mapActions({
			addCollaborator: 'app/addCollaborator',
			inviteCollaborator: 'app/inviteCollaborator'
		}),
		async onSumbit(event) {
			event.preventDefault();
			this.page.isSubmitting = true;
			try {
				await this.addCollaborator({
					id: this.$route.params.id,
					data: { email: this.newCollaboratorEmail }
				});
				this.$toaster.success(`Added collaborator: ${this.newCollaboratorEmail}`);
				this.$emit('submit');
				this.$emit('hidden');
			} catch (err) {
				try {
					if (err.response.status !== 404) throw err;
					await this.inviteCollaborator({
						id: this.$route.params.id,
						data: { email: this.newCollaboratorEmail, redirectUrl: window.location.origin }
					});
					this.$toaster.success(`Invited collaborator: ${this.memberName}`);
					this.$emit('submit');
					this.$emit('hidden');
				} catch (finalError) {
					this.$toaster.error(`Error inviting collaborator: ${finalError}`);
				}
			}
			this.page.isSubmitting = false;
		}
	},
	watch: {
		visible() {
			this.newCollaboratorEmail = null;
		}
	}
};
</script>

<template>
	<b-collapse class="DateIntervalPickerCollapse" v-model="open" @hide="$emit('hide')">
		<div class="DateIntervalPickerCollapse-body">
			<section class="DateIntervalPickerCollapse-pickers">
				<b-form-group label="Created">
					From
					<flat-pickr class="form-control" v-model="localFrom" :config="config" />
					To
					<flat-pickr class="form-control" v-model="localTo" :config="config" />
				</b-form-group>
			</section>

			<section class="DateIntervalPickerCollapse-presets">
				<label>Presets</label>
				<b-button
					v-for="preset in presets"
					size="sm"
					@click="handlePresetClick(preset.name)"
					:variant="selectedPreset === preset.name ? 'primary' : ''"
					:key="preset.name"
					v-text="preset.label"
				/>
			</section>

			<hr />

			<div class="DateIntervalPickerCollapse-footer">
				<div>
					<b-button size="sm" @click="handleResetClick">Reset</b-button>
				</div>
				<div>
					<b-button size="sm" @click="handleCancelClick">Cancel</b-button>
					<b-button variant="primary" size="sm" @click="handleApplyClick">Apply</b-button>
				</div>
			</div>
		</div>
	</b-collapse>
</template>

<script>
import moment from 'moment-timezone';
import FlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
	components: {
		FlatPickr
	},
	props: {
		from: { type: String },
		to: { type: String },
		open: { type: Boolean, required: true }
	},
	data() {
		const presets = [
			{ name: 'lastHour', label: 'Last Hour' },
			{ name: 'last2Hours', label: 'Last 2 Hours' },
			{ name: 'today', label: 'Today' },
			{ name: 'yesterday', label: 'Yesterday' },
			{ name: 'currentWeek', label: 'Current Week' },
			{ name: 'lastWeek', label: 'Last Week' }
		];
		return {
			presets,
			selectedPreset: null,
			localFrom: this.from,
			localTo: this.to,
			config: {
				enableTime: true,
				defaultHour: 0,
				time_24hr: true,
				altFormat: this.dateFormat(),
				altInput: true,
				dateFormat: 'Z'
			}
		};
	},
	computed: {
		hasSelection() {
			return this.from || this.to;
		}
	},
	methods: {
		moment,
		handlePresetClick(preset) {
			const presetSwitch = {
				lastHour: () => [moment().add(-1, 'hour'), moment()],
				last2Hours: () => [moment().add(-2, 'hour'), moment()],
				today: () => [moment().startOf('day'), moment().endOf('day')],
				yesterday: () => [
					moment()
						.add(-1, 'day')
						.startOf('day'),
					moment()
						.add(-1, 'day')
						.endOf('day')
				],
				currentWeek: () => [moment().startOf('week'), moment().endOf('week')],
				lastWeek: () => [
					moment()
						.add(-1, 'week')
						.startOf('week'),
					moment()
						.add(-1, 'week')
						.endOf('week')
				]
			};

			const [from, to] = presetSwitch[preset]();
			this.localFrom = from.toISOString();
			this.localTo = to.toISOString();
			this.selectedPreset = preset;
		},
		handleApplyClick() {
			this.$emit('input', { from: this.localFrom, to: this.localTo });
			this.$emit('hide');
		},
		handleCancelClick() {
			this.localFrom = this.from;
			this.localTo = this.to;
			this.$emit('hide');
		},
		handleResetClick() {
			this.localFrom = null;
			this.localTo = null;
			this.selectedPreset = null;
		},
		onCollapseButtonClick() {
			this.isCollapseOpen = true;
		},
		dateFormat() {
			return `Y-m-d H:i ${this.escapeChars(this.timeZone())}`;
		},
		timeZone() {
			const timeZone = moment.tz.guess();
			const time = new Date();
			const timeZoneOffset = time.getTimezoneOffset();
			return moment.tz.zone(timeZone).abbr(timeZoneOffset);
		},
		escapeChars(text) {
			let result = '';
			for (let i = 0; i < text.length; ++i) {
				result += '\\';
				result += text.charAt(i);
			}
			return result;
		}
	}
};
</script>

<style lang="scss">
.DateIntervalPickerCollapse {
	&-body {
		padding: 0.5rem 1rem;
	}

	&-footer {
		display: flex;
		justify-content: space-between;
		button {
			margin-left: 0.5rem !important;
		}
	}

	&-pickers {
		margin-bottom: 1rem;
		input {
			width: 50%;
		}
	}

	&-presets {
		label {
			display: block;
			margin-bottom: 0.5rem;
		}
		button {
			margin-right: 0.5rem !important;
		}
	}

	.dropdown-menu {
		@media (min-width: 778px) {
			min-width: 680px;
		}
	}
	.flatpickr-am-pm {
		display: none;
	}
}
</style>

import appRoutes from '../containers/App';
import planRoutes from '../containers/Plan';
import workerRoutes from '../containers/Worker';
import scriptRoutes from '../containers/Script';
import Login from '../containers/Login';
import Teams from '../containers/Team';
import Dashboard from '../containers/Dashboard';
import Edge from '../containers/Edge';

const routes = [
	...appRoutes,
	...planRoutes,
	...workerRoutes,
	...scriptRoutes,
	...Teams,
	...Login,
	...Dashboard,
	...Edge,
	{
		path: '/',
		redirect: { name: 'app.list' }
	},
	{
		path: '*',
		redirect: '/'
	}
];

export default routes;

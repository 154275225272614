<template>
	<div id="app" class="App" :data-version="version">
		<router-view v-if="showRouter"/>

		<notification/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Notification } from '@workflow-solutions/ofs-vue-layout';
import _ from 'lodash';

export default {
	data: () => ({
		version: process.env.VERSION
	}),
	components: {
		Notification
	},
	computed: {
		...mapGetters({
			isAuthenticated: 'auth/isAuthenticated',
			isHpIdLogin: 'auth/isHpIdLogin'
		}),
		showRouter() {
			return this.isAuthenticated || this.$route.name === 'login';
		}
	},
	methods: {
		...mapActions('auth', ['loginHpId']),
		async checkAuthentication() {
			// HP SSO Authentication handling
			if (!this.isAuthenticated && this.tempToken) {
				try {
					await this.loginHpId(this.tempToken); // Exchange tempToken
					this.$router.push({ query: { token: null } }); // Remove tempToken from query
					const redirectTo = this.redirectTo || '/';
					this.$router.push(redirectTo);
				} catch (err) {
					this.$toaster.error(err);
				}
			}
			if (!this.isHpIdLogin && !this.isAuthenticated && this.$route.name !== 'login') {
				this.$router.push(
					{
						name: 'login',
						query: { redirectTo: this.$route.fullPath }
					}
				);
			}
		}
	},
	async beforeCreate() {
		this.tempToken = _.get(this, '$router.currentRoute.query.token');
		if (Array.isArray(this.tempToken)) [this.tempToken] = this.tempToken;
	},
	watch: {
		isAuthenticated: {
			immediate: true,
			handler: 'checkAuthentication'
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';

.App {

	&__loading {
		display: block;
		margin: 3rem auto;
	}

	// default to not use bold fonts for titles
	h1, h2, h3, h4, h5 {
		font-weight: 500;
	}

	// mimic ofs-vue-layout original content header styles
	.ContentHeader {
		background: #edeff3;

		&-title {
			font-weight: 100;
			font-size: 25px;
		}

	}

	// we have to overwrite the default margins on this component
	.OfsPanel {
		margin: 0;
	}

	// emulates the look of the original ofs-panel titles
	// used across sub components of the App section
	&__panelTitle {
		font-size: 1.2rem;
		line-height: 1.6rem;
		margin: 0;
		flex: 1;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-bottom: 1.5rem;
		display: flex;
		justify-content: space-between;
	}

	// override status colors from ofs-vue-layout defaults to suit Workforce
	.OfsBadge {
		&--notstarted,
		&--cancelled {
			background-color: $of-color-status-complete;
		}

		&--ended,
		&--completed {
			background-color: $of-color-status-ready;
		}

		&--queued,
		&--retry {
			background-color: $of-color-status-pending;
		}
		&--skipped {
			background-color: $of-color-grey-2
		}
	}

}

</style>

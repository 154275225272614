import { render, staticRenderFns } from "./StarterKitsModal.vue?vue&type=template&id=6cd72bc0"
import script from "./StarterKitsModal.vue?vue&type=script&lang=js"
export * from "./StarterKitsModal.vue?vue&type=script&lang=js"
import style0 from "./StarterKitsModal.vue?vue&type=style&index=0&id=6cd72bc0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
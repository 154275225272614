<template>
	<DefaultLayout>

		<ContentHeader title="Scripts">
			<b-button
				variant="primary"
				v-text="'New Script'"
				@click="openNewScriptModal"/>
		</ContentHeader>

		<section class="ScriptList">

			<loader class="App__loading" v-if="page.isLoading || page.isLoadingTeams"/>

			<list-table
				v-if="!page.isLoading && !page.isLoadingTeams"
				class="ScriptList__listTable"
				:items="scripts"
				:fields="scriptFields"
				:config="listConfig"
				:totalItems="pagination.total"
				:perPage="pagination.perPage"
				:currentPage="pagination.currentPage"
				:fetchData="fetch"
				@row-clicked="goToScript"
				@table-change="handleTableChange"
				striped
				hover>

				<template v-slot:cell(name)="data">
					<span  class="link" @click.stop>
						<router-link
							:to="{
								name: 'script.detail',
								params: { id: data.item._id }
							}"
							v-text="data.item.name"/>
					</span>
				</template>

			</list-table>
		</section>

		<new-script-modal
			:visible="page.isNewScriptModalVisible"
			:teamOptions="teamOptions"
			@submit="goToScript"
			@hidden="hideNewScriptModal"/>

	</DefaultLayout>
</template>

<script type="text/javascript">
import { ListTable, ContentHeader } from '@workflow-solutions/ofs-vue-layout';
import { mapGetters, mapActions } from 'vuex';
import listConfig from '../../lib/listConfig';
import DefaultLayout from '../../components/DefaultLayout';
import NewScriptModal from './components/NewScriptModal';

export default {
	components: {
		DefaultLayout,
		ListTable,
		ContentHeader,
		NewScriptModal
	},
	data() {
		return {
			listConfig,
			page: {
				isLoading: false,
				isLoadingTeams: false,
				isNewScriptModalVisible: false
			},
			pagination: {
				currentPage: 1,
				perPage: 10,
				total: 0
			},
			scriptFields: [
				'name',
				'team',
				'description'
			],
			newScriptName: '',
			newScriptTeam: ''
		};
	},
	computed: {
		...mapGetters({
			teamsData: 'team/teams',
			scriptsData: 'script/scripts'
		}),
		teamOptions() {
			if (!this.teamsData.data) return [];
			return this.teamsData.data.map(item => ({
				text: item.name,
				value: item._id
			}));
		},
		scripts() {
			if (!this.scriptsData.data) return [];
			return this.scriptsData.data.map(s => ({
				...s,
				team: this.teamOptions.find(t => t.value === s.teamId._id).text
			}));
		}
	},
	methods: {
		...mapActions({
			findTeams: 'team/find',
			findScripts: 'script/find'
		}),
		async fetch() {
			this.page.isLoading = true;
			try {
				await this.findScripts({
					query: {
						page: parseInt(this.$route.query.page, 10),
						pagesize: parseInt(this.$route.query.perPage, 10)
					}
				});
				this.pagination.total = this.scriptsData.total;
			} catch (err) {
				this.$toaster.error(`Error loading scripts: ${err}`);
			}
			this.page.isLoading = false;
		},
		async fetchTeams() {
			this.page.isLoadingTeams = true;
			await this.findTeams({ query: { page: 1, pagesize: 9999 } });
			this.page.isLoadingTeams = false;
		},
		goToScript(script) {
			this.$router.push({ name: 'script.detail', params: { id: script._id } });
		},
		handleTableChange({ currentPage, perPage }) {
			this.pagination.currentPage = currentPage;
			this.pagination.perPage = perPage;
			this.updateQuery();
		},
		openNewScriptModal() {
			this.page.isNewScriptModalVisible = true;
		},
		hideNewScriptModal() {
			this.page.isNewScriptModalVisible = false;
		},
		updateQuery() {
			this.$router.replace({
				name: 'script.list',
				query: {
					...this.$route.query,
					page: this.pagination.currentPage,
					perPage: this.pagination.perPage
				}
			}).catch(e => {
				if (e.name !== 'NavigationDuplicated') throw e;
			});
		}
	},
	created() {
		this.fetchTeams();
	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				if (this.$route.query.page) this.pagination.currentPage = parseInt(this.$route.query.page, 10);
				if (this.$route.query.perPage) this.pagination.perPage = parseInt(this.$route.query.perPage, 10);
				this.updateQuery();
				this.fetch();
			}
		}
	}
};
</script>

<style lang="scss">

.ScriptList {
	padding: 1rem;

	&__listTable {
		margin-top: -28px;
	}
}
</style>

<template>
	<div :ref="identifier"/>
</template>

<script>
import { uniqueId as _uniqueId } from 'lodash';
import JSONFormatter from 'json-formatter-js';

export default {
	props: {
		data: {
			type: Object
		}
	},
	data() {
		return {
			identifier: `TreeView__${_uniqueId()}`,
			treeObject: null
		};
	},
	mounted() {
		this.treeObject = new JSONFormatter(this.data);
		this.$el.appendChild(this.treeObject.render());
	},
	watch: {
		data: {
			deep: true,
			handler() {
				this.$el.removeChild(this.$el.firstChild);
				this.treeObject = new JSONFormatter(this.data);
				this.$el.appendChild(this.treeObject.render());
			}
		}
	}
};
</script>

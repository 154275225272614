<template>
	<DefaultLayout class="PlanDetail">

		<ContentHeader
			:title="plan.name"
			:breadcrumbs="breadcrumbs">

			<b-button
				class="PlanDetail__action"
				variant="danger"
				v-text="'Delete Plan'"
				:disabled="page.isSubmitting"
				@click="openConfirmDeleteModal"/>
			<b-button
				class="PlanDetail__action"
				variant="warning"
				v-text="'Run Plan'"
				:disabled="page.isSubmitting"
				@click="openRunPlanModal"/>
			<b-button
				class="PlanDetail__action"
				variant="primary"
				v-text="'Save Plan'"
				:disabled="page.isSubmitting"
				@click="openConfirmSaveModal"/>
		</ContentHeader>

		<section class="PlanDetail__content">

			<loader class="App__loading" v-if="page.isLoading || page.isSubmitting"/>

			<div
				v-if="!page.isLoading && !page.isSubmitting"
				class="PlanDetail__row">
				<div class="PlanDetail__col">

					<workflow
						v-if="tasks"
						:tasks="tasks"/>

						<p
							v-if="!tasks"
							v-text="'No tasks found...'"/>

				</div>
				<div class="PlanDetail__col">
					<codemirror
						class="PlanDetail__code"
						:options="codeEditorOptions"
						v-model="planText"/>
				</div>
			</div>

		</section>

		<run-plan-modal
			:plan="plan"
			:visible="page.isRunPlanModalVisible"
			@hidden="hideRunPlanModal"
			@submit="goToWorkqueue"/>

		<confirm-modal
			:visible="page.isConfirmDeleteModalVisible"
			title="Delete Plan"
			text="Are you sure you want to delete this plan?"
			:danger="true"
			@ok="onConfirmDeletePlanClick"
			@hidden="hideConfirmDeleteModal"/>

		<confirm-modal
			:visible="page.isConfirmSaveModalVisible"
			title="Update Plan"
			text="Are you sure you want to update this plan?"
			@ok="onConfirmSavePlanClick"
			@hidden="hideConfirmSaveModal"/>

	</DefaultLayout>
</template>

<script>
import { ContentHeader } from '@workflow-solutions/ofs-vue-layout';
import { mapGetters, mapActions } from 'vuex';
import codeEditorOptions from '../../lib/codeEditorOptions';
import DefaultLayout from '../../components/DefaultLayout';
import RunPlanModal from '../../components/RunPlanModal';
import ConfirmModal from '../../components/ConfirmModal';
import Workflow from '../../components/Workflow';


export default {
	components: {
		DefaultLayout,
		ContentHeader,
		RunPlanModal,
		ConfirmModal,
		Workflow
	},
	data() {
		return {
			codeEditorOptions,
			page: {
				isLoading: false,
				isSubmitting: false,
				isRunPlanModalVisible: false,
				isConfirmDeleteModalVisible: false,
				isConfirmSaveModalVisible: false
			},
			planText: ''
		};
	},
	computed: {
		...mapGetters({
			plan: 'plan/plan'
		}),
		breadcrumbs() {
			return [
				{
					text: 'All Plans',
					to: { name: 'plan.list' }
				},
				{
					text: this.plan.name,
					active: true
				}
			];
		},
		tasks() {
			return (this.plan && this.plan.tasks) ? this.plan.tasks : [];
		},
		parsedPlan() {
			return JSON.parse(this.planText);
		}
	},
	methods: {
		...mapActions({
			getplan: 'plan/get',
			deleteplan: 'plan/deleteById',
			updateplan: 'plan/update'
		}),
		async fetchPlan() {
			this.page.isLoading = true;
			try {
				await this.getplan({ id: this.$route.params.id });
				this.planText = JSON.stringify(this.plan, null, 2);
			} catch (err) {
				this.$toaster.error(`Error loading plan: ${err}`);
				this.$router.push({ name: 'plan.list' });
			}
			this.page.isLoading = false;
		},
		async onConfirmDeletePlanClick() {
			this.page.isSubmitting = true;
			try {
				await this.deleteplan({ id: this.$route.params.id });
				this.$toaster.success(`Deleted plan: ${this.plan.name}`);
				this.$router.push({ name: 'plan.list' });
			} catch (err) {
				this.$toaster.error(`Error deleting plan: ${err}`);
			}
			this.page.isSubmitting = false;
		},
		async onConfirmSavePlanClick() {
			this.page.isSubmitting = true;
			try {
				await this.updateplan({ id: this.$route.params.id, data: this.parsedPlan });
				this.$toaster.success(`Updated plan: ${this.plan.name}`);
				this.fetchPlan();
			} catch (err) {
				this.$toaster.error(`Error updating plan: ${err}`);
			}
			this.page.isSubmitting = false;
		},
		openRunPlanModal() {
			this.page.isRunPlanModalVisible = true;
		},
		hideRunPlanModal() {
			this.page.isRunPlanModalVisible = false;
		},
		openConfirmDeleteModal() {
			this.page.isConfirmDeleteModalVisible = true;
		},
		hideConfirmDeleteModal() {
			this.page.isConfirmDeleteModalVisible = false;
		},
		openConfirmSaveModal() {
			this.page.isConfirmSaveModalVisible = true;
		},
		hideConfirmSaveModal() {
			this.page.isConfirmSaveModalVisible = false;
		},
		goToWorkqueue(workqueue) {
			this.$router.push({
				name: 'app.detail.workqueue',
				params: {
					id: workqueue.appId,
					workqueue: workqueue._id
				}
			});
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				this.fetchPlan();
			}
		}
	}
};
</script>

<style lang="scss">

.PlanDetail {

	&__action {
		margin-left: 1rem;
	}

	&__content {
		padding: 1rem;
		display: flex;
		flex-direction: column;
	}

	&__row {
		display: flex;
		flex-direction: row;
	}

	&__col {
		width: 50%;
		overflow: auto;

		&:first-of-type { margin-right: 0.5rem; }
		&:last-of-type { margin-left: 0.5rem; }
	}

	&__code {
		.CodeMirror {
			height: auto;
		}
	}
}
</style>
